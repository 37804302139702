import { useEffect, useMemo, useState } from 'react';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import {
  ConfigurationRuleScopeEntityType,
  DepartmentResponse,
  RegionResponse,
  StoreResponse,
  StoreTypeResponse,
} from '@ydistri/api-sdk';
import {
  ListType,
  useGetDepartmentsQuery,
  useGetRegionsQuery,
  useGetStoresQuery,
  useGetStoreTypesQuery,
} from '../../../apis/apiLists';

interface EntityListData<T> {
  data: ListType<T> | undefined;
  loading: boolean;
}

export interface EntityTypeToResponseMap {
  [ConfigurationRuleScopeEntityType.Regions]: RegionResponse;
  [ConfigurationRuleScopeEntityType.Stores]: StoreResponse;
  [ConfigurationRuleScopeEntityType.StoreTypes]: StoreTypeResponse;
  [ConfigurationRuleScopeEntityType.Departments]: DepartmentResponse;
}

export function useEntityListData<T extends keyof EntityTypeToResponseMap>(
  entityType: T,
): EntityListData<EntityTypeToResponseMap[T]> {
  const templateOrCalculation = useTemplateOrCalculation();

  const { data: availableRegions, isFetching: isFetchingRegions } =
    useGetRegionsQuery(templateOrCalculation);
  const { data: availableStores, isFetching: isFetchingStores } =
    useGetStoresQuery(templateOrCalculation);
  const { data: availableStoreTypes, isFetching: isFetchingStoreTypes } =
    useGetStoreTypesQuery(templateOrCalculation);
  const { data: availableDepartments, isFetching: isFetchingDepartments } =
    useGetDepartmentsQuery(templateOrCalculation);

  const [data, setData] = useState<ListType<EntityTypeToResponseMap[T]>>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (entityType === ConfigurationRuleScopeEntityType.Regions) {
      if (isFetchingRegions) {
        // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
        setLoading(true);
        // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
        setData(undefined);
      } else {
        // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@nx/workspace/no-useless-setstate-in-effect
        setData(availableRegions as unknown as ListType<EntityTypeToResponseMap[T]>);
      }
    }
  }, [entityType, isFetchingRegions, availableRegions]);

  useEffect(() => {
    if (entityType === ConfigurationRuleScopeEntityType.Stores) {
      if (isFetchingStores) {
        // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
        setLoading(true);
        // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
        setData(undefined);
      } else {
        // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@nx/workspace/no-useless-setstate-in-effect
        setData(availableStores as unknown as ListType<EntityTypeToResponseMap[T]>);
      }
    }
  }, [entityType, isFetchingStores, availableStores]);

  useEffect(() => {
    if (entityType === ConfigurationRuleScopeEntityType.StoreTypes) {
      if (isFetchingStoreTypes) {
        // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
        setLoading(true);
        // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
        setData(undefined);
      } else {
        // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@nx/workspace/no-useless-setstate-in-effect
        setData(availableStoreTypes as unknown as ListType<EntityTypeToResponseMap[T]>);
      }
    }
  }, [entityType, isFetchingStoreTypes, availableStoreTypes]);

  useEffect(() => {
    if (entityType === ConfigurationRuleScopeEntityType.Departments) {
      if (isFetchingDepartments) {
        // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
        setLoading(true);
        // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
        setData(undefined);
      } else {
        // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@nx/workspace/no-useless-setstate-in-effect
        setData(availableDepartments as unknown as ListType<EntityTypeToResponseMap[T]>);
      }
    }
  }, [entityType, isFetchingDepartments, availableDepartments]);

  return useMemo(
    () => ({
      data,
      loading,
    }),
    [data, loading],
  );
}
