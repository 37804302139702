import { useGetTargetListsQuery } from '../apiTargetLists';
import NoAdministrationItemSelected from '../../common/NoAdministrationItemSelected';
import React from 'react';

/**
 * Component for main part of the scrren when no Target List is selected.
 * Displays a message based on whether there are any Target Lists or not.
 * @constructor
 */
const NoTargetListSelected: React.FC = React.memo(() => {
  const { data: targetLists } = useGetTargetListsQuery(undefined);

  return (
    <NoAdministrationItemSelected
      message="No Target List selected"
      secondaryMessage={
        targetLists && targetLists.length > 0
          ? 'Click on a Target List from the list'
          : "Click on the 'Create new target list' button on the left to create a new Target List"
      }
    />
  );
});

NoTargetListSelected.displayName = 'NoTargetListSelected';
export default NoTargetListSelected;
