import { ColumnsType } from 'antd/es/table';
import { ProductResponse } from '@ydistri/api-sdk';
import { computeRemSize } from '@ydistri/ds';

export const getColumnsForProductCatalog = (
  productCodesPresent: boolean,
  productIdColumnWidth: string,
): ColumnsType<ProductResponse> => {
  const columns: ColumnsType<ProductResponse> = [
    {
      title: 'Product ID',
      dataIndex: 'customerId',
      key: 'customerId',
      width: productIdColumnWidth,
    },
  ];

  if (productCodesPresent) {
    columns.push({
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: computeRemSize(100),
    });
  }

  columns.push(
    ...[
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      // {
      //   title: 'Brand',
      //   dataIndex: 'brand',
      //   key: 'brand',
      //   width: computeRemSize(150),
      //   render: (value: string, record: ProductResponse) => record.brand.name,
      // },
    ],
  );

  return columns;
};
