import { useSelector } from 'react-redux';
import React, { useMemo } from 'react';

import { DetailType } from '../../detailTypes';
import DetailCategoryBody from './components/DetailCategoryBody/DetailCategoryBody';
import DetailProductBody from './components/DetailProductBody/DetailProductBody';
import DetailSkuBody from './components/DetailSkuBody/DetailSkuBody';
import useDetailScreenType from '../../hooks/useDetailScreenType';
import { Column, computeRemSize } from '@ydistri/ds';
import SKUTableSection from './components/DetailSales/SKUTableSection';

const DetailContentBodySection: React.FC = () => {
  const storeId = useSelector(state => state.detailReducer.storeId);
  const skuId = useSelector(state => state.detailReducer.skuId);
  const productId = useSelector(state => state.detailReducer.productId);
  const unit = useSelector(state => state.detailReducer.chartSettings.unit);

  const detailType = useDetailScreenType(productId, storeId, skuId);

  const body = useMemo(() => {
    switch (detailType) {
      case DetailType.SKU: {
        return <DetailSkuBody />;
      }
      case DetailType.CATEGORY: {
        return <DetailCategoryBody />;
      }
      case DetailType.PRODUCT: {
        return <DetailProductBody />;
      }
      case DetailType.SKU_NOT_FOUND: {
        return null;
      }
    }
  }, [detailType]);

  return (
    <Column $gap={computeRemSize(2)} $flexGrow={1}>
      {body}
      <SKUTableSection key="SkuTableSection" productId={productId} units={unit} />
    </Column>
  );
};

export default DetailContentBodySection;
