import { ActionsBar, Text } from '@ydistri/ds';
import {
  CalculationDetailResponse,
  CalculationFileResponse,
  CalculationFileType,
  CalculationType,
} from '@ydistri/api-sdk';
import React, { useCallback } from 'react';
import ExportCalculationFilesAction from './actions/ExportCalculationFilesAction';
import SearchBox from '../../../components/global/SearchBox/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
import { searchRedistribution } from '../../Calculations/calculationsSlice';
import CalculationRedistributionFiltersAction from './filters/CalculationRedistributionFiltersAction';
import { formatNumber, plural } from '@ydistri/utils';
import { NO_VALUE } from '../../../lib/utils/utilsTypes';
import { AvailableModals, openModal } from '../../Modals/modalSlice';

interface CalculationRedistributionActionsProps {
  calculation: CalculationDetailResponse;
}

const CalculationRedistributionActions: React.FC<CalculationRedistributionActionsProps> = ({
  calculation,
}) => {
  const redistributionSearchQuery = useSelector(
    state => state.calculations.redistributionSearchQuery,
  );
  const redistributionPairingCount = useSelector(
    state => state.calculations.redistributionPairingCount,
  );

  const dispatch = useDispatch();

  const onErpDownload = useCallback(() => {
    dispatch(
      openModal({
        type: AvailableModals.SET_PRODUCTION,
        data: {
          calculationId: calculation.id,
          isErpDownload: true,
        },
      }),
    );
  }, [calculation.id, dispatch]);

  const fileClickHandler = useCallback(
    (file: CalculationFileResponse) => {
      if (file.calculationFileType === CalculationFileType.PairingSpecific) {
        if (calculation.type === CalculationType.Simulation) {
          onErpDownload();
        }
      }
    },
    [calculation.type, onErpDownload],
  );

  const searchHandler = useCallback(
    (searchValue: string) => {
      dispatch(searchRedistribution(searchValue));
    },
    [dispatch],
  );

  return (
    <ActionsBar useDivider>
      {redistributionPairingCount !== NO_VALUE && (
        <Text $semiBold={true}>{`${formatNumber(redistributionPairingCount)} ${plural(
          redistributionPairingCount,
          'picking position',
        )}`}</Text>
      )}
      <ExportCalculationFilesAction files={calculation.files} onFileClick={fileClickHandler} />
      <CalculationRedistributionFiltersAction />
      <SearchBox
        value={redistributionSearchQuery}
        onSearch={searchHandler}
        placeholder="Search Redistribution"
        id="redistribution-search-box"
      />
    </ActionsBar>
  );
};

export default CalculationRedistributionActions;
