import { TargetListItemResponse, TargetListVersionConfigurationType } from '@ydistri/api-sdk';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  getDefaultSorting,
  InfiniteScrollTable,
  YColumnsType,
  Panel,
  computeRemSize,
} from '@ydistri/ds';
import { TargetListSkusRequest, useGetTargetListSkusQuery } from '../../apiTargetLists';
import { getQuantityColumnHeader } from '../../targetListsLib';
import { useApplicationData } from '../../../../../hooks/useApplicationData';
import { useColumnWidth } from '../../../../../hooks/useColumnWidth';
import useColumnDefinitionByType from '../../../../../hooks/useColumnDefinitionByType';

interface TargetListSkuGridProps {
  selectedTargetListId: number;
  configurationType: TargetListVersionConfigurationType;
  data?: TargetListItemResponse[];
  showActionType?: boolean;
  isInfinite?: boolean;
}

const BLOCK_SIZE: number = 45;

const TargetListSkuGrid: React.FC<TargetListSkuGridProps> = ({
  selectedTargetListId,
  configurationType,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState(480);
  const { productCode, warehouseCode } = useApplicationData();
  const { productIdColumnWidth, storeIdColumnWidth } = useColumnWidth();

  const productColumnGetterFunction = useColumnDefinitionByType<TargetListItemResponse>({
    type: 'Product',
  });

  const storeColumnGetterFunction = useColumnDefinitionByType<TargetListItemResponse>({
    type: 'Store',
  });

  const columns: YColumnsType<TargetListItemResponse>[] = useMemo(() => {
    console.log('STORE: ', {
      ...storeColumnGetterFunction(['warehouse'], 'Warehouse', undefined, 'CustomerWarehouseId'),
    });
    const tmpColumns: YColumnsType<TargetListItemResponse>[] = [
      {
        ...storeColumnGetterFunction(['warehouse'], 'Warehouse', undefined, 'customerWarehouseId'),
        width: storeIdColumnWidth,
      },
      {
        title: 'Store Name',
        key: 'warehouseName',
        width: '29rem',
        dataIndex: ['warehouse', 'name'],
        apiColumnName: 'Warehouse/Name',
        apiFilterable: true,
        apiFilterType: 'text',
        sorter: true,
      },
      {
        ...productColumnGetterFunction(),
        width: productIdColumnWidth,
      },
      {
        title: 'Product Name',
        key: 'productName',
        width: '29rem',
        dataIndex: ['product', 'name'],
        apiColumnName: 'Product/Name',
        apiFilterable: true,
        apiFilterType: 'text',
        sorter: true,
      },
      {
        title: getQuantityColumnHeader(configurationType),
        key: 'quantity',
        dataIndex: 'quantity',
        width: '10rem',
        align: 'right',
        apiColumnName: 'Quantity',
        apiFilterable: true,
        apiFilterType: 'number',
        sorter: true,
      },
    ];

    //if project uses warehouse code, insert a Warehouse Code column after the Warehouse index column
    if (warehouseCode) {
      const warehouseIdColumnIndex = tmpColumns.findIndex(
        tmpColumn => tmpColumn.key === 'warehouseId',
      );
      if (warehouseIdColumnIndex > -1) {
        tmpColumns.splice(warehouseIdColumnIndex + 1, 0, {
          title: 'Store Code',
          key: 'warehouseCode',
          dataIndex: ['warehouse', 'code'],
          width: '8rem',
          apiColumnName: 'Warehouse/Code',
          apiFilterable: true,
          apiFilterType: 'text',
          sorter: true,
        });
      }
    }

    //if project uses product code, insert a Product Code column after the Product index column
    if (productCode) {
      const productIdColumnIndex = tmpColumns.findIndex(tmpColumn => tmpColumn.key === 'productId');
      if (productIdColumnIndex > -1) {
        tmpColumns.splice(productIdColumnIndex + 1, 0, {
          title: 'Product Code',
          key: 'productCode',
          dataIndex: ['product', 'code'],
          width: '8rem',
          apiColumnName: 'Product/Code',
          apiFilterable: true,
          apiFilterType: 'text',
          sorter: true,
        });
      }
    }

    return tmpColumns;
  }, [
    configurationType,
    productCode,
    productColumnGetterFunction,
    productIdColumnWidth,
    storeColumnGetterFunction,
    storeIdColumnWidth,
    warehouseCode,
  ]);

  const defaultParams = useMemo(() => {
    return {
      targetListId: selectedTargetListId,
      skip: 0,
      top: BLOCK_SIZE,
      sortings: getDefaultSorting(columns),
    };
  }, [columns, selectedTargetListId]);

  const [params, setParams] = useState<TargetListSkusRequest>(defaultParams);

  useEffect(() => {
    setParams(defaultParams);
  }, [defaultParams]);

  const { data, isFetching, refetch } = useGetTargetListSkusQuery(params);

  const computeTableHeight = useCallback(() => {
    const TABLE_SHIFT: number = 96; //to compensate for the table's header as it is rendered outside the table's container
    if (ref.current) {
      setTableHeight(ref.current.clientHeight - TABLE_SHIFT);
    }
  }, []);

  useEffect(() => {
    //initial height
    computeTableHeight();

    //compute height on window resize
    window.addEventListener('resize', () => computeTableHeight);

    return () => {
      window.removeEventListener('resize', () => computeTableHeight);
    };
  }, [computeTableHeight, refetch]);

  const rowKeyProvider = useCallback(
    (row: TargetListItemResponse) =>
      row.skuId?.toString() ?? `${row.warehouse?.id}#${row.product?.id}`,
    [],
  );

  return (
    <Panel $noAutoHeight={true} $grow={1} ref={ref}>
      <InfiniteScrollTable<TargetListItemResponse, TargetListSkusRequest>
        id="targetListContent"
        height={computeRemSize(tableHeight)}
        setParams={setParams}
        columns={columns}
        dataSource={data}
        loading={isFetching}
        rowKey={rowKeyProvider}
      />
    </Panel>
  );
};

export default TargetListSkuGrid;
