import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  computeRemSize,
  getDefaultSorting,
  InfiniteScrollTable,
  Panel,
  YColumnsType,
} from '@ydistri/ds';
import { EntityListItemResponse, EntityListType } from '@ydistri/api-sdk';
import { useApplicationData } from '../../../../../hooks/useApplicationData';
import { useColumnWidth } from '../../../../../hooks/useColumnWidth';
import useColumnDefinitionByType from '../../../../../hooks/useColumnDefinitionByType';
import { GetLatestEntityListItemsRequest, useGetEntityListItemsQuery } from '../../apiEntityLists';
import {
  entityListTypesWithProducts,
  entityListTypesWithStores,
  productDataIndexByEntityListType,
  storeDataIndexByEntityListType,
} from '../../entityListsLib';

interface EntityListGridProps {
  entityListType: EntityListType;
  selectedEntityListId: number;
}

const BLOCK_SIZE: number = 45;

const EntityListGrid: React.FC<EntityListGridProps> = ({
  entityListType,
  selectedEntityListId,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { productIdColumnWidth, storeIdColumnWidth } = useColumnWidth();
  const [tableHeight, setTableHeight] = useState(480);
  const { productCode, warehouseCode } = useApplicationData();

  const productColumnGetterFunction = useColumnDefinitionByType<EntityListItemResponse>({
    type: 'Product',
  });
  const storeColumnGetterFunction = useColumnDefinitionByType<EntityListItemResponse>({
    type: 'Store',
  });

  const columns: YColumnsType<EntityListItemResponse>[] = useMemo(() => {
    const tmpColumns: YColumnsType<EntityListItemResponse>[] = [];

    if (entityListTypesWithProducts.includes(entityListType)) {
      tmpColumns.push(
        {
          ...productColumnGetterFunction([...productDataIndexByEntityListType[entityListType]], ''),
          width: productIdColumnWidth,
        },
        {
          title: 'Product Name',
          key: 'productName',
          width: '29rem',
          dataIndex: [...productDataIndexByEntityListType[entityListType], 'name'],
          apiColumnName: 'Name',
          apiFilterable: true,
          apiFilterType: 'text',
          sorter: true,
        },
      );
      //if project uses product code, insert a Product Code column after the Product index column
      if (productCode) {
        const productIdColumnIndex = tmpColumns.findIndex(
          tmpColumn => tmpColumn.key === 'productId',
        );
        if (productIdColumnIndex > -1) {
          tmpColumns.splice(productIdColumnIndex + 1, 0, {
            title: 'Product Code',
            key: 'productCode',
            dataIndex: [...productDataIndexByEntityListType[entityListType], 'code'],
            width: '6rem',
            apiColumnName: 'Code',
            apiFilterable: true,
            apiFilterType: 'text',
            sorter: true,
          });
        }
      }
    }

    if (entityListTypesWithStores.includes(entityListType)) {
      tmpColumns.push(
        {
          ...storeColumnGetterFunction([...storeDataIndexByEntityListType[entityListType]], ''),
          width: storeIdColumnWidth,
        },
        {
          title: 'Store Name',
          key: 'storeName',
          width: '29rem',
          dataIndex: [...storeDataIndexByEntityListType[entityListType], 'name'],
          apiColumnName: 'Name',
          apiFilterable: true,
          apiFilterType: 'text',
          sorter: true,
        },
      );
      //if project uses product code, insert a Product Code column after the Product index column
      if (warehouseCode) {
        const storeIdColumnIndex = tmpColumns.findIndex(tmpColumn => tmpColumn.key === 'storeId');
        if (storeIdColumnIndex > -1) {
          tmpColumns.splice(storeIdColumnIndex + 1, 0, {
            title: 'Store Code',
            key: 'storeCode',
            dataIndex: [...storeDataIndexByEntityListType[entityListType], 'code'],
            width: '6rem',
            apiColumnName: 'Code',
            apiFilterable: true,
            apiFilterType: 'text',
            sorter: true,
          });
        }
      }

      tmpColumns.push({
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
        width: '10rem',
        align: 'right',
        apiColumnName: 'Quantity',
        apiFilterable: true,
        apiFilterType: 'number',
        sorter: true,
      });
    }

    return tmpColumns;
  }, [
    entityListType,
    productCode,
    productColumnGetterFunction,
    productIdColumnWidth,
    storeColumnGetterFunction,
    storeIdColumnWidth,
    warehouseCode,
  ]);

  const defaultParams = useMemo(() => {
    return {
      entityListId: selectedEntityListId,
      skip: 0,
      top: BLOCK_SIZE,
      sortings: getDefaultSorting(columns),
    };
  }, [columns, selectedEntityListId]);

  const [params, setParams] = useState<GetLatestEntityListItemsRequest>(defaultParams);

  useEffect(() => {
    setParams(defaultParams);
  }, [defaultParams]);

  const { data, isFetching, refetch } = useGetEntityListItemsQuery(params);

  const resizeTable = useCallback(() => {
    const TABLE_SHIFT: number = 96;
    if (ref.current) {
      setTableHeight(ref.current.clientHeight - TABLE_SHIFT);
    }
  }, []);

  useEffect(() => {
    resizeTable();

    //compute height on window resize
    window.addEventListener('resize', () => resizeTable);

    return () => {
      window.removeEventListener('resize', () => resizeTable);
    };
  }, [refetch, resizeTable]);

  const rowKeyProvider = useCallback(
    (row: EntityListItemResponse) =>
      row.sku?.skuId ? `sku-${row.sku.skuId}` : `s-${row.store?.id}-p-${row.product?.id}`,
    [],
  );

  return (
    <Panel $height="100%" ref={ref}>
      <InfiniteScrollTable<EntityListItemResponse, GetLatestEntityListItemsRequest>
        id="targetListContent"
        height={computeRemSize(tableHeight)}
        setParams={setParams}
        columns={columns}
        dataSource={data}
        loading={isFetching}
        rowKey={rowKeyProvider}
      />
    </Panel>
  );
};

export default EntityListGrid;
