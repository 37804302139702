import React, { useEffect, useMemo } from 'react';
import { TargetListResponse } from '@ydistri/api-sdk';
import { Column, ReadOnlyItem, Text } from '@ydistri/ds';
import TargetListTypeIndicator from './TargetListTypeIndicator';
import { targetListTypeOptions } from '../targetListsTypes';
import { useGetTargetListCalculationsQuery } from '../apiTargetLists';
import ListCalculationsWrapper from '../../common/usage/ListCalculationsWrapper';

interface TargetListDetailsProps {
  targetList: TargetListResponse;
}

/** Common width for the labels to have the data aligned */
const LABEL_WIDTH: string = '6rem';

/**
 * Details of a target list like title, id etc.
 * @param targetList
 * @constructor
 */
const TargetListDetails: React.FC<TargetListDetailsProps> = ({ targetList }) => {
  const typeDescription = useMemo(() => {
    return targetListTypeOptions.find(
      option => option.value === targetList.targetListVersionConfigurationTypeId,
    )?.label;
  }, [targetList]);

  const {
    data: targetListCalculations = [],
    isFetching,
    refetch: refetchTargetListCalculations,
  } = useGetTargetListCalculationsQuery(targetList.targetListId);

  useEffect(() => {
    refetchTargetListCalculations();
  }, [refetchTargetListCalculations, targetList]);

  return (
    <Column $gap="1rem" data-type="TargetListDetails">
      <ReadOnlyItem label="Type" labelWidth={LABEL_WIDTH}>
        <Text $size="large">{typeDescription}</Text>
        <TargetListTypeIndicator
          size="small"
          type={targetList.targetListVersionConfigurationTypeId}
        />
      </ReadOnlyItem>
      <ReadOnlyItem
        label="System Id"
        value={targetList.customerProductListId}
        labelWidth={LABEL_WIDTH}
      />
      <ListCalculationsWrapper
        calculations={targetListCalculations}
        fetching={isFetching}
        labelWidth={LABEL_WIDTH}
      />
    </Column>
  );
};

export default TargetListDetails;
