import React, { useCallback, useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import {
  UpdateCalculationConfigurationPayload,
  useGetCalculationConfigurationQuery,
  useUpdateCalculationConfigurationMutation,
} from '../../../apis/apiGlobalConfiguration';
import { SelectProps } from 'antd';
import { SuperTargetRedistributionType } from '@ydistri/api-sdk';
import {
  ConfigurationFieldStatus,
  rtkQueryPatchStatusToConfigurationFieldStatus,
} from '@ydistri/utils';
import { ConfigurationSelect } from '@ydistri/ds';

interface SuperTargetSelectProps {}

const SuperTargetSelect: React.FC<SuperTargetSelectProps> = () => {
  const templateOrCalculation = useTemplateOrCalculation();

  const { data: calculationConfiguration, isFetching } =
    useGetCalculationConfigurationQuery(templateOrCalculation);

  const [updateConfiguration, updateConfigurationStatus] =
    useUpdateCalculationConfigurationMutation();

  const options: SelectProps<SuperTargetRedistributionType>['options'] = useMemo(() => {
    return [
      {
        value: SuperTargetRedistributionType.DoNotUse,
        label: 'Do not use',
      },
      {
        value: SuperTargetRedistributionType.AllWarehouses,
        label: 'All',
      },
      {
        value: SuperTargetRedistributionType.ProductsInRedistribution,
        label: 'Source SKU',
      },
      {
        value: SuperTargetRedistributionType.SourceWarehousesInRedistribution,
        label: 'Source Store',
      },
    ];
  }, []);

  const onChangeHandler = useCallback(
    (value: SuperTargetRedistributionType) => {
      const payload: UpdateCalculationConfigurationPayload = {
        templateOrCalculation,
        request: {
          superTargetRedistributionTypeId: value,
        },
      };

      updateConfiguration(payload);
    },
    [templateOrCalculation, updateConfiguration],
  );

  const status = useMemo(() => {
    console.log('STATUS: ', isFetching, updateConfigurationStatus);

    return isFetching
      ? ConfigurationFieldStatus.LOADING
      : rtkQueryPatchStatusToConfigurationFieldStatus(updateConfigurationStatus);
  }, [updateConfigurationStatus, isFetching]);

  return (
    <ConfigurationSelect
      disabled={templateOrCalculation.type === 'Calculation'}
      value={calculationConfiguration?.superTargetRedistributionTypeId}
      options={options}
      onChange={onChangeHandler}
      status={status}
      showArrow={true}
      width="150px"
    />
  );
};

export default SuperTargetSelect;
