import React, { useCallback, useState } from 'react';
import CreateCalculationModal from './CreateCalculationModal';
import { MdAdd } from 'react-icons/md';
import { Button, IconButton_IconSizes } from '@ydistri/ds';

interface CreateCalculationButtonProps {
  disabled?: boolean;
}

const createIcon = <MdAdd size={IconButton_IconSizes.small} />;

const CreateCalculationButton: React.FC<CreateCalculationButtonProps> = ({ disabled = false }) => {
  const [openCreateCalculationModal, setOpenCreateCalculationModal] = useState(false);

  const openCreateCalculationModalHandler = useCallback(
    () => setOpenCreateCalculationModal(true),
    [],
  );
  const closeCreateCalculationModalHandler = useCallback(
    () => setOpenCreateCalculationModal(false),
    [],
  );

  return (
    <>
      <Button
        $colorVariant={disabled ? 'ghost' : 'primary'}
        $textVariant="semibold"
        icon={createIcon}
        type="primary"
        onClick={openCreateCalculationModalHandler}
        disabled={disabled}
      >
        Create calculation
      </Button>
      {openCreateCalculationModal && (
        <CreateCalculationModal
          open={openCreateCalculationModal}
          onCancel={closeCreateCalculationModalHandler}
        />
      )}
    </>
  );
};

export default CreateCalculationButton;
