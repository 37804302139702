import {
  ApiOperationType,
  SkuClassConfigurationRequest,
  SkuClassConfigurationResponse,
} from '@ydistri/api-sdk';
import { apiSlice, TemplateOrCalculation, wrongTemplateInHeaders } from '../../../apis/api';
import { CalculationsCollection, CurrentSetupCollection } from '../../../swagger/collections';
import { SignalConfigurationChanged } from '../../../signalr/signalrInterfaces';
import { signalrClient } from '../../../signalr/client';
import { getTags } from '../../../apis/apiLib';

const { TAGS, TAGS_ARRAY } = getTags('skuClassConfiguration');

export interface UpdateSkuClassConfigurationPayload {
  templateOrCalculation: TemplateOrCalculation;
  skuClassId: number;
  request: SkuClassConfigurationRequest;
}

export const apiSkuClasses = apiSlice
  .enhanceEndpoints({ addTagTypes: TAGS_ARRAY })
  .injectEndpoints({
    endpoints: builder => ({
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      getSkuClassConfiguration: builder.query<
        SkuClassConfigurationResponse[],
        TemplateOrCalculation
      >({
        queryFn: async arg => {
          if (wrongTemplateInHeaders(arg)) return { data: [] };
          const {
            data: { data: skuClassConfiguration },
          } = await (arg.type === 'Template'
            ? CurrentSetupCollection.getCurrentSkuClassConfigurations()
            : CalculationsCollection.getCalculationSkuClassConfigurations(arg.id));
          return { data: skuClassConfiguration };
        },
        providesTags: [TAGS.skuClassConfiguration],
        async onCacheEntryAdded(
          // eslint-disable-next-line no-unused-vars
          { type: _, id: selectedTemplateId },
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
        ) {
          try {
            await cacheDataLoaded;

            const listener = (signal: SignalConfigurationChanged) => {
              console.log('CONFIGURATION CHANGED', signal);
              if (selectedTemplateId !== signal.templateId) return;
              if (
                signal.entityType === 'SkuClass' &&
                signal.operationType === ApiOperationType.Patch
              ) {
                const skuClassId = signal.entityId;
                updateCachedData(draft => {
                  signal.updates.forEach(update => {
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    const field = update.fieldName as keyof SkuClassConfigurationResponse;
                    const value = update.value;
                    const skuClass = draft.find(r => r.skuClass?.id === skuClassId);
                    if (
                      skuClass &&
                      field in skuClass &&
                      field !== 'skuClass' &&
                      value !== undefined
                    ) {
                      skuClass[field] = value === '1';
                    }
                  });
                });
              }
            };

            signalrClient.on('TemplateConfigurationChanged', listener);

            cacheEntryRemoved.then(() => {
              signalrClient.off('TemplateConfigurationChanged', listener);
            });
          } catch {
            // no-op in case cache entry was removed before data was loaded
          }
          await cacheEntryRemoved;
        },
      }),

      updateSkuClassConfiguration: builder.mutation<
        SkuClassConfigurationResponse,
        UpdateSkuClassConfigurationPayload
      >({
        queryFn: async payload => {
          const { data } = await CurrentSetupCollection.patchCurrentSkuClassConfigurations(
            payload.skuClassId,
            payload.request,
          );

          return data;
        },
        onQueryStarted(payload, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            apiSkuClasses.util.updateQueryData(
              'getSkuClassConfiguration',
              payload.templateOrCalculation,
              draft => {
                const skuClass = draft.find(r => r.skuClass?.id === payload.skuClassId);
                if (skuClass) Object.assign(skuClass, payload.request);
              },
            ),
          );
          queryFulfilled.catch(patchResult.undo);
        },
      }),
    }),
  });

export const { useGetSkuClassConfigurationQuery, useUpdateSkuClassConfigurationMutation } =
  apiSkuClasses;
