import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Toast, ToastDraft } from '@ydistri/utils';

export interface ToastReducerType {
  toastCounter: number;
  toasts: Toast[];
}

export const initialState: ToastReducerType = {
  toastCounter: 0,
  toasts: [],
};

export const toastSlice = createSlice({
  name: 'toastReducer',
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<ToastDraft>) => {
      const newToastCounter = state.toastCounter + 1;
      const newToast: Toast = {
        ...action.payload,
        id: newToastCounter,
      };

      return {
        ...state,
        toastCounter: newToastCounter,
        toasts: [...state.toasts, newToast],
      };
    },
    removeToast: (state, action: PayloadAction<Toast>) => {
      return { ...state, toasts: state.toasts.filter(toast => toast.id !== action.payload.id) };
    },
    resetState: () => initialState,
  },
});

export const { addToast, removeToast } = toastSlice.actions;
export const toastReducer = toastSlice.reducer;
