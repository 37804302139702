import React, { KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { Checkbox, Form, Input, InputRef, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Text } from '@ydistri/ds';
import { useSelectedTemplate } from '../../hooks/useSelectedTemplate';
import { useCreateCalculationMutation } from '../Calculations/apiCalculations';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../components/menu/menuLeftItemTemplate';
import { createDebugLog } from '../../lib/utils/logging';
import { useUser } from '../../hooks/useUser';
import { CalculationRequest, CalculationType } from '@ydistri/api-sdk';
import ValidateStoreDepartmentConfiguration from './ConfigurationStoresAndDepartments/ValidateStoreDepartmentConfiguration';

const debugLog = createDebugLog('Configuration', 'CreateCalculationModal');

interface CreateCalculationModalProps {
  open: boolean;
  onCancel: () => void;
}

interface FormFields {
  title: string;
  description: string;
  private: boolean;
}

const titleInputRules = [{ required: true, message: 'Please input the title of calculation' }];
const formLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};

const CreateCalculationModal: React.FC<CreateCalculationModalProps> = ({ open, onCancel }) => {
  const inputRef = useRef<InputRef>(null);
  const navigate = useNavigate();
  const selectedTemplate = useSelectedTemplate();

  const { projectShortName } = useParams();
  const [form] = Form.useForm<FormFields>();
  const [createCalculation, createCalculationStatus] = useCreateCalculationMutation();
  const user = useUser();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleSubmit = useCallback(() => {
    debugLog('SUBMITTED');
    form.validateFields().then(values => {
      debugLog('These are values: ', values);

      const request: CalculationRequest = {
        title: values.title,
        description: values.description,
      };

      if (values.private) {
        request.calculationType = CalculationType.Private;
      }

      createCalculation(request).then(() => {
        form.resetFields();
        const path = generatePath(ROUTES.calculations, { projectShortName });
        debugLog('Should redirect to path:', path);
        navigate(path);
        onCancel();
      });
    });
  }, [createCalculation, form, onCancel, projectShortName, navigate]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (event.key === 'Enter') {
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  useEffect(() => {
    debugLog('useEffect', inputRef.current);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <Modal
      data-type="modal"
      title="Create calculation"
      open={open}
      width={1000}
      okText="Create"
      okButtonProps={{
        disabled: buttonDisabled,
      }}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={createCalculationStatus.isLoading}
    >
      <Form
        data-type="create-calculation-form"
        form={form}
        labelCol={formLayout.labelCol}
        wrapperCol={formLayout.wrapperCol}
        name="create_calculation_modal"
        layout="horizontal"
      >
        <Form.Item name="title" label="Title" rules={titleInputRules}>
          <Input ref={inputRef} onKeyDown={handleKeyDown} />
        </Form.Item>
        <Form.Item label="Template">
          <Text>
            #{selectedTemplate?.id} {selectedTemplate?.title}
          </Text>
        </Form.Item>
        {user?.isSuperUser && (
          <Form.Item label="Type" name="private" valuePropName="checked">
            <Checkbox>Private calculation</Checkbox>
          </Form.Item>
        )}
        <Form.Item name="description" label="Description">
          <TextArea rows={4} />
        </Form.Item>
        <ValidateStoreDepartmentConfiguration setDisabledButton={setButtonDisabled} />
      </Form>
    </Modal>
  );
};

export default CreateCalculationModal;
