import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ButtonProps, Modal, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AvailableModals, closeModal } from './modalSlice';
import { ReduxState } from '../../store';
import TextArea, { TextAreaRef } from 'antd/es/input/TextArea';
import * as Sentry from '@sentry/react';
import { useUser } from '../../hooks/useUser';
import { sentryReplay } from '../../../main';
import { Button, Column, Text } from '@ydistri/ds';

interface ReportErrorModalProps {}

const modalId = AvailableModals.REPORT_ERROR;

const ReportErrorModal: React.FC<ReportErrorModalProps> = () => {
  const dispatch = useDispatch();
  const user = useUser();
  const ref = useRef<TextAreaRef>(null);
  const [description, setDescription] = useState('');
  const [sent, setSent] = useState(false);

  const open = useSelector((state: ReduxState) => state.modal.openedModal === modalId);

  const handleClose = useCallback(() => {
    dispatch(closeModal());
    setSent(false);
    setDescription('');
  }, [dispatch]);

  const onChangeHandler = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  }, []);

  const disabled = description === '';

  const okButtonProps: ButtonProps = useMemo(() => {
    return {
      disabled,
      style: disabled ? { opacity: 0.5, cursor: 'not-allowed' } : {},
    };
  }, [disabled]);

  const onSubmitHandler = useCallback(() => {
    let replayId = 'no-replay-id';
    if (sentryReplay) {
      replayId = sentryReplay.getReplayId() ?? 'no-replay-id';
      sentryReplay.flush();
    }

    const eventId = Sentry.captureMessage(`Error report by ${user?.fullName}: ${description}`, {
      tags: {
        type: 'user-error-report',
        ['replay.id']: replayId,
        ['replay.url']: `https://ydistri.sentry.io/replays/${replayId}`,
      },
    });

    Sentry.captureFeedback({
      associatedEventId: eventId,
      name: `Error report by ${user?.fullName}`,
      email: user?.email ?? 'unknown-email',
      message: description,
    });

    setSent(true);
    setDescription('');
  }, [description, user?.email, user?.fullName]);

  useEffect(() => {
    const refC = ref.current;
    if (refC && open) setTimeout(() => refC.focus(), 100);
  }, [open]);

  return (
    <Modal
      title="Report issue"
      open={open}
      onCancel={handleClose}
      okText="Send report"
      footer={sent ? null : undefined}
      onOk={onSubmitHandler}
      okButtonProps={okButtonProps}
    >
      {!sent && (
        <Space direction="vertical">
          <span />
          <TextArea
            allowClear
            rows={10}
            value={description}
            onChange={onChangeHandler}
            placeholder="What went wrong? Please provide as much detail as possible."
            ref={ref}
          />
          <Text>
            We&apos;ll save a replay of your session to help address the issue promptly. Thank you
            for your assistance!
          </Text>
        </Space>
      )}
      {sent && (
        <Column $gap="1rem" $alignSelf="center" $alignItems="center" $justifyContent="center">
          <Text>Issue has been reported.</Text>
          <Button onClick={handleClose} $colorVariant="ghostPrimary">
            Close
          </Button>
        </Column>
      )}
    </Modal>
  );
};

export default ReportErrorModal;
