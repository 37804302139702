import UserAvatar from '../../../../components/global/data-display/UserAvatar';
import { Row, Text } from '@ydistri/ds';
import React from 'react';

interface CalculationOwnerProps {
  fullName: string;
}

/**
 * Displays an avatar and the fullName next to each other
 */
const CalculationOwner: React.FC<CalculationOwnerProps> = ({ fullName }) => {
  return (
    <Row $gap="0.5rem" $alignItems="center">
      <UserAvatar fullName={fullName} />
      <Text data-type="CalculationOwner" $size="large">
        {fullName}
      </Text>
    </Row>
  );
};

export default React.memo(CalculationOwner);
