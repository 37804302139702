import React, { useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useCurrency } from '../../../hooks/useCurrency';
import StatisticsOverallIcon from '../../../../assets/statistics-overall.svg';
import { Column, computeRemSize, Row, Title } from '@ydistri/ds';
import { useGetEvaluationSummaryQuery } from './apiCalculationEvaluations';
import LoadingIndicator from '../../../components/global/LoadingIndicator/LoadingIndicator';
import StatisticsSection from '../Statistics/components/StatisticsSection';
import { Popover } from 'antd';
import { formatMonetaryValue, shortenNumber } from '@ydistri/utils';
import { WideStatistic } from '../Statistics/SectionTargetList';
import { ColumnsType } from 'antd/es/table';
import { SimpleTable, SimpleTableDivider } from '../Statistics/components/SimpleTable';
import dayjs from 'dayjs';

type OverallStatistics =
  | {
      title: string;
      key: string;
      value: string | number;
      fromNow: string | number;
    }
  | SimpleTableDivider;

const OverallCalculationEvaluations: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const currencyId = useCurrency() ?? '...';

  const { data, isFetching: isFetchingStatistics } = useGetEvaluationSummaryQuery({
    calculationId: templateOrCalculation.id,
  });

  const overallEvaluationsColumns: ColumnsType<OverallStatistics> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: computeRemSize(200),
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      align: 'right',
    },
    {
      title: 'From now',
      dataIndex: 'fromNow',
      key: 'fromNow',
      align: 'left',
      render: value => (value ? <i>({value})</i> : null),
    },
  ];

  const tableData: OverallStatistics[] = useMemo(() => {
    const newData: OverallStatistics[] = [];
    if (data) {
      newData.push(
        ...[
          {
            title: 'Calculation computed on:',
            key: 'calculationCreatedAt',
            value: dayjs(data.calculationCreatedAt ?? '-').format('Do MMMM YYYY'),
            fromNow: dayjs(data.calculationCreatedAt ?? '-').fromNow(),
          },
          {
            title: 'Finish execution until:',
            key: 'finishExecutionUntil',
            value: data.finishExecutionUntil
              ? dayjs(data.finishExecutionUntil).format('Do MMMM YYYY')
              : 'Not available',
            fromNow: data.finishExecutionUntil ? dayjs(data.finishExecutionUntil).fromNow() : '',
          },
        ],
      );
    }

    return newData;
  }, [data]);

  return (
    <StatisticsSection $width="100%" icon={StatisticsOverallIcon}>
      {isFetchingStatistics && <LoadingIndicator />}
      <Row $justifyContent="space-between" $gap="1rem">
        <Column $gap="1rem" $width={computeRemSize(450)}>
          <Title>Store evaluations</Title>
          <SimpleTable<OverallStatistics>
            id="overall-statistics"
            rowKey="key"
            dataSource={tableData}
            columns={overallEvaluationsColumns}
            loading={isFetchingStatistics}
          />
        </Column>
        {data && (
          <Row $gap="1rem">
            <Popover
              content={`${formatMonetaryValue(
                'MU',
                data.evaluationRatesSummary.executedQuantity ?? 0,
                0,
              )} / ${formatMonetaryValue('MU', data.evaluationRatesSummary.totalQuantity ?? 0, 0)}`}
            >
              <WideStatistic
                groupSeparator=" "
                title="Executed quantity [MU]"
                value={`${shortenNumber(
                  data.evaluationRatesSummary.executedQuantity ?? 0,
                  0,
                )} / ${shortenNumber(data.evaluationRatesSummary.totalQuantity ?? 0, 0)} (${
                  data.executedQuantityPercentage ?? 0
                }%)`}
                loading={isFetchingStatistics}
              />
            </Popover>

            <Popover
              content={`${formatMonetaryValue(
                currencyId,
                data.evaluationRatesSummary.executedValue ?? 0,
                0,
              )} / ${formatMonetaryValue(
                currencyId,
                data.evaluationRatesSummary.totalValue ?? 0,
                0,
              )}`}
            >
              <WideStatistic
                groupSeparator=" "
                title={`Executed value [${currencyId}]`}
                value={`${shortenNumber(
                  data.evaluationRatesSummary.executedValue ?? 0,
                  0,
                )} / ${shortenNumber(data.evaluationRatesSummary.totalValue ?? 0, 0)} (${
                  data.executedValuePercentage ?? 0
                }%)`}
                loading={isFetchingStatistics}
              />
            </Popover>

            <Popover
              content={`${formatMonetaryValue(
                '',
                data.evaluationRatesSummary.executedSkuCount ?? 0,
                0,
              )} / ${formatMonetaryValue('', data.evaluationRatesSummary.totalSkuCount ?? 0, 0)}`}
            >
              <WideStatistic
                groupSeparator=" "
                title="Executed SKU count"
                value={`${shortenNumber(
                  data.evaluationRatesSummary.executedSkuCount ?? 0,
                  0,
                )} / ${shortenNumber(data.evaluationRatesSummary.totalSkuCount ?? 0, 0)} (${
                  data.executedSkuCountPercentage ?? 0
                }%)`}
                loading={isFetchingStatistics}
              />
            </Popover>
          </Row>
        )}
      </Row>
    </StatisticsSection>
  );
};

export default OverallCalculationEvaluations;
