import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SkuRedistributionResponse } from '@ydistri/api-sdk';
import {
  CalculationsTableFilters,
  CalculationTableParams,
  PrivateFilterType,
} from './calculationsTypes';
import { NO_VALUE } from '../../lib/utils/utilsTypes';

type SelectedRowKeysType = Record<number, boolean | undefined>;

export interface CalculationsSliceState {
  expandedRowKeys: number[];
  selectedRowKeys: { arr: number[]; obj: SelectedRowKeysType };
  hasSelectedRowKeys: boolean;
  redistributionSearchQuery?: string;
  selectedSKURedistribution: SkuRedistributionResponse | undefined;
  redistributionFilterBrandId?: number;
  redistributionFilterOutOfStock: boolean;
  redistributionFilterForced: boolean;
  redistributionPairingCount: number;
  calculationFilters: CalculationsTableFilters;
  calculationsTableParams: CalculationTableParams;
}

const initialTableFilters: CalculationsTableFilters = {
  showAllPrivateCalculations: PrivateFilterType.MINE,
  showProductionOnly: false,
  showCurrentUserCalculationsOnly: false,
};

const initialState: CalculationsSliceState = {
  expandedRowKeys: [],
  selectedRowKeys: { arr: [], obj: {} },
  hasSelectedRowKeys: false,
  redistributionSearchQuery: undefined,
  selectedSKURedistribution: undefined,
  redistributionFilterBrandId: undefined,
  redistributionFilterOutOfStock: false,
  redistributionFilterForced: false,
  calculationFilters: { ...initialTableFilters },
  calculationsTableParams: {},
  redistributionPairingCount: NO_VALUE,
};

export const calculationsSlice = createSlice({
  name: 'calculations',
  initialState,
  reducers: {
    resetExpandedRowKeys: state => {
      state.expandedRowKeys = [];
    },
    addExpandedRowKey: (state, action: PayloadAction<number>) => {
      state.expandedRowKeys.push(action.payload);
    },
    removeExpandedRowKey: (state, action: PayloadAction<number>) => {
      state.expandedRowKeys = state.expandedRowKeys.filter(id => id !== action.payload);
    },
    toggleExpandedRowKey: (state, action: PayloadAction<number>) => {
      if (state.expandedRowKeys.includes(action.payload)) {
        const removedKeyIndex = state.expandedRowKeys.findIndex(id => id === action.payload);
        if (removedKeyIndex !== -1) {
          state.expandedRowKeys.splice(removedKeyIndex, 1);
        }
      } else {
        state.expandedRowKeys.push(action.payload);
      }
    },
    resetSelectedRowKeys: state => {
      state.selectedRowKeys = { arr: [], obj: {} };
      state.hasSelectedRowKeys = false;
    },
    setSelectedRowKeys: (state, action: PayloadAction<number[]>) => {
      state.selectedRowKeys = {
        arr: action.payload,
        obj: action.payload.reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        }, {} as SelectedRowKeysType),
      };
      state.hasSelectedRowKeys = action.payload.length > 0;
    },
    removeSelectedRowKey: (state, action: PayloadAction<number>) => {
      if (state.selectedRowKeys.obj[action.payload]) {
        state.selectedRowKeys.arr = state.selectedRowKeys.arr.filter(id => id !== action.payload);
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete state.selectedRowKeys.obj[action.payload];
      }
      state.hasSelectedRowKeys = state.selectedRowKeys.arr.length > 0;
    },
    toggleSelectedRowKey: (state, action: PayloadAction<number>) => {
      if (state.selectedRowKeys.obj[action.payload]) {
        state.selectedRowKeys.arr = state.selectedRowKeys.arr.filter(id => id !== action.payload);
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete state.selectedRowKeys.obj[action.payload];
      } else {
        state.selectedRowKeys.arr.push(action.payload);
        state.selectedRowKeys.obj[action.payload] = true;
      }
      state.hasSelectedRowKeys = state.selectedRowKeys.arr.length > 0;
    },
    searchRedistribution: (state, action: PayloadAction<string | undefined>) => {
      state.redistributionSearchQuery = action.payload;
    },
    selectSKURedistribution: (
      state,
      action: PayloadAction<SkuRedistributionResponse | undefined>,
    ) => {
      state.selectedSKURedistribution = action.payload;
    },
    filterByBrandId: (state, action: PayloadAction<number | undefined>) => {
      if (action.payload === -1) {
        state.redistributionFilterBrandId = undefined;
      } else {
        state.redistributionFilterBrandId = action.payload;
      }
    },
    filterByOutOfStock: (state, action: PayloadAction<boolean>) => {
      state.redistributionFilterOutOfStock = action.payload;
    },
    filterByForced: (state, action: PayloadAction<boolean>) => {
      state.redistributionFilterForced = action.payload;
    },
    resetFilters: state => {
      state.redistributionFilterBrandId = undefined;
      state.redistributionFilterOutOfStock = false;
      state.redistributionFilterForced = false;
    },
    filterCalculations: (state, action: PayloadAction<CalculationsTableFilters>) => {
      state.calculationFilters = action.payload;
    },
    setRedistributionPairingCount: (state, action: PayloadAction<number>) => {
      if (action.payload > 0) {
        state.redistributionPairingCount = action.payload;
      } else {
        state.redistributionPairingCount = NO_VALUE;
      }
    },
    setCalculationsTableParams: (state, action: PayloadAction<CalculationTableParams>) => {
      state.calculationsTableParams = action.payload;
    },
    resetState: () => initialState,
  },
});

export const {
  resetExpandedRowKeys,
  addExpandedRowKey,
  removeExpandedRowKey,
  toggleExpandedRowKey,
  resetSelectedRowKeys,
  setSelectedRowKeys,
  removeSelectedRowKey,
  toggleSelectedRowKey,
  searchRedistribution,
  selectSKURedistribution,
  filterByBrandId,
  filterByOutOfStock,
  filterCalculations,
  filterByForced,
  resetFilters,
  resetState,
  setRedistributionPairingCount,
  setCalculationsTableParams,
} = calculationsSlice.actions;

export const calculationsReducer = calculationsSlice.reducer;
