import React, { useCallback, useMemo } from 'react';
import { SkuData, SkuRedistributionPaneType } from '../../redistributionTypes';
import { COLORS, Label } from '@ydistri/ds';
import { ColumnsType } from 'antd/es/table';
import { SimpleTable } from '../../../Statistics/components/SimpleTable';
import { styled } from 'styled-components';
import SupplyCoverage from '../SupplyCoverage';

interface SupplyInfoTooltipData {
  label: string;
  value: string;
  className?: string;
  coverage?: React.ReactNode;
}

const columnsAttributes: ColumnsType<SupplyInfoTooltipData> = [
  {
    dataIndex: 'label',
    key: 'label',
    width: '13rem',
    render: (value: string) => <Label>{value}</Label>,
  },
  {
    dataIndex: 'value',
    key: 'value',
    align: 'right',
  },
  {
    dataIndex: 'coverage',
    key: 'coverage',
    align: 'right',
  },
];

const TooltipTable = styled(SimpleTable<SupplyInfoTooltipData>)`
  .highlighted td {
    color: ${COLORS.PRIMARY};
  }
`;

interface SupplyInfoTooltipProps {
  infoType: SkuRedistributionPaneType;
  sku: SkuData;
  quantity: number | null;
  totalMovedQuantity: number | null;
}

const SupplyInfoTooltip: React.FC<SupplyInfoTooltipProps> = ({
  infoType,
  sku,
  quantity,
  totalMovedQuantity,
}: SupplyInfoTooltipProps) => {
  const availableSupply = useMemo(() => {
    const txtAvailableSupply = sku.attributes.values.AvailableSupply;
    if (txtAvailableSupply) {
      return parseFloat(txtAvailableSupply);
    } else {
      return 0;
    }
  }, [sku]);

  const addOpenPurchaseOrdersToAvailableSupply = useMemo(() => {
    const value = sku.config.PtAddOpenPurchaseOrdersToAvailableSupply;
    return !!(value && value === '1');
  }, [sku]);

  const openPurchaseOrdersQuantity = useMemo(() => {
    const value = sku.attributes.values.OpenPurchaseOrdersQuantity;
    if (value) {
      return parseFloat(value);
    } else {
      return 0;
    }
  }, [sku]);

  const tooltipData = useMemo((): SupplyInfoTooltipData[] => {
    const plusMinus = (amount: number, fixed?: string | undefined): string => {
      if (amount === 0) return `${amount.toFixed(0)}`;
      const result = infoType === 'Target' ? '+' : '-';
      return `${fixed ?? result}${amount.toFixed(0)}`;
    };

    const result: SupplyInfoTooltipData[] = [
      {
        label: '',
        value: '',
        className: 'highlighted',
        coverage: <b>Coverage</b>,
      },
      {
        label: 'Inventory:',
        value: `${availableSupply.toFixed(0)} MU`,
        className: 'highlighted',
        coverage: (
          <SupplyCoverage
            skuId={sku?.id}
            supply={availableSupply}
            infoType={infoType}
            displayType="text"
          />
        ),
      },
    ];

    if (sku.config.outgoingQuantity > 0) {
      result.push({
        label: 'Outgoing redistributions:',
        value: `${plusMinus(sku.config.outgoingQuantity, '-')} MU`,
      });
    }

    if (sku.config.incomingQuantity > 0) {
      result.push({
        label: 'Incoming redistributions:',
        value: `${plusMinus(sku.config.incomingQuantity, '+')} MU`,
      });
    }

    if (infoType === 'Target' && addOpenPurchaseOrdersToAvailableSupply) {
      result.push({
        label: 'Open purchase orders:',
        value: `${plusMinus(openPurchaseOrdersQuantity)} MU`,
      });
    }

    const finalSupply =
      availableSupply +
      sku.config.incomingQuantity -
      sku.config.outgoingQuantity +
      (infoType === 'Target' && addOpenPurchaseOrdersToAvailableSupply
        ? openPurchaseOrdersQuantity
        : 0) +
      (infoType === 'Source' ? -1 : 1) * (totalMovedQuantity ?? 0);

    return result.concat([
      {
        label: 'This route',
        value: `${plusMinus(quantity ?? 0)} MU`,
      },
      {
        label: 'Other routes',
        value: `${plusMinus(
          totalMovedQuantity && quantity ? totalMovedQuantity - quantity : 0,
        )} MU`,
      },
      {
        label: 'Final supply',
        value: `${finalSupply.toFixed(0)} MU`,
        className: 'highlighted',
        coverage: (
          <SupplyCoverage
            skuId={sku?.id}
            supply={finalSupply}
            infoType={infoType}
            displayType="text"
          />
        ),
      },
    ]);
  }, [
    addOpenPurchaseOrdersToAvailableSupply,
    availableSupply,
    infoType,
    openPurchaseOrdersQuantity,
    quantity,
    sku.config.incomingQuantity,
    sku.config.outgoingQuantity,
    sku?.id,
    totalMovedQuantity,
  ]);

  const classNameProvider = useCallback(
    (record: SupplyInfoTooltipData) => record.className ?? '',
    [],
  );

  return (
    <TooltipTable
      id="SupplyInfoTooltip"
      dataSource={tooltipData}
      columns={columnsAttributes}
      showHeader={false}
      rowClassName={classNameProvider}
    />
  );
};

export default SupplyInfoTooltip;
