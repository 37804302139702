import { CalculationStatus, CalculationWithOverviewResponse } from '@ydistri/api-sdk';
import React, { useCallback } from 'react';
import { Row } from '@ydistri/ds';
import ToggleStatisticsAction from './ToggleStatisticsAction';
import CalculationContextMenu from './CalculationContextMenu';
import { isCalculationFinished } from '../calculationsLib';
import { ActionsBar } from './ActionsBar2';

interface CalculationActionsProps {
  calculation: CalculationWithOverviewResponse;
  /** handler for the delete action */
  onDelete?: (calculation: CalculationWithOverviewResponse) => void;
  /** handler for additional statistics to be displayed */
  onToggleStatistics?: (calculationId: number) => void;
  /** set when the calculation is being deleted */
  disabled?: boolean;
  /** id of the actions bar */
  id?: string;
}

const CalculationActions: React.FC<CalculationActionsProps> = ({
  calculation,
  onDelete,
  onToggleStatistics,
  id,
  disabled = false,
}: CalculationActionsProps) => {
  const toggleStatisticsHandler = useCallback(() => {
    if (onToggleStatistics) {
      onToggleStatistics(calculation.id);
    }
  }, [calculation, onToggleStatistics]);

  return (
    <Row $flexWrap="nowrap">
      <ActionsBar id={id} wrap={false}>
        {onToggleStatistics && calculation.status === CalculationStatus.Completed && (
          <ToggleStatisticsAction
            onClick={toggleStatisticsHandler}
            disabled={disabled}
            size="small"
          />
        )}

        {isCalculationFinished(calculation.status) && !disabled && (
          <CalculationContextMenu calculation={calculation} onDelete={onDelete} />
        )}
      </ActionsBar>
    </Row>
  );
};

export default CalculationActions;
