import React, { useMemo } from 'react';
import { MinMaxForecast, SkuData } from '../../redistributionTypes';
import SkuInfo, { SkuInfoData } from './SkuInfo';
import SkuInfoTypeConfiguration from '../SkuInfo/SkuInfoDetails/SkuInfoTypeConfiguration';
import SkuInfoTypeValue from '../SkuInfo/SkuInfoDetails/SkuInfoTypeValue';
import CountableText from '../../../../../components/global/CountableText/CountableText';
import NoData, { NO_DATA_PLACEHOLDER } from '../../../../../components/global/NoData/NoData';
import { Row, Text } from '@ydistri/ds';
import { fullMonthDiff, getForecastApproachLabel } from '@ydistri/utils';
import { format } from 'date-fns';
import { forecastTypesDefault } from '../../../../../lib/charts/saleChartsLib';
import ChartLineLegendGraphic from '../../../../Detail/sections/components/DetailSaleChartTooltip/ChartLineLegendGraphic';

interface SkuInfoTargetProps {
  skuData?: SkuData;
  applicationDate: Date;
  usedForecast?: MinMaxForecast;
  showActualData?: boolean;
}
const getMuToGet = (skuData: SkuData, usedForecastMin?: number) => {
  if (skuData.attributes.values.AvailableSupply && usedForecastMin) {
    const availableSupply = skuData.attributes.values.AvailableSupply
      ? parseFloat(skuData.attributes.values.AvailableSupply)
      : 0;

    return `(${Math.max(usedForecastMin - availableSupply, 0)} MU to get)`;
  }
};

const getMonthsToSelloutValue = (skuData: SkuData, usedForecast?: MinMaxForecast) => {
  return (
    <Row $gap="0.5rem">
      <Text $semiBold>{`${usedForecast?.min ?? NO_DATA_PLACEHOLDER} MU forecasted`}</Text>{' '}
      <Text>{getMuToGet(skuData, usedForecast?.min)}</Text>
    </Row>
  );
};

const getMonthsSinceLastShipmentValue = (sku: SkuData, applicationDate: Date) => {
  if (!sku.attributes.dates.dateOfLastPurchase) {
    return <Text>No purchase info</Text>;
  }

  return (
    <Row $gap="0.5rem">
      <CountableText
        value={fullMonthDiff(sku.attributes.dates.dateOfLastPurchase, applicationDate)}
        word="month"
        highlighting="both"
        suffix="from last shipment to store"
      />
      <Text>{`(Last shipment: ${format(
        sku.attributes.dates.dateOfLastPurchase,
        'dd. MM. yyyy',
      )})`}</Text>
    </Row>
  );
};

const SkuInfoTarget: React.FC<SkuInfoTargetProps> = ({
  skuData,
  applicationDate,
  usedForecast,
  showActualData = false,
}) => {
  const tableData = useMemo(() => {
    const result: SkuInfoData[] = [];

    result.push({
      label: skuData?.type ?? 'Type',
      configuration: <SkuInfoTypeConfiguration sku={skuData} />,
      value: skuData ? (
        <SkuInfoTypeValue sku={skuData} applicationDate={applicationDate} />
      ) : (
        <NoData />
      ),
    });

    result.push({
      label: 'Months to sellout',
      configuration: (
        <CountableText value={skuData?.config.PtMonthsOfSupplyToGetByForecast} word="month" />
      ),
      value: skuData && getMonthsToSelloutValue(skuData, usedForecast),
    });

    result.push({
      label: 'Min no. of sales within last 6 months',
      configuration: (
        <CountableText value={skuData?.config.PtMinimal6MonthsFrequencyOfSales} word="sale" />
      ),
      value: (
        <CountableText
          value={skuData?.attributes.values.Frequency6Month}
          word="sale"
          highlighting="both"
        />
      ),
    });

    const minLayers = skuData?.targetMinLayerLists ?? [];

    result.push({
      label: 'Minimum layers',
      configuration: minLayers.length > 0 ? 'Yes' : 'No',
      value:
        minLayers.length > 0 ? (
          <table>
            {minLayers.map(ml => (
              <tr key={ml.entityListVersionId}>
                <td>
                  <Text $semiBold>{ml.name}</Text>
                </td>
                <td>{ml.quantity} MU</td>
              </tr>
            ))}
          </table>
        ) : (
          ' - '
        ),
    });

    const fctMinLine = forecastTypesDefault.find(ft => ft.priority === 1)?.lineInfo;
    const fctMaxLine = forecastTypesDefault.find(ft => ft.priority === 2)?.lineInfo;

    result.push({
      label: 'Forecasting approach',
      configuration: (
        <>
          <Row $alignItems="center" $gap="0.5rem">
            {fctMaxLine ? <ChartLineLegendGraphic $lineInfo={fctMaxLine} /> : null}
            <Text>Source</Text>
          </Row>
          <Row $alignItems="center" $gap="0.5rem">
            {fctMinLine ? <ChartLineLegendGraphic $lineInfo={fctMinLine} /> : null}
            <Text $bold>Target</Text>
          </Row>
        </>
      ),
      value: (
        <>
          <Text>{getForecastApproachLabel(skuData?.config.PsMaxForecastConfidence)}</Text>
          <br />
          <Text $bold>{getForecastApproachLabel(skuData?.config.PtMinForecastConfidence)}</Text>
        </>
      ),
    });

    result.push({
      label: 'Months since last shipment to store',
      configuration: <NoData />,
      value: skuData ? getMonthsSinceLastShipmentValue(skuData, applicationDate) : <NoData />,
    });

    const includeOpenPurchaseOrders =
      skuData?.config.PtAddOpenPurchaseOrdersToAvailableSupply &&
      parseInt(skuData.config.PtAddOpenPurchaseOrdersToAvailableSupply) === 1;
    result.push({
      label: 'Include open purchase orders',
      configuration: includeOpenPurchaseOrders ? 'Yes' : 'No',
      value: (
        <Row $gap="0.2rem">
          <CountableText
            value={skuData?.attributes.values.OpenPurchaseOrdersQuantity}
            word="MU"
            highlighting="both"
            parser={parseFloat}
          />
          {!includeOpenPurchaseOrders && <Text> - not used</Text>}
        </Row>
      ),
    });

    return result;
  }, [applicationDate, skuData, usedForecast]);

  return (
    <SkuInfo
      data={tableData}
      showActualData={showActualData}
      skuClass={skuData?.skuClass}
      targetList={skuData?.targetList}
    />
  );
};

export default SkuInfoTarget;
