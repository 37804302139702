import React, { useCallback, useMemo, useState } from 'react';
import {
  CategoryConfigurationDefinitionResponse,
  ConfigurationDataType,
  ConfigurationSectionType,
  FieldConfigurationDeleteRequest,
  FieldConfigurationRequest,
  SkuType,
} from '@ydistri/api-sdk';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { Select } from 'antd';
import {
  Button,
  ConfigurationInput,
  ConfigurationSelect,
  ConfigurationSwitch,
  Row,
  Text,
} from '@ydistri/ds';
import { PartialExceptionExpanded } from './categoryExceptionsModalLib';
import { usePatchCategoryConfigurationMutation } from '../../Configuration/apiTemplate';
import { useDeleteCategoryConfigurationMutation } from '../../../components/global/CategoryTree/apiCategoryTree';
import { ConfigurationFieldStatus, configurationSelectOptions } from '@ydistri/utils';
import { createPayload } from '../../../components/global/ConfigurationField/createPayload';
import { iterableSkuTypes } from '../../../helpers/types/iterableEnums';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';

const optionsSkuTypes: DefaultOptionType[] = [
  {
    value: 'all',
    label: 'All SKU types',
  },
  {
    value: SkuType.DeadStock,
    label: 'DeadStock',
  },
  {
    value: SkuType.SlowMover,
    label: 'SlowMover',
  },
  {
    value: SkuType.FastMover,
    label: 'FastMover',
  },
] as const;

interface ResetFormFieldProps {
  definition: CategoryConfigurationDefinitionResponse;
  exceptionsByField: PartialExceptionExpanded[];
}

const ResetFormField: React.FC<ResetFormFieldProps> = ({ definition, exceptionsByField }) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const [skuType, setSkuType] = useState<SkuType | 'all'>();
  const [actionValue, setActionValue] = useState<string | undefined>(
    definition.dataType === ConfigurationDataType.CheckBox ? '0' : undefined,
  );

  const [patchConfiguration, { isLoading: isLoadingPatch }] =
    usePatchCategoryConfigurationMutation();
  const [deleteConfiguration, { isLoading: isLoadingDelete }] =
    useDeleteCategoryConfigurationMutation();

  const isTarget = definition.sectionType === ConfigurationSectionType.PotentialTarget;
  const isSkuTypeDefinition = definition.sectionType === ConfigurationSectionType.General;

  const handleSubmit = useCallback(() => {
    if (actionValue === undefined || actionValue === '' || (!skuType && !isTarget)) return;

    exceptionsByField.forEach(e => {
      const categoryId = e.category.id;

      const basePayload: FieldConfigurationRequest = {
        configurationFieldType: definition.fieldType,
        value: actionValue,
        skuType: undefined,
      };

      const payload: FieldConfigurationRequest[] = [];
      if (skuType === 'all') {
        iterableSkuTypes.forEach(st => {
          payload.push(...createPayload<FieldConfigurationRequest>(basePayload, st));
        });
      } else {
        payload.push({ ...basePayload, skuType });
      }

      patchConfiguration({
        categoryId,
        payload,
      });
    });
  }, [actionValue, skuType, isTarget, exceptionsByField, definition.fieldType, patchConfiguration]);

  const handleClear = useCallback(() => {
    if (!skuType && !isTarget) return;

    exceptionsByField.forEach(e => {
      const categoryId = e.category.id;

      const basePayload: FieldConfigurationDeleteRequest = {
        configurationFieldType: definition.fieldType,
        skuType: undefined,
      };

      const payload: FieldConfigurationDeleteRequest[] = [];

      if (skuType === 'all') {
        iterableSkuTypes.forEach(st => {
          payload.push(...createPayload<FieldConfigurationDeleteRequest>(basePayload, st));
        });
      } else {
        payload.push({ ...basePayload, skuType });
      }

      deleteConfiguration({
        categoryId,
        fields: payload,
      });
    });
  }, [skuType, isTarget, exceptionsByField, definition.fieldType, deleteConfiguration]);

  const disabled = isLoadingPatch || isLoadingDelete;

  const input = useMemo(() => {
    switch (definition.dataType) {
      case ConfigurationDataType.SelectBoxMinLayerList:
      case ConfigurationDataType.SelectBoxTargetList:
        return null;
      case ConfigurationDataType.CheckBox:
        return (
          <ConfigurationSwitch
            status={ConfigurationFieldStatus.DEFAULT}
            value={actionValue === '1'}
            disabled={disabled}
            onChange={setActionValue}
          />
        );
      case ConfigurationDataType.SelectBoxForecastConfidence:
      case ConfigurationDataType.SelectBoxMonthIdentifySkuType:
      case ConfigurationDataType.SelectBoxMonthsForForecast:
      case ConfigurationDataType.SelectBoxForcingLevelType:
        return (
          <ConfigurationSelect
            status={ConfigurationFieldStatus.DEFAULT}
            options={configurationSelectOptions[definition.dataType]}
            disabled={disabled}
            value={actionValue}
            onChange={setActionValue}
          />
        );
      case ConfigurationDataType.InputNumber:
        return (
          <ConfigurationInput
            status={ConfigurationFieldStatus.DEFAULT}
            disabled={disabled}
            value={parseInt(actionValue ?? '')}
            onChange={setActionValue}
          />
        );
    }
  }, [definition.dataType, actionValue, disabled]);

  if (isSkuTypeDefinition) return null;
  if (templateOrCalculation.type !== 'Template') return null;

  return (
    <Row $gap="0.5rem">
      <Text $bold>Field actions - </Text>
      {!isTarget && (
        <>
          <Text>SKU Type:</Text>
          <Select
            onChange={setSkuType}
            value={skuType}
            options={optionsSkuTypes}
            variant="outlined"
            popupMatchSelectWidth={false}
          />
        </>
      )}
      <Text>Value:</Text>
      {input}
      <Button $sizeVariant="xSmall" $colorVariant="primary" onClick={handleSubmit}>
        Set
      </Button>
      <Button $sizeVariant="xSmall" $colorVariant="ghostDanger" onClick={handleClear}>
        Clear
      </Button>
    </Row>
  );
};

export default ResetFormField;
