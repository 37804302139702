import React, { useMemo } from 'react';
import { styled } from 'styled-components';
import { PairingValueText } from './PairingValueText';
import { Row, computeRemSize, COLORS } from '@ydistri/ds';
import { createDebugLog } from '../../../../lib/utils/logging';
import CalculationFinalRedistributionTooltipContent from './CalculationFinalRedistributionTooltipContent';
import { Popover } from 'antd';

const debugLog = createDebugLog('Calculations', 'FinalRedistribution');

interface RedistributionDisplayProps {
  $production: boolean;
}

const RowWrapper = styled(Row)`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`;

const RedistributionDisplay = styled.div<RedistributionDisplayProps>`
  font-size: ${computeRemSize(10)};
  opacity: ${props => (props.$production ? 0.8 : 1)};
  text-align: center;
  padding: ${computeRemSize(6)};
  min-width: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
`;

const RedistributionDeadStock = styled(RedistributionDisplay)`
  background-color: ${COLORS.BAR_DEADSTOCK_FADED};
`;

const RedistributionSlowMover = styled(RedistributionDisplay)`
  background-color: ${COLORS.BAR_SLOWMOVERS_FADED};
`;

const RedistributionFastMover = styled(RedistributionDisplay)`
  background-color: ${COLORS.BAR_FASTMOVERS_FADED};
`;

const RedistributionTotal = styled(RedistributionDisplay)`
  font-weight: 500;
`;

interface CalculationFinalRedistributionProps {
  calculationId: number;
  totalValue: number;
  deadStockValue: number;
  slowMoverValue: number;
  fastMoverValue: number;
  production: boolean;
  currency?: string;
}

const CalculationFinalRedistribution: React.FC<CalculationFinalRedistributionProps> = ({
  production,
  totalValue,
  deadStockValue,
  slowMoverValue,
  fastMoverValue,
  calculationId,
  currency = '...',
}: CalculationFinalRedistributionProps) => {
  debugLog(`rendering ${calculationId}`);

  const tooltipContent = useMemo(() => {
    return (
      <CalculationFinalRedistributionTooltipContent
        deadStockValue={deadStockValue}
        slowMoverValue={slowMoverValue}
        fastMoverValue={fastMoverValue}
        totalValue={totalValue}
        currency={currency}
      />
    );
  }, [currency, deadStockValue, fastMoverValue, slowMoverValue, totalValue]);

  return (
    <Popover content={tooltipContent}>
      <RowWrapper $flexWrap="nowrap">
        <RedistributionDeadStock $production={production}>
          <PairingValueText
            value={deadStockValue}
            color={COLORS.DEADSTOCK_COLOR}
            currency={currency}
          />
        </RedistributionDeadStock>
        <RedistributionSlowMover $production={production}>
          <PairingValueText
            value={slowMoverValue}
            color={COLORS.SLOWMOVER_COLOR}
            currency={currency}
          />
        </RedistributionSlowMover>
        <RedistributionFastMover $production={production}>
          <PairingValueText
            value={fastMoverValue}
            color={COLORS.FASTMOVER_COLOR}
            currency={currency}
          />
        </RedistributionFastMover>
        <RedistributionTotal $production={production}>
          <PairingValueText
            value={totalValue}
            color={COLORS.ALL_SKU_TYPES_COLOR}
            currency={currency}
          />
        </RedistributionTotal>
      </RowWrapper>
    </Popover>
  );
};

export default React.memo(CalculationFinalRedistribution);
