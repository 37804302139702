import {
  CalculationSkuResponse,
  CategoryResponse,
  ConfigurationFieldType,
  SkuAttributeResponse,
  SkuFieldConfigurationResponse,
} from '@ydistri/api-sdk';
import {
  SkuData,
  SkuFieldConfiguration,
  SkuRedistributionPaneType,
  SkuRedistributionSubset,
} from './redistributionTypes';
import { parseSkuAttributes } from '../../../lib/sku/skuLib';

/**
 * Extract category titles from categories to an array sorted by category level
 * with category level 0 removed.
 * @param categories
 * @returns array of category titles
 */
export const extractCategoryTitles = (categories: CategoryResponse[]): string[] => {
  return categories
    .filter(category => category.level > 0)
    .sort((a: CategoryResponse, b: CategoryResponse) => a.level - b.level)
    .map(category => category.title);
};

const parseConfig = (skuConfiguration: CalculationSkuResponse): SkuFieldConfiguration => {
  const result: SkuFieldConfiguration = {
    isForced: skuConfiguration.isForced,
    isClosing: skuConfiguration.isClosing,
    outgoingQuantity: skuConfiguration.outgoingQuantity,
    incomingQuantity: skuConfiguration.incomingQuantity,
  };

  skuConfiguration.fieldConfigurations.forEach(
    fieldConfiguration => (result[fieldConfiguration.fieldType] = fieldConfiguration.value),
  );

  return result;
};

export const parseSku = (
  skuConfiguration: CalculationSkuResponse,
  skuAttributes: SkuAttributeResponse[],
): SkuData => {
  return {
    id: skuConfiguration.skuId,
    type: skuConfiguration.skuType,
    categories: extractCategoryTitles(skuConfiguration.categories),
    skuClass: skuConfiguration.skuClass ?? undefined,
    attributes: parseSkuAttributes(skuConfiguration.skuId, skuAttributes),
    config: parseConfig(skuConfiguration),
    targetList: skuConfiguration.targetList ?? undefined,
    sourceMinLayerLists: skuConfiguration.sourceMinLayerLists ?? [],
    targetMinLayerLists: skuConfiguration.targetMinLayerLists ?? [],
  };
};

export const computeFinalSupply = (
  skuConfig: SkuData,
  redistributionData: SkuRedistributionSubset,
  infoType: SkuRedistributionPaneType,
): number => {
  let availableSupply = skuConfig.attributes.values.AvailableSupply
    ? parseFloat(skuConfig.attributes.values.AvailableSupply)
    : 0;

  availableSupply -= skuConfig.config.outgoingQuantity;
  availableSupply += skuConfig.config.incomingQuantity;

  const tmpTotalMovedQuantity = redistributionData.totalMovedQuantity;

  if (infoType === 'Source') {
    return parseInt((availableSupply - tmpTotalMovedQuantity).toFixed(0));
  } else {
    let openPurchaseOrdersQuantity = 0;

    const addOpenPurchaseOrdersToAvailableSupply = skuConfig.config
      .PtAddOpenPurchaseOrdersToAvailableSupply
      ? parseInt(skuConfig.config.PtAddOpenPurchaseOrdersToAvailableSupply)
      : 0;

    if (addOpenPurchaseOrdersToAvailableSupply === 1) {
      openPurchaseOrdersQuantity = skuConfig.attributes.values.OpenPurchaseOrdersQuantity
        ? parseFloat(skuConfig.attributes.values.OpenPurchaseOrdersQuantity)
        : 0;
    }

    return parseInt(
      (availableSupply + openPurchaseOrdersQuantity + tmpTotalMovedQuantity).toFixed(0),
    );
  }
};

export const trimArray = <T>(items: T[], maxItems: number, replacement: T): T[] => {
  const result = [...items];
  if (result.length > maxItems) {
    const lastIndexToKeep = result.length - (maxItems - 1);
    result.splice(1, lastIndexToKeep - 1, replacement);
  }

  return result;
};

export const getSkuFieldConfigurationValue = (
  fieldConfigurations: SkuFieldConfigurationResponse[],
  fieldConfigurationName: ConfigurationFieldType,
): string | undefined => {
  const fieldConfiguration = fieldConfigurations.find(
    fieldConfiguration => fieldConfiguration.fieldType === fieldConfigurationName,
  );
  if (fieldConfiguration) {
    return fieldConfiguration.value;
  } else {
    return undefined;
  }
};

export const getSkuFieldConfigurationNumericValue = (
  fieldConfigurations: SkuFieldConfigurationResponse[],
  fieldConfigurationName: ConfigurationFieldType,
): number | undefined => {
  const txtValue = getSkuFieldConfigurationValue(fieldConfigurations, fieldConfigurationName);
  if (txtValue) {
    return parseInt(txtValue);
  } else {
    return undefined;
  }
};
