import { GenericConfirmationModal, Section } from '@ydistri/ds';
import TargetListSkuGrid from './TargetListSkuGrid';
import React, { useCallback, useMemo, useState } from 'react';
import {
  TargetListSetArchiveRequest,
  useDeleteTargetListMutation,
  useSetArchivedMutation,
} from '../../apiTargetLists';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveScreenType, setSelectedTargetList } from '../../targetListAdministrationSlice';
import { addToast } from '../../../../../store/toastSlice';
import { getSectionTitle } from '../../targetListsLib';
import AdministrationItemActions from '../../../common/AdministrationItemActions';
import TargetListDetails from '../../components/TargetListDetails';
import TargetListModal from '../../modals/TargetListModal';
import { ActiveScreenType } from '../../../common/administrationItemsLib';
import { ReduxState } from '../../../../../store';

const TargetListItemsSection: React.FC = () => {
  const dispatch = useDispatch();

  const selectedTargetList = useSelector(
    (state: ReduxState) => state.targetListAdministration.selectedTargetList,
  );
  const showArchivedItems = useSelector(
    (state: ReduxState) => state.targetListAdministration.showArchivedItems,
  );
  const [isBusy, setIsBusy] = useState(false);
  const [showDeleteTargetListConfirmation, setShowDeleteTargetListConfirmation] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [setArchived] = useSetArchivedMutation();
  const [deleteTargetList] = useDeleteTargetListMutation();

  /**
   * Delete Target List action handler.
   * Display a confirmation modal.
   */
  const onTargetListDelete = useCallback(() => {
    setShowDeleteTargetListConfirmation(true);
  }, []);

  /**
   * Close the confirmation modal
   */
  const closeTargetListDeleteConfirm = useCallback(() => {
    setShowDeleteTargetListConfirmation(false);
  }, []);

  /**
   * Delete target list action was confirmed, perform the delete action.
   */
  const onTargetListDeleteConfirm = useCallback(() => {
    closeTargetListDeleteConfirm();
    if (selectedTargetList) {
      setIsBusy(true);
      if (!isBusy) {
        deleteTargetList(selectedTargetList.targetListId)
          .unwrap()
          .then(() => {
            dispatch(setSelectedTargetList(undefined));
          })
          .catch((error: Error) => {
            dispatch(
              addToast({
                isError: true,
                message: 'Target List was not deleted due to an error',
                description: error.message,
              }),
            );
          })
          .finally(() => setIsBusy(false));
      }
    }
  }, [deleteTargetList, selectedTargetList, isBusy, dispatch, closeTargetListDeleteConfirm]);

  /**
   * Mark Target List as archived or unarchived
   */
  const onTargetListArchiveToggle = useCallback(() => {
    if (!selectedTargetList) {
      return null;
    }

    if (!isBusy) {
      setIsBusy(true);
      const request: TargetListSetArchiveRequest = {
        targetListId: selectedTargetList.targetListId,
        isArchived: !selectedTargetList.isArchived,
      };
      setArchived(request)
        .unwrap()
        .then(updatedTargetList => {
          //deselect archived target lists if archived product lsits are not displayed
          if (!showArchivedItems) {
            dispatch(setSelectedTargetList(undefined));
          } else {
            dispatch(setSelectedTargetList(updatedTargetList));
          }

          dispatch(
            addToast({
              message: `Target List was ${
                updatedTargetList.isArchived ? 'archived' : 'unarchived'
              }`,
            }),
          );
        })
        .catch((error: Error) => {
          dispatch(
            addToast({
              isError: true,
              message: `Target List's archive status was not changed ${error.message}`,
            }),
          );
        })
        .finally(() => {
          setIsBusy(false);
        });
    }
  }, [selectedTargetList, dispatch, isBusy, setArchived, showArchivedItems]);

  const onTargetListContentEdit = useCallback(() => {
    dispatch(setActiveScreenType(ActiveScreenType.EDIT));
  }, [dispatch]);

  const onTargetListEdit = useCallback(() => {
    setShowEditModal(true);
  }, []);

  const onEditTargetListModalClose = useCallback(() => {
    setShowEditModal(false);
  }, []);

  /**
   * Actions for the header of the section
   */
  const headerActions = useMemo(() => {
    let deleteTooltip: string | undefined;
    if (selectedTargetList?.isDeletable === false) {
      deleteTooltip =
        'Cannot delete the Target List because it is currently used in a configuration or calculation';
    }

    return (
      <AdministrationItemActions
        itemName="Target List"
        deletable={selectedTargetList?.isDeletable}
        archivable={selectedTargetList?.isArchivable}
        archived={selectedTargetList?.isArchived}
        onDelete={onTargetListDelete}
        onToggleArchived={onTargetListArchiveToggle}
        onEdit={onTargetListEdit}
        onContentEdit={onTargetListContentEdit}
        disabled={isBusy}
        deleteTooltip={deleteTooltip}
      />
    );
  }, [
    selectedTargetList?.isDeletable,
    selectedTargetList?.isArchivable,
    selectedTargetList?.isArchived,
    onTargetListDelete,
    onTargetListArchiveToggle,
    onTargetListEdit,
    onTargetListContentEdit,
    isBusy,
  ]);

  // render the content
  if (!selectedTargetList) {
    return null;
  } else {
    return (
      <>
        <Section header={getSectionTitle(selectedTargetList)} headerActions={headerActions}>
          <>
            <TargetListDetails targetList={selectedTargetList} />
            <TargetListSkuGrid
              selectedTargetListId={selectedTargetList.targetListId}
              configurationType={selectedTargetList.targetListVersionConfigurationTypeId}
            />
          </>
        </Section>
        {showDeleteTargetListConfirmation && (
          <GenericConfirmationModal
            data-testid="DeleteTargetListConfirmationDialog"
            title="Delete Target List"
            message={`The Target List '${
              selectedTargetList.name ?? '-no title-'
            }' will be deleted. Continue?`}
            onConfirmed={onTargetListDeleteConfirm}
            onCanceled={closeTargetListDeleteConfirm}
          />
        )}
        {showEditModal && (
          <TargetListModal targetList={selectedTargetList} onClose={onEditTargetListModalClose} />
        )}
      </>
    );
  }
};

export default TargetListItemsSection;
