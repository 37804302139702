import React, { useMemo } from 'react';
import { Column, Row, COLORS, Title, Text, computeRemSize } from '@ydistri/ds';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { styled } from 'styled-components';
import { useCurrency } from '../../../hooks/useCurrency';
import { formatNumber } from '@ydistri/utils';

const ContentWrapper = styled(Column)`
  margin: 0.8rem;
  justify-content: center;
  align-items: center;
  flex-basis: ${computeRemSize(110)};
  flex-shrink: 0;
`;

const SummaryLabel = styled(Text)`
  text-transform: uppercase;
`;

interface RedistributionLabeledValueProps {
  label: string;
  value: string;
  unit: string;
}

/**
 * Single large redistribution value with label and unit. Used for quantity and value.
 * @param label Displayed in uppercase
 * @param value
 * @param unit
 * @constructor
 */
const RedistributionLabeledValue: React.FC<RedistributionLabeledValueProps> = ({
  label,
  value,
  unit,
}) => {
  return (
    <ContentWrapper $gap={computeRemSize(4)}>
      <SummaryLabel>{label}</SummaryLabel>
      <Row $gap="6pt" $justifyContent="center">
        <Title>{value}</Title>
        <Title>{unit}</Title>
      </Row>
    </ContentWrapper>
  );
};

interface CalculationRedistributionSummaryProps {}

/**
 * Summarizes the redistribution of a single SKU. Displays a quantity and value
 * with graphical icon
 * @constructor
 */
const CalculationRedistributionSummary: React.FC<CalculationRedistributionSummaryProps> = () => {
  const skuRedistribution = useSelector(
    (state: ReduxState) => state.calculations.selectedSKURedistribution,
  );
  const currency = useCurrency();

  const formattedValue = useMemo(() => {
    if (skuRedistribution) {
      return formatNumber(skuRedistribution.value, 0);
    } else {
      return '';
    }
  }, [skuRedistribution]);

  const formattedQuantity = useMemo(() => {
    if (skuRedistribution) {
      return formatNumber(skuRedistribution.quantity, 0);
    } else {
      return '';
    }
  }, [skuRedistribution]);

  return (
    <ContentWrapper>
      <RedistributionLabeledValue label="Quantity" value={formattedQuantity} unit="MU" />
      <FaRegArrowAltCircleRight size={computeRemSize(48)} color={COLORS.PRIMARY} />
      <RedistributionLabeledValue label="Value" value={formattedValue} unit={currency} />
    </ContentWrapper>
  );
};

export default CalculationRedistributionSummary;
