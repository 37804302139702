import { SkuInfoValueComponent } from './SkuInfoTypes';
import { SkuType } from '@ydistri/api-sdk';
import { Text } from '@ydistri/ds';
import CountableText from '../../../../../../components/global/CountableText/CountableText';
import { fullMonthDiff, getNewerDate } from '@ydistri/utils';
import NoData, { NO_DATA_PLACEHOLDER } from '../../../../../../components/global/NoData/NoData';

const SkuInfoTypeValue: SkuInfoValueComponent = ({ sku, applicationDate }) => {
  if (sku) {
    switch (sku.type) {
      case SkuType.DeadStock:
        if (sku.config.CatDeadStock) {
          return (
            <Text>
              Currently{' '}
              <CountableText
                value={fullMonthDiff(
                  getNewerDate(
                    sku.attributes.dates.dateOfLastSale,
                    sku.attributes.dates.dateOfFirstPurchase,
                  ),
                  applicationDate,
                )}
                word="month"
                highlighting="both"
              />{' '}
              no sale
            </Text>
          );
        } else {
          return <NoData />;
        }
      case SkuType.SlowMover:
      case SkuType.FastMover: {
        if (sku.config.CatSlowMoverMonths) {
          let frequency = sku.attributes.values.Frequency12Month;
          const slowMoverMonths = parseInt(sku.config.CatSlowMoverMonths);
          if (slowMoverMonths === 6) {
            frequency = sku.attributes.values.Frequency6Month;
          }

          return (
            <Text>
              {frequency ? (
                <CountableText value={parseInt(frequency)} word="sale" highlighting="both" />
              ) : (
                NO_DATA_PLACEHOLDER
              )}{' '}
              in last <CountableText value={slowMoverMonths} word="month" highlighting="none" />
            </Text>
          );
        } else {
          return <NoData />;
        }
      }
    }
  }
};
export default SkuInfoTypeValue;
