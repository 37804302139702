import React, { useCallback, useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import {
  UpdateCalculationConfigurationPayload,
  useGetCalculationConfigurationQuery,
  useUpdateCalculationConfigurationMutation,
} from '../../../apis/apiGlobalConfiguration';
import {
  ConfigurationFieldStatus,
  rtkQueryPatchStatusToConfigurationFieldStatus,
} from '@ydistri/utils';
import { ConfigurationSwitch } from '@ydistri/ds';

interface ClosingToggleProps {}

const ClosingSwitch: React.FC<ClosingToggleProps> = () => {
  const templateOrCalculation = useTemplateOrCalculation();

  const { data: calculationConfiguration, isFetching } =
    useGetCalculationConfigurationQuery(templateOrCalculation);

  const [updateConfiguration, updateConfigurationStatus] =
    useUpdateCalculationConfigurationMutation();

  const onChangeHandler = useCallback(() => {
    const payload: UpdateCalculationConfigurationPayload = {
      templateOrCalculation,
      request: {
        useClosingStores: !calculationConfiguration?.useClosingStores,
      },
    };

    updateConfiguration(payload);
  }, [calculationConfiguration?.useClosingStores, templateOrCalculation, updateConfiguration]);

  const status = useMemo(() => {
    console.log('STATUS: ', isFetching, updateConfigurationStatus);

    return isFetching
      ? ConfigurationFieldStatus.LOADING
      : rtkQueryPatchStatusToConfigurationFieldStatus(updateConfigurationStatus);
  }, [updateConfigurationStatus, isFetching]);

  return (
    <ConfigurationSwitch
      disabled={templateOrCalculation.type === 'Calculation'}
      value={calculationConfiguration?.useClosingStores ?? false}
      onChange={onChangeHandler}
      status={status}
    />
  );
};

export default ClosingSwitch;
