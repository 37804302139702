import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { setSubpage } from '../../../routes/routerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetCalculationQuery } from '../apiCalculationDetail';
import CategoryTree from '../../../components/global/CategoryTree/CategoryTree';
import { CalculationDetailSubpages } from '../../Calculations/calculationsLib';
import CalculationRedistributionSubpageContent from './CalculationRedistributionSubpageContent';
import { useSearchParams } from 'react-router-dom';
import { ReduxState } from '../../../store';
import { CalculationsCollection } from '../../../swagger/collections';
import useCategoryId from '../../../hooks/useCategoryId';
import { Operation } from '@ydistri/api-sdk';
import { selectSKURedistribution } from '../../Calculations/calculationsSlice';
import {
  MainContent,
  MainContentWrapper,
} from '../../../../../../../libs/ds/src/pages/PageLayout/pageLayoutComponents';

const PAIRINGS_SEPARATOR = '-';

const CalculationRedistributionSubpage: React.FC = () => {
  const dispatch = useDispatch();
  const calculationData = useTemplateOrCalculation();
  const { data: calculation, isError, isFetching } = useGetCalculationQuery(calculationData.id);
  const selectedCategoryId = useCategoryId();
  const [searchParams] = useSearchParams();

  const selectedItem = useSelector(
    (state: ReduxState) => state.calculations.selectedSKURedistribution,
  );

  useEffect(() => {
    dispatch(setSubpage(CalculationDetailSubpages.REDISTRIBUTION));
  }, [dispatch]);

  //we don't want to keep selected pairing "p" in the URL on category change - since its the only one URL param we have on this screen for now, we can just erase the whole search params
  const searchParamModifier = useCallback(() => '', []);

  const onCategoryChange = useCallback(() => {
    dispatch(selectSKURedistribution(undefined));
  }, [dispatch]);

  //check if the URL contains the pairing identification and load it as selected if it does,
  //and it's not already selected
  useEffect(() => {
    const pairing = searchParams.get('p');
    if (pairing === null) {
      return;
    }

    if (!pairing.includes(PAIRINGS_SEPARATOR)) {
      return;
    }

    const pairingIds = pairing.split(PAIRINGS_SEPARATOR);
    const sourceSkuId = parseInt(pairingIds[0]);
    const targetSkuId = parseInt(pairingIds[1]);

    //should we load the pairing and set it as selected?
    if (
      !selectedItem?.sourceSkuId ||
      selectedItem.sourceSkuId !== sourceSkuId ||
      !selectedItem.targetSkuId ||
      selectedItem.targetSkuId !== targetSkuId
    ) {
      CalculationsCollection.getCalculationCategoryPairings(
        calculationData.id,
        selectedCategoryId,
        {
          conditions: [
            { fieldName: 'SourceSkuId', operation: Operation.Eq, value: sourceSkuId },
            { fieldName: 'TargetSkuId', operation: Operation.Eq, value: targetSkuId },
          ],
        },
      ).then(pairing => {
        const found = pairing.data.data;
        if (found.length > 0) {
          dispatch(selectSKURedistribution(found[0]));
        }
      });
    }
  }, [calculationData.id, dispatch, searchParams, selectedCategoryId, selectedItem]);

  if (!isFetching && !isError && calculation) {
    return (
      <>
        <Helmet title={`Calculation ${calculation.id} - Redistribution`} />
        <MainContentWrapper>
          <CategoryTree
            searchParamModifier={searchParamModifier}
            onCategoryChange={onCategoryChange}
          />
          <MainContent>
            <CalculationRedistributionSubpageContent calculationId={calculation.id} />
          </MainContent>
        </MainContentWrapper>
      </>
    );
  } else {
    return null;
  }
};

export default CalculationRedistributionSubpage;
