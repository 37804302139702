import { ActionsBar, Section } from '@ydistri/ds';
import React, { PropsWithChildren, useMemo } from 'react';
import ExitEditModeButton from './buttons/ExitEditModeButton';

interface ValidationSectionProps extends PropsWithChildren {
  title: string;
  onClose: () => void;
}

const ValidationSection: React.FC<ValidationSectionProps> = ({ title, onClose, children }) => {
  const headerActions = useMemo(() => {
    return (
      <ActionsBar>
        <ExitEditModeButton onClick={onClose} />
      </ActionsBar>
    );
  }, [onClose]);

  return (
    <Section header={`${title} - Validation`} headerActions={headerActions}>
      {children}
    </Section>
  );
};

export default ValidationSection;
