import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConfigurationSubpage } from '../screens/Configuration/Configuration';
import { ProjectAdministrationSubpage } from '../screens/ProjectAdministration/projectAdministrationLib';

import { CalculationDetailSubpages } from '../screens/Calculations/calculationsLib';

export enum RoutingPage {
  USER_SETTINGS = 'user-settings',
  PROJECT_ADMINISTRATION = 'project-administration',
  DETAIL = 'detail',
  CALCULATIONS = 'calculations',
  CALCULATION_DETAIL = 'calculation-detail',
  CONFIGURATION = 'configuration',
  LAYOUTS = 'layouts',
}

export interface Params {
  projectShortName: string;
  calculationId: string;
  templateId: string;
  slug: string;
}

export type RoutingSubpage =
  | CalculationDetailSubpages
  | ConfigurationSubpage
  | ProjectAdministrationSubpage;

export interface RouterState {
  page?: RoutingPage;
  subpage?: RoutingSubpage;
}

const initialState: RouterState = {
  page: undefined,
  subpage: undefined,
};

export const generatePartialPath = (
  template: string,
  params: Partial<Params>,
  subpage: RoutingSubpage | string | undefined,
): string => {
  const split = template.split('/');

  //save part of the route until some parameter does not exist
  let route = '';
  for (let i = 1; i < split.length; i++) {
    const part = split[i];
    if (part.startsWith(':')) {
      const param = part.substring(1);

      // @ts-expect-error params is not indexable with string
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      let value: string | undefined = params[param];

      if (param === 'subpage' && subpage) value = subpage;

      if (value === undefined || value === '') break;

      route += `/${value}`;
    } else {
      route += `/${part}`;
    }
  }

  if (route === '') throw new Error('Route is empty');

  return route;
};

export const routerSlice = createSlice({
  name: 'routerReducer',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<RoutingPage | undefined>) => {
      state.page = action.payload;
    },
    setSubpage: (state, action: PayloadAction<RoutingSubpage | undefined>) => {
      state.subpage = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setPage, setSubpage } = routerSlice.actions;

export const routerReducer = routerSlice.reducer;
