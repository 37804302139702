import React from 'react';
import { ChartDataset, TooltipModel, Chart } from 'chart.js';

import { format } from 'date-fns';
import { formatNumber, fullMonthDiff, padLeft } from '@ydistri/utils';
import './SkuSaleChartTooltip.scss';
import { computeRemSize } from '@ydistri/ds';
import {
  LINE_TYPES,
  TLineType,
  TParsedMonthlyTransactions,
} from '../../../../lib/charts/saleChartsTypes';

const DATE_FORMAT_STRING: string = 'dd. MM. yyyy';

const addVat = (value: number, vat: number) =>
  !isNaN(vat) ? Math.floor((value + (value / 100) * vat) * 100) / 100 : value;

// eslint-disable-next-line max-params
export default function skuSaleGraphTooltip(
  tooltipModel: TooltipModel<'line'>,
  lines: TLineType[],
  datasets: ChartDataset[],
  parsedMonthlyTransactions: TParsedMonthlyTransactions[],
  applicationDate: Date,
  chartRef: React.RefObject<Chart<'line'>>,
  currency: string,
  vat: number,
  showAvgValue: boolean,
): Date | undefined {
  let tooltipEl = document.getElementById('chartjs-tooltip');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.className = 'graph-tooltip';
    tooltipEl.innerHTML = '<div class="innerDivStyle"></div><table></table>';
    document.body.appendChild(tooltipEl);
  }

  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  if (tooltipModel.dataPoints.length > 0) {
    const dataPoint = tooltipModel.dataPoints[0];

    const monthIndex: number = dataPoint.dataIndex || 0;
    const monthlyData: TParsedMonthlyTransactions = parsedMonthlyTransactions[monthIndex];
    const isFuture = monthlyData.dates.dateTo > applicationDate;
    const spaceRow = `<tr><td colSpan="5" style={{ paddingBottom: '0' }}></td></tr>`;

    const datePart = `(${format(monthlyData.dates.dateFrom, DATE_FORMAT_STRING)} - ${format(
      monthlyData.dates.dateTo,
      DATE_FORMAT_STRING,
    )})`;

    let totalQuantityForSalesCumulative = 0;

    const tableParts = LINE_TYPES.map(type => {
      let tablePart = '';
      let lineTypeHasData = false;

      let totalQuantityForSales = 0;
      let totalValueForSalesCumulative = 0;

      const header = `<tr class="underline header"><td colspan="2">${type}s</td><td style="text-align: right">Qty</td>${
        isFuture ? '<td style="text-align: right">Cumulative</td>' : ''
      }${
        type === 'Sale'
          ? `<td style="text-align: right">${showAvgValue ? 'Avg. price' : 'Value'}</td>`
          : '<td></td>'
      }</tr>`;

      let noOfVisibleLines = 0;

      lines.forEach((line, i) => {
        const lineData = monthlyData.data[line.name];
        let lineRow = '';
        const info = line.lineInfo;

        if (line.type === type && lineData && info.hidden !== true) {
          //Quantity for given month - in case of "SaleUsedForForecast", we must take "QuantityToThisPriority"
          let quantityMonth = null;

          if (line.name === 'SaleUsedForForecast') {
            if (lineData.quantityToThisPriority !== null && lineData.quantityToThisPriority > 0) {
              quantityMonth = lineData.quantityToThisPriority;
            }
          } else {
            quantityMonth = lineData.quantity;
          }

          //we check if there is data for this month from this line type (so we wont show "forecast" in tooltips before calculation date and sales after calculation date if there are none)
          if (quantityMonth !== null) {
            lineTypeHasData = true;
          }

          //cumulative quantity, shown only in case of "future"
          const quantityCumulative = lineData.quantityCumulative;

          //avg value of sales - not shown in case of row "sales used for forecast", "dash" in case of Quantity = 0, value with currency otherwise
          let avgValue = '';

          if (line.name !== 'SaleUsedForForecast') {
            if (quantityMonth !== null && quantityMonth > 0) {
              avgValue = addVat(lineData.avgValue, vat).toString();
            } else {
              avgValue = '-';
            }
          }

          const value = lineData.value ?? 0;

          //for TOTAL sale quantity
          if (quantityMonth !== null && line.name !== 'SaleUsedForForecast' && type === 'Sale') {
            noOfVisibleLines++;
            totalQuantityForSales += quantityMonth;
            totalQuantityForSalesCumulative += lineData.quantityCumulative ?? 0;
            totalValueForSalesCumulative += value;
          }

          lineRow += `<tr>`;

          //DIV styled to look like line style used in graph
          let height;
          let border = '';
          let backgroundColor = '';

          if (info.fill !== false) {
            height = computeRemSize(12);
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            border = `border: ${computeRemSize((info.borderWidth ?? 1) as number)} ${
              info.borderDash && info.borderDash.length > 0 ? 'dashed' : 'solid'
            } ${info.borderColor?.toString() ?? 'black'}`;
            backgroundColor = `background-color: ${info.backgroundColor?.toString() ?? 'black'}`;
          } else {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            height = computeRemSize((info.borderWidth ?? 2) as number);
            if (info.borderDash && info.borderDash.length > 0) {
              border = `border-left: ${computeRemSize(8)} solid ${
                info.borderColor?.toString() ?? 'black'
              }; border-right: ${computeRemSize(8)} solid ${
                info.borderColor?.toString() ?? 'black'
              }; background-color: white;`;
            } else {
              backgroundColor = `background-color: ${info.borderColor?.toString() ?? 'black'};`;
            }
          }

          lineRow += `<td><div style="
              width: ${computeRemSize(20)};
              height: ${height};
              ${border};
              ${backgroundColor};">
              &nbsp;
            </div></td>`;

          lineRow += `<td class="header">${line.label}</td>`;
          lineRow += `<td style="text-align: right">${formatNumber(quantityMonth ?? 0)}</td>`;
          if (isFuture) {
            lineRow += `<td style="text-align: right">${formatNumber(
              quantityCumulative ?? 0,
            )}</td>`;
          }
          if (type === 'Sale' && line.name !== 'SaleUsedForForecast') {
            let cellContent;

            if (showAvgValue) {
              if (isNaN(+avgValue)) {
                cellContent = avgValue;
              } else {
                cellContent = formatNumber(avgValue, 2);
              }
            } else {
              cellContent = formatNumber(value);
            }

            const cell = `<td style="text-align: right">${cellContent} ${currency}</td>`;
            lineRow += cell;
          }
          lineRow += '</tr>';
        }

        if (lineRow.length > 0) {
          if (line.name === 'SaleUsedForForecast' && isFuture) {
            lineRow = '';
          }
        }
        const isLastLine =
          lines.find(tmpLine => tmpLine.name === 'SaleUsedForForecast') === undefined &&
          i + 1 === lines.length;
        if ((line.name === 'SaleUsedForForecast' && noOfVisibleLines > 0) || isLastLine) {
          let totalLine = `<tr class="overline"><td></td><td class="header">Total</td><td style="text-align: right">${formatNumber(
            totalQuantityForSales,
          )}</td>`;
          if (isFuture) {
            totalLine += `<td style="text-align: right">${totalQuantityForSalesCumulative}</td><td></td>`;
          } else {
            totalLine += `<td colspan="2" style="text-align: right">${
              showAvgValue ? '' : `${formatNumber(totalValueForSalesCumulative)} ${currency}`
            }</td>`;
          }
          totalLine += `</tr>`;
          if (line.name === 'SaleUsedForForecast') {
            lineRow = totalLine + lineRow;
          } else if (isLastLine) {
            lineRow += totalLine;
          }
        }

        if (lineRow.length > 0) {
          tablePart += lineRow;
        }
      });

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (lineTypeHasData) {
        tablePart = spaceRow + header + tablePart;
      } else {
        tablePart = '';
      }

      return tablePart;
    });

    const tableRoot = tooltipEl.querySelector('table');
    const titleRoot = tooltipEl.querySelector('div');
    if (titleRoot) {
      let title;
      if (applicationDate <= monthlyData.dates.dateFrom) {
        title = `OUTLOOK ${fullMonthDiff(applicationDate, monthlyData.dates.dateTo) + 1}`;
      } else {
        title = `${monthlyData.dates.dateTo.getFullYear()} / ${padLeft(
          String(monthlyData.dates.dateTo.getMonth() + 1),
          '0',
          2,
        )}`;
      }
      title += `<br /><span style="font-size: ${computeRemSize(
        10,
      )}; font-style: italic; color: darkgray;">${datePart}</span>`;
      titleRoot.innerHTML = title;
    }

    if (tableRoot) {
      tableRoot.innerHTML = tableParts.join('');
    }

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'fixed';
    tooltipEl.style.pointerEvents = 'none';
    if (chartRef.current !== null) {
      const chartPosition = chartRef.current.canvas.getBoundingClientRect();

      // set position of tooltip
      tooltipEl.style.top = computeRemSize(chartPosition.top + 40);
      if (tooltipModel.caretX > (chartPosition.width * 2) / 3 - chartPosition.width / 8) {
        tooltipEl.style.right = computeRemSize(
          document.body.clientWidth -
            chartPosition.right +
            (chartPosition.width - tooltipModel.caretX) +
            20,
        );
        tooltipEl.style.left = 'auto';
      } else {
        tooltipEl.style.left = computeRemSize(chartPosition.left + tooltipModel.caretX + 15);
        tooltipEl.style.right = 'auto';
      }
    }
    return monthlyData.dates.dateFrom;
  }
}
