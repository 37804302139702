import React, { useMemo } from 'react';
import { useGetSourceStoreTargetStoresCategoriesEvaluationRatesQuery } from './apiCalculationEvaluations';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import LoadingIndicator from '../../../components/global/LoadingIndicator/LoadingIndicator';
import {
  evaluationsRowClassName,
  EvaluationSummaryExtended,
  getBaseExecutionColumns,
} from './evaluationsLib';
import { CalculationStatisticClusterType } from '@ydistri/api-sdk';
import { useCurrency } from '../../../hooks/useCurrency';
import { ApplicationDataType } from '@ydistri/utils';
import { useApplicationData } from '../../../hooks/useApplicationData';
import { SimpleTable } from '../Statistics/components/SimpleTable';
import { computeRemSize } from '@ydistri/ds';
import { styled } from 'styled-components';
import { COLORS } from '../../../styles/style';

const Header = styled.b`
  margin-left: ${computeRemSize(48)};
`;
const TableWrapper = styled.div`
  margin-left: ${computeRemSize(48)};
  margin-right: ${computeRemSize(300)};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  border: 2px solid ${COLORS.BORDER};
`;

interface TargetStoreDetailTableProps {
  sourceStoreId: number;
}

const TargetStoreDetailTable: React.FC<TargetStoreDetailTableProps> = ({ sourceStoreId }) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const clusterType = useSelector((state: ReduxState) => state.evaluations.clusterType);
  const currencyId = useCurrency() ?? '...';
  const { [ApplicationDataType.STORE_CODE]: hasStoreCodes } = useApplicationData();

  const { data, isFetching: isFetchingStatistics } =
    useGetSourceStoreTargetStoresCategoriesEvaluationRatesQuery({
      calculationId: templateOrCalculation.id,
      clusterType,
      sourceStoreId,
    });

  const columns = useMemo(
    () =>
      getBaseExecutionColumns(
        currencyId,
        hasStoreCodes,
        clusterType === CalculationStatisticClusterType.StoreDepartment,
        false,
      ),
    [hasStoreCodes, clusterType, currencyId],
  );

  return (
    <>
      {isFetchingStatistics && <LoadingIndicator />}
      {data && (
        <>
          <Header>Target stores:</Header>
          <TableWrapper>
            <SimpleTable<EvaluationSummaryExtended>
              id={`target-store-evaluations-${sourceStoreId}`}
              dataSource={data}
              columns={columns}
              loading={isFetchingStatistics}
              showHeader={true}
              rowClassName={evaluationsRowClassName}
            />
          </TableWrapper>
        </>
      )}
    </>
  );
};

export default TargetStoreDetailTable;
