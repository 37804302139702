import {
  apiConfigurationTags,
  apiSlice,
  TemplateOrCalculation,
  wrongTemplateInHeaders,
} from '../../apis/api';
import {
  API,
  CalculationsCollection,
  CurrentSetupCollection,
  TemplatesCollection,
} from '../../swagger/collections';
import {
  ApiAction,
  ApiOperationType,
  CategoryFieldConfigurationResponse,
  ConfigurationFieldType,
  ConfigurationRequest,
  ConfigurationResponse,
  FieldConfigurationRequest,
  SkuType,
  TemplateRequest,
  TemplateResponse,
  TemplateUpdateRequest,
} from '@ydistri/api-sdk';
import { SignalConfigurationChanged } from '../../signalr/signalrInterfaces';
import { capitalizeFirstLetter } from '@ydistri/utils';
import { signalrClient } from '../../signalr/client';
import { apiApplication } from '../../apis/apiApplication';
import { getApiHeader } from '../../apis/getApiHeader';
import { getTags } from '../../apis/apiLib';
import { createDebugLog } from '../../lib/utils/logging';
import { store } from '../../store';
import { EntityListConfiguration } from './ConfigurationCategories/SegmentPotentialDefinition/ConfigurationEntityLists/entityListLib';

const debugLog = createDebugLog('apiTemplate');

const { TAGS, TAGS_ARRAY } = getTags('template');

export interface PatchCategoryConfigurationPayload {
  categoryId: number;
  payload: FieldConfigurationRequest[];
}

export interface PatchTemplatePayload {
  templateId: number;
  data: TemplateUpdateRequest;
}

export interface CreateTemplatePayload {
  basedOnTemplateId: number;
  data: TemplateRequest;
}

export const apiTemplate = apiSlice.enhanceEndpoints({ addTagTypes: TAGS_ARRAY }).injectEndpoints({
  endpoints: builder => ({
    patchCurrentSetup: builder.mutation<ConfigurationResponse, ConfigurationRequest>({
      queryFn: async configurationRequest => {
        const { data } = await CurrentSetupCollection.patchCurrentSetup(configurationRequest);

        return data;
      },
    }),

    patchCategoryConfiguration: builder.mutation<
      CategoryFieldConfigurationResponse[],
      PatchCategoryConfigurationPayload
    >({
      queryFn: async ({ categoryId, payload }) => {
        const { data } = await CurrentSetupCollection.patchCurrentCategoryConfiguration(
          categoryId,
          payload,
        );

        return data;
      },
    }),

    getRootCategoryConfiguration: builder.query<
      CategoryFieldConfigurationResponse[],
      TemplateOrCalculation
    >({
      queryFn: async arg => {
        if (wrongTemplateInHeaders(arg)) return { data: [] };
        debugLog('TEST - Calling getRootCategoryConfiguration', arg.id);
        const { data: rootConfigurationData } = await (arg.type === 'Template'
          ? CurrentSetupCollection.getCurrentCategoryConfigurations(1)
          : CalculationsCollection.getCalculationCategoryConfigurations(arg.id, 1));

        return { data: rootConfigurationData.data };
      },
      providesTags: (result, error, arg) => [
        { type: TAGS.categoryConfiguration, id: `${arg.type}-${arg.id}` },
      ],
      async onCacheEntryAdded(
        // eslint-disable-next-line no-unused-vars
        { id },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch },
      ) {
        try {
          await cacheDataLoaded;

          const listener = (signal: SignalConfigurationChanged) => {
            if (store.getState().userReducer.selectedTemplateId !== signal.templateId) {
              // we don't want to update data, when signal is not for selected template in the STORE
              return;
            } else if (
              // When Loading configuration into template, we reset all cached data for this template
              signal.apiAction === ApiAction.LoadConfigurationsFromCalculation &&
              signal.operationType === ApiOperationType.Replace
            ) {
              debugLog('Load into template...');
              dispatch(apiSlice.util.invalidateTags(apiConfigurationTags));
            } else if (signal.entityType === 'Category') {
              updateCachedData(draft => {
                const { updates, entityId: categoryId, operationType } = signal;
                console.log('SIGNAL [in RootCategoryConfiguration]', signal);
                if (categoryId !== 1) return;
                if (operationType === ApiOperationType.Patch) {
                  updates.forEach(u => {
                    if (u.value !== undefined) {
                      const split = u.fieldName.split('|').map(x => capitalizeFirstLetter(x));
                      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                      const fieldType = split[0] as ConfigurationFieldType;
                      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                      const skuType = (split[1] === 'Null' ? null : split[1]) as SkuType;

                      const foundException = draft.find(
                        e => e.fieldType === fieldType && e.skuType === skuType,
                      );
                      if (foundException) {
                        foundException.value = u.value;
                        console.log({ foundException });
                        console.log('exception found, setting to ', u.value);
                      } else {
                        console.log('Exception not found', draft);
                      }
                    } else {
                      throw Error(`No value in update!`);
                    }
                  });
                }
              });
            } else if (signal.entityType === 'EntityListConfiguration') {
              const { entityId: entityListId } = signal;
              console.log('SIGNAL EntityListConfiguration', signal);
              if (signal.operationType === ApiOperationType.Create) return;
              if (signal.operationType === ApiOperationType.Delete) {
                updateCachedData(draft => {
                  draft.forEach(conf => {
                    signal.updates.forEach(u => {
                      if (conf.fieldType.toString() === capitalizeFirstLetter(u.fieldName)) {
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        const oldConfigs = JSON.parse(conf.value) as EntityListConfiguration[];
                        if (oldConfigs.length > 0) {
                          const newConfigs = oldConfigs.filter(
                            x => x.EntityListId !== entityListId,
                          );
                          if (newConfigs.length !== oldConfigs.length) {
                            conf.value = JSON.stringify(newConfigs);
                          }
                        }
                      }
                    });
                  });
                });
              }
            }
          };

          debugLog('TEST - [signalrClient.on] TemplateId: ', id);
          signalrClient.on('TemplateConfigurationChanged', listener);

          cacheEntryRemoved.then(() => {
            debugLog('[signalrClient.off] Cache entry removed! TemplateId: ', id);
            signalrClient.off('TemplateConfigurationChanged', listener);
          });
        } catch {
          // no-op in case cache entry was removed before data was loaded
        }
        await cacheEntryRemoved;
      },
    }),
    getTemplate: builder.query<TemplateResponse | undefined, number | undefined>({
      queryFn: async templateId => {
        if (templateId) {
          const response = await TemplatesCollection.getTemplate(templateId);
          return { data: response.data.data };
        } else {
          return { data: undefined };
        }
      },
      providesTags: result => {
        if (!result) return [];
        return [{ type: TAGS.template, id: result.id }];
      },
    }),
    patchTemplate: builder.mutation<TemplateResponse, PatchTemplatePayload>({
      queryFn: async ({ templateId, data }) => {
        const response = await TemplatesCollection.patchTemplate(templateId, data);
        return { data: response.data.data };
      },
      onQueryStarted(payload, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiApplication.util.updateQueryData(
            'getTemplates',
            getApiHeader(API, 'Project-Code') ?? '',
            draft => {
              const existingTemplate = draft.find(template => template.id === payload.templateId);
              if (existingTemplate) {
                Object.assign(existingTemplate, payload.data);
              }
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),

    createTemplate: builder.mutation<TemplateResponse, CreateTemplatePayload>({
      queryFn: async ({ basedOnTemplateId, data }) => {
        const response = await TemplatesCollection.postTemplate(data, {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Template-Id': basedOnTemplateId,
          },
        });

        return { data: response.data.data };
      },
      onQueryStarted(payload, { dispatch, queryFulfilled }) {
        queryFulfilled.then(data => {
          dispatch(
            apiApplication.util.updateQueryData(
              'getTemplates',
              getApiHeader(API, 'Project-Code') ?? '',
              draft => [...draft, data.data],
            ),
          );
        });
      },
    }),

    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    deleteTemplate: builder.mutation<void, number>({
      queryFn: async templateId => {
        const response = await TemplatesCollection.deleteTemplate(templateId);
        return { data: response.data };
      },
      onQueryStarted(templateId, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            apiApplication.util.updateQueryData(
              'getTemplates',
              getApiHeader(API, 'Project-Code') ?? '',
              draft => [...draft.filter(template => template.id !== templateId)],
            ),
          );
        });
      },
    }),
  }),
});

export const {
  usePatchCurrentSetupMutation,
  usePatchCategoryConfigurationMutation,
  useGetRootCategoryConfigurationQuery,
  useGetTemplateQuery,
  usePatchTemplateMutation,
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
} = apiTemplate;
