import React, { Fragment } from 'react';
import { Divider, SegmentedProps } from 'antd';
import { menuLeftItemTemplate } from './menuLeftItemTemplate';
import { generatePartialPath, RoutingPage } from '../../routes/routerSlice';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import { SubmenuOption } from '../../screens/Configuration/ConfigurationHeader';
import MenuSubpageButton from './MenuSubpageButton';
import { computeRemSize } from '@ydistri/ds';

interface MenuSubpagesProps {
  routingPage: RoutingPage;
  submenuOptions: SubmenuOption[];
  selectedOption?: string;
  showInSection?: boolean;
  showFullWidth?: boolean;
  size?: SegmentedProps['size'];
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${computeRemSize(8)};
`;

const OptionDivider = styled(Divider)`
  align-self: center;
  height: 2rem;
`;

const MenuSubpages: React.FC<MenuSubpagesProps> = ({
  routingPage,
  submenuOptions,
  selectedOption,
}) => {
  const params = useParams();

  return (
    <ButtonWrapper data-type="menu-subpages">
      {submenuOptions.map(option => {
        const menuItem = menuLeftItemTemplate.find(item => item.id === routingPage);
        if (!menuItem?.childRoute) return null;
        if (option.isVisible === false) return null;

        const partialPath = generatePartialPath(menuItem.childRoute, params, option.value);
        return (
          <Fragment key={option.value}>
            <MenuSubpageButton
              partialPath={partialPath}
              selected={selectedOption === option.value}
              submenuOption={option}
              data-testid={option.value}
            />
            {option.appendDivider && <OptionDivider type="vertical" />}
          </Fragment>
        );
      })}
    </ButtonWrapper>
  );
};

export default MenuSubpages;
