import React, { useMemo } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useCurrency } from '../../../hooks/useCurrency';
import { capitalizeFirstLetter, formatNumber, minimalToMinimum } from '@ydistri/utils';
import StatisticsPackagesIcon from '../../../../assets/statistics-packages.svg';
import { SimpleTableDivider, SimpleTable } from './components/SimpleTable';
import StatisticsSection from './components/StatisticsSection';
import { sortByStatisticsType, StatisticsModalType } from './statisticsLib';
import TitleWithModalButton from './components/TitleWithModalButton';
import { computeRemSize } from '@ydistri/ds';
import { useGetStatsRouteSummariesQuery, useGetStatisticsQuery } from './apiCalculationStatistics';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';

type RouteBaseStatistics =
  | {
      title: string;
      key: string;
      value: string | number;
    }
  | SimpleTableDivider;

type RouteMainStatistics =
  | {
      title: string;
      key: string;
      value: number;
      quantity: number;
      productCount: number;
    }
  | SimpleTableDivider;

const routeBaseStatisticsColumns: ColumnsType<RouteBaseStatistics> = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: computeRemSize(300),
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    width: computeRemSize(70),
    align: 'right',
  },
];

const getRouteMainStatisticsColumns = (currency: string): ColumnsType<RouteMainStatistics> => [
  {
    title: '',
    dataIndex: 'title',
    key: 'title',
    width: computeRemSize(130),
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    width: computeRemSize(130),
    align: 'right',
    render: (value: number) => `${formatNumber(value)} ${currency}`,
    sorter: (a, b) => ('value' in a && 'value' in b ? a.value - b.value : 0),
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    width: computeRemSize(130),
    align: 'right',
    render: (quantity: number) => `${formatNumber(quantity)} MU`,
    sorter: (a, b) => ('quantity' in a && 'quantity' in b ? a.quantity - b.quantity : 0),
  },
  {
    title: 'Product count',
    dataIndex: 'productCount',
    key: 'productCount',
    width: computeRemSize(130),
    align: 'right',
    sorter: (a, b) =>
      'productCount' in a && 'productCount' in b ? a.productCount - b.productCount : 0,
  },
];

const SectionRoutes: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const currencyId = useCurrency() ?? '...';
  const departmentId = useSelector((state: ReduxState) => state.statistics.departmentId);
  const clusterType = useSelector((state: ReduxState) => state.statistics.clusterType);

  const { data, isLoading } = useGetStatsRouteSummariesQuery({
    calculationId: templateOrCalculation.id,
    departmentId,
    clusterType,
  });

  const { data: overallStatisticsData, isFetching: isFetchingStatistics } = useGetStatisticsQuery({
    calculationId: templateOrCalculation.id,
    departmentId,
    clusterType,
  });

  const routeMainStatisticsColumns: ColumnsType<RouteMainStatistics> = useMemo(
    () => getRouteMainStatisticsColumns(currencyId),
    [currencyId],
  );

  const baseTableData: RouteBaseStatistics[] = useMemo(
    () =>
      overallStatisticsData
        ? [
            {
              title: 'Total number of routes',
              key: 'totalRoutes',
              value: formatNumber(overallStatisticsData.routesCount),
            },
            {
              divider: true,
              key: 'divider1',
            },
            {
              title: 'Avg. no. of outgoing routes',
              key: 'avgOutgoingRoutes',
              value: formatNumber(overallStatisticsData.avgOutgoingRoutes),
            },
            {
              title: 'Avg. no. of incoming routes',
              key: 'avgIncomingRoutes',
              value: formatNumber(overallStatisticsData.avgIncomingRoutes),
            },
          ]
        : [],
    [overallStatisticsData],
  );

  const mainTableData: RouteMainStatistics[] = useMemo(
    () =>
      [...(data ?? [])].sort(sortByStatisticsType).map(item => ({
        title: `${capitalizeFirstLetter(minimalToMinimum(item.type))} route`,
        key: `${item.type}Route`,
        value: item.value,
        quantity: item.quantity,
        productCount: item.productCount,
      })),
    [data],
  );

  return (
    <StatisticsSection icon={StatisticsPackagesIcon}>
      <TitleWithModalButton title="Routes" modalType={StatisticsModalType.ROUTE} />
      <SimpleTable<RouteBaseStatistics>
        id="route-base-statistics"
        rowKey="key"
        dataSource={baseTableData}
        columns={routeBaseStatisticsColumns}
        loading={isFetchingStatistics}
      />
      <SimpleTable<RouteMainStatistics>
        id="route-main-statistics"
        rowKey="key"
        dataSource={mainTableData}
        columns={routeMainStatisticsColumns}
        loading={isLoading}
        showHeader={true}
      />
    </StatisticsSection>
  );
};

export default SectionRoutes;
