import {
  ApiOperationType,
  CalculationResponse,
  Operation,
  SortDirection,
  TargetListCreateRequest,
  TargetListItemResponse,
  TargetListItemsIdentificationResponse,
  TargetListResponse,
} from '@ydistri/api-sdk';
import { apiSlice, ErrorType } from '../../../apis/api';
import { TargetListsCollections } from '../../../swagger/collections';
import { signalrClient } from '../../../signalr/client';
import { refreshTargetListContent, setSelectedTargetList } from './targetListAdministrationSlice';
import { ReduxState } from '../../../store';
import { addToast } from '../../../store/toastSlice';
import { SignalProjectConfigurationChanged } from '../common/administrationItemsTypes';
import {
  forceRefetchForInfiniteScroll,
  InfiniteScrollParams,
  mergeForInfiniteScroll,
  serializeQueryArgsForInfiniteScroll,
} from '@ydistri/ds';
import { getTags } from '../../../apis/apiLib';
import { SignalConfigurationChanged } from '../../../signalr/signalrInterfaces';

const { TAGS, TAGS_ARRAY } = getTags('targetLists');

export type TargetListSetArchiveRequest = Pick<TargetListResponse, 'targetListId' | 'isArchived'>;
export type TargetListSetConfigurationTypeRequest = Pick<
  TargetListResponse,
  'targetListId' | 'targetListVersionConfigurationTypeId'
>;
export type TargetListUpdateRequest = Pick<TargetListResponse, 'targetListId'> &
  Partial<Pick<TargetListResponse, 'name' | 'targetListVersionConfigurationTypeId'>>;

export interface TargetListSkusRequest extends InfiniteScrollParams {
  targetListId: number;
}

export const apiTargetLists = apiSlice
  .enhanceEndpoints({
    addTagTypes: TAGS_ARRAY,
  })
  .injectEndpoints({
    endpoints: builder => ({
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      getTargetLists: builder.query<TargetListResponse[], void>({
        queryFn: async () => {
          const data = await TargetListsCollections.targetListsList({
            sortings: [{ fieldName: 'Name' }],
          });
          return data.data;
        },
        providesTags: [TAGS.targetListResponse],
        async onCacheEntryAdded(
          arg,
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState, dispatch },
        ) {
          const signalerListener = (signal: SignalProjectConfigurationChanged) => {
            if (signal.entityType !== 'TargetList') return;

            dispatch(apiTargetLists.util.invalidateTags([TAGS.targetListResponse]));

            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const state = getState() as ReduxState;

            //if the currently selected target list was deleted,
            //we need to clear the selected target list
            const selectedTargetList = state.targetListAdministration.selectedTargetList;
            if (selectedTargetList && signal.entityId === selectedTargetList.targetListId) {
              if (signal.operationType === ApiOperationType.Delete) {
                dispatch(setSelectedTargetList(undefined));
                dispatch(
                  addToast({
                    message: 'Target list has been deleted',
                  }),
                );
              }
            }

            if (signal.operationType === ApiOperationType.Patch) {
              dispatch(refreshTargetListContent());
            }
          };

          /**
           * React to changes in the template configuration when target list is set to or removed from a template.
           * Based on this change the target list might be marked as archivable or not and list of templates
           * where it is used will change.
           * The listener fetches the target list from the server and updates the cache.
           * @param signal
           */
          const templateConfigurationListener = (signal: SignalConfigurationChanged) => {
            if (signal.entityType === 'TargetListConfiguration') {
              const targetListId = signal.entityId;
              TargetListsCollections.targetListsList({
                conditions: [
                  {
                    fieldName: 'TargetListId',
                    operation: Operation.Eq,
                    value: targetListId,
                  },
                ],
              })
                .then(response => {
                  try {
                    const tmpTargetList = response.data.data[0];
                    updateCachedData(data => {
                      const targetListIndex = data.findIndex(
                        tl => tl.targetListId === tmpTargetList.targetListId,
                      );
                      if (targetListIndex !== -1) {
                        data[targetListIndex] = tmpTargetList;
                        return data;
                      }
                    });
                  } catch (error) {
                    dispatch(
                      addToast({
                        message: `Failed to update the Target List, refresh the page.`,
                        isError: true,
                      }),
                    );
                  }
                })
                .catch((error: ErrorType) => {
                  dispatch(
                    addToast({
                      message: `Failed to load Target List: ${error.response.data.Messages.join(
                        ', ',
                      )}`,
                      isError: true,
                    }),
                  );
                });
            }
          };

          try {
            await cacheDataLoaded;
            signalrClient.on('ProjectConfigurationChanged', signalerListener);
            signalrClient.on('TemplateConfigurationChanged', templateConfigurationListener);

            cacheEntryRemoved.then(() => {
              signalrClient.off('ProjectConfigurationChanged', signalerListener);
              signalrClient.off('TemplateConfigurationChanged', templateConfigurationListener);
            });
            await cacheEntryRemoved;
          } catch (error) {
            console.error(error);
          }
        },
      }),
      createTargetList: builder.mutation<TargetListResponse, TargetListCreateRequest>({
        queryFn: async payload => {
          try {
            const response = await TargetListsCollections.createTargetList({
              name: payload.name,
              targetListVersionConfigurationTypeId: payload.targetListVersionConfigurationTypeId,
            });
            const newTargetList = response.data.data;

            return { data: newTargetList };
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const err = error as ErrorType;
            return { error: err };
          }
        },
        invalidatesTags: [TAGS.targetListResponse],
      }),
      deleteTargetList: builder.mutation<number, number>({
        queryFn: async (targetListId: number) => {
          await TargetListsCollections.deleteTargetList(targetListId);
          return { data: targetListId };
        },
        invalidatesTags: [TAGS.targetListResponse],
      }),
      setArchived: builder.mutation<TargetListResponse, TargetListSetArchiveRequest>({
        queryFn: async (request: TargetListSetArchiveRequest) => {
          const response = await TargetListsCollections.updateTargetList(request.targetListId, {
            isArchived: request.isArchived,
          });
          return { data: response.data.data };
        },
        invalidatesTags: [TAGS.targetListResponse],
      }),
      updateTargetList: builder.mutation<TargetListResponse | undefined, TargetListUpdateRequest>({
        queryFn: async (request: TargetListUpdateRequest) => {
          let result: TargetListResponse | undefined = undefined;

          if (request.name) {
            const response = await TargetListsCollections.updateTargetList(request.targetListId, {
              name: request.name,
            });
            result = response.data.data;
          }

          if (request.targetListVersionConfigurationTypeId) {
            const responseConfigurationType = await TargetListsCollections.patchTargetListItems(
              request.targetListId,
              {
                targetIdentifications: [],
                targetListVersionConfigurationTypeId: request.targetListVersionConfigurationTypeId,
              },
            );

            if (result) {
              result.targetListVersionConfigurationTypeId =
                responseConfigurationType.data.data.targetListVersionConfigurationTypeId;
            } else {
              const targetListsResponse = await TargetListsCollections.targetListsList({
                conditions: [
                  {
                    fieldName: 'TargetListId',
                    operation: Operation.Eq,
                    value: request.targetListId,
                  },
                ],
              });
              if (targetListsResponse.data.data.length === 1) {
                result = targetListsResponse.data.data[0];
              }
            }
          }

          return { data: result };
        },
        invalidatesTags: [TAGS.targetListResponse],
      }),
      setConfigurationType: builder.mutation<
        TargetListItemsIdentificationResponse,
        TargetListSetConfigurationTypeRequest
      >({
        queryFn: async (request: TargetListSetConfigurationTypeRequest) => {
          const response = await TargetListsCollections.patchTargetListItems(request.targetListId, {
            targetIdentifications: [],
            targetListVersionConfigurationTypeId: request.targetListVersionConfigurationTypeId,
          });

          return { data: response.data.data };
        },
        invalidatesTags: [TAGS.targetListResponse],
      }),
      getTargetListSkus: builder.query<TargetListItemResponse[], TargetListSkusRequest>({
        queryFn: async args => {
          const query = {
            top: args.top,
            skip: args.skip,
            inlineCount: true,
            sortings: args.sortings,
            conditions: args.conditions,
            search: args.search,
          };
          const response = await TargetListsCollections.getSkus(args.targetListId, query);
          return { data: response.data.data };
        },
        providesTags: [TAGS.targetListSkuResponse],
        serializeQueryArgs: serializeQueryArgsForInfiniteScroll<TargetListSkusRequest>(),
        merge: mergeForInfiniteScroll<TargetListItemResponse, InfiniteScrollParams>(),
        forceRefetch: forceRefetchForInfiniteScroll<InfiniteScrollParams | undefined>(),
      }),
      getTargetListCalculations: builder.query<CalculationResponse[], number>({
        queryFn: async targetListId => {
          const response = await TargetListsCollections.getTargetListCalculations(targetListId, {
            sortings: [
              {
                fieldName: 'Title',
                direction: SortDirection.Asc,
              },
            ],
          });

          return { data: response.data.data };
        },
        providesTags: [TAGS.targetListCalculations],
      }),
    }),
  });

export const {
  useGetTargetListsQuery,
  useCreateTargetListMutation,
  useDeleteTargetListMutation,
  useSetArchivedMutation,
  useSetConfigurationTypeMutation,
  useUpdateTargetListMutation,
  useGetTargetListSkusQuery,
  useGetTargetListCalculationsQuery,
} = apiTargetLists;
