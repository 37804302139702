import React, { useCallback, useState } from 'react';
import { Column, Row, ActionsBar } from '@ydistri/ds';
import IdentificationTypeComponent from '../../../common/IdentificationTypeComponent';
import TargetListQuantityComponent from '../../components/TargetListQuantityComponent';
import {
  TargetListSetConfigurationTypeRequest,
  useSetConfigurationTypeMutation,
} from '../../apiTargetLists';
import { batch, useDispatch, useSelector } from 'react-redux';
import { IdentificationType, TargetListVersionConfigurationType } from '@ydistri/api-sdk';
import {
  resetTargetListItems,
  setHandlingMethod,
  setIdentificationType,
  setSelectedTargetListConfigurationType,
  setWarehouseIdentificationType,
} from '../../targetListAdministrationSlice';
import { addToast } from '../../../../../store/toastSlice';

import { Button } from 'antd';
import { AdministrationItemListHandlingMethod } from '../../../common/administrationItemsTypes';
import LoadingIndicator from '../../../../../components/global/LoadingIndicator/LoadingIndicator';
import TargetListItemsTextArea from './TargetListItemsTextArea';
import useValidateItems from '../../useValidateItems';
import { ReduxState } from '../../../../../store';

const TargetListEditor: React.FC = () => {
  const [isBusy, setIsBusy] = useState(false);

  const warehouseIdentificationType = useSelector(
    (state: ReduxState) => state.targetListAdministration.warehouseIdentificationType,
  );
  const productIdentificationType = useSelector(
    (state: ReduxState) => state.targetListAdministration.productIdentificationType,
  );
  const isLoading = useSelector((state: ReduxState) => state.targetListAdministration.isLoading);
  const productItemsContent = useSelector(
    (state: ReduxState) => state.targetListAdministration.productItemsContent,
  );
  const selectedTargetList = useSelector(
    (state: ReduxState) => state.targetListAdministration.selectedTargetList,
  );

  const [setConfigurationType] = useSetConfigurationTypeMutation();
  const dispatch = useDispatch();

  const validateItems = useValidateItems();

  const selectWarehouseIdentificationHandler = useCallback(
    (newValue: IdentificationType) => {
      batch(() => {
        dispatch(setWarehouseIdentificationType(newValue));
        dispatch(resetTargetListItems());
        dispatch(setHandlingMethod(undefined));
      });
    },
    [dispatch],
  );
  const selectProductIdentificationHandler = useCallback(
    (newValue: IdentificationType) => {
      batch(() => {
        dispatch(setIdentificationType(newValue));
        dispatch(resetTargetListItems());
        dispatch(setHandlingMethod(undefined));
      });
    },
    [dispatch],
  );

  const selectConfigurationType = useCallback(
    (newValue: TargetListVersionConfigurationType) => {
      if (!selectedTargetList) {
        return null;
      }

      if (!isBusy) {
        setIsBusy(true);
        const request: TargetListSetConfigurationTypeRequest = {
          targetListId: selectedTargetList.targetListId,
          targetListVersionConfigurationTypeId: newValue,
        };

        setConfigurationType(request)
          .unwrap()
          .then(() => {
            dispatch(setSelectedTargetListConfigurationType(newValue));
          })
          .catch((error: Error) => {
            dispatch(
              addToast({
                isError: true,
                message: `Target List's configuration type was not changed ${error.message}`,
              }),
            );
          })
          .finally(() => {
            setIsBusy(false);
          });
      }
    },
    [dispatch, isBusy, selectedTargetList, setConfigurationType],
  );

  const addItems = useCallback(() => {
    validateItems(AdministrationItemListHandlingMethod.ADD);
  }, [validateItems]);
  const replaceItems = useCallback(() => {
    validateItems(AdministrationItemListHandlingMethod.REPLACE);
  }, [validateItems]);

  if (!selectedTargetList) {
    return null;
  }

  if (isLoading) {
    return (
      <Column $gap="0.5rem" $minWidth="45rem">
        <LoadingIndicator text="Validating..." />
      </Column>
    );
  } else {
    return (
      <Column $gap="1rem">
        <Row>
          <IdentificationTypeComponent
            type="Store"
            value={warehouseIdentificationType}
            onChange={selectWarehouseIdentificationHandler}
            id="WarehouseIdentificationTypeSelect"
          />
        </Row>
        <Row>
          <IdentificationTypeComponent
            type="Product"
            value={productIdentificationType}
            onChange={selectProductIdentificationHandler}
            id="ProductIdentificationTypeSelect"
          />
        </Row>
        <Row>
          <TargetListQuantityComponent
            onChange={selectConfigurationType}
            value={selectedTargetList.targetListVersionConfigurationTypeId}
            id="TargetListQuantitySelect"
          />
        </Row>
        <Row $gap="1rem">
          <Column $gap="0.5rem" $minWidth="45rem">
            <TargetListItemsTextArea label="Items" />
            <ActionsBar data-testid="TargetListEditorActions">
              <Button
                disabled={productItemsContent.length === 0}
                data-testid="add-to-target-list"
                type="primary"
                onClick={addItems}
              >
                Add to Target List...
              </Button>
              <Button
                disabled={productItemsContent.length === 0}
                data-testid="replace-target-list"
                type="primary"
                onClick={replaceItems}
              >
                Replace Target List...
              </Button>
            </ActionsBar>
          </Column>
        </Row>
      </Column>
    );
  }
};

export default TargetListEditor;
