import { Section, ReadOnlyItem, Row, Text } from '@ydistri/ds';
import React, { useCallback, useMemo } from 'react';
import { ProductResponse } from '@ydistri/api-sdk';
import CalculationRedistributionSectionHeader from './CalculationRedistributionSectionHeader';
import { useGetCalculationSkusQuery } from '../../Calculations/apiCalculationsSkus';
import { extractCategoryTitles, trimArray } from './redistributionLib';
import { Divider, Popover, Space, Spin } from 'antd';
import { useApplicationData } from '../../../hooks/useApplicationData';
import { isSameId } from '../../../lib/utils/utils';

interface CategoryListProps {
  categories: string[];
  maxCategories?: number;
}

/**
 * Display a list of categories separated by a vertical divider. If the list is longer than the maxCategories prop, the
 * list will be trimmed and a tooltip will be displayed with the full list.
 * @param categories
 * @param maxCategories Display only given number of categories. Component will display the first and last maxCategories - 1
 * @constructor
 */
const CategoryList: React.FC<CategoryListProps> = ({ categories, maxCategories = 4 }) => {
  const trimCategories = categories.length > maxCategories;

  const body = useCallback((items: string[]) => {
    return (
      <Space split={splitter}>
        {items.map((categoryName, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Text key={`${categoryName}-${i}`}>{categoryName}</Text>
        ))}
      </Space>
    );
  }, []);

  if (trimCategories) {
    const tmpCategoryNames = trimArray(categories, maxCategories, '...');
    const tooltipContent = categories.join(' / ');
    return (
      <Popover content={tooltipContent} placement="bottomLeft" trigger="hover">
        {body(tmpCategoryNames)}
      </Popover>
    );
  } else {
    return body(categories);
  }
};

interface CalculationRedistributionProductSummaryProps {
  product: ProductResponse;
  skuId: number;
  calculationId: number;
}

const splitter = <Divider type="vertical" />;

const CalculationRedistributionProductSummary: React.FC<
  CalculationRedistributionProductSummaryProps
> = ({ product, skuId, calculationId }) => {
  const { productCode } = useApplicationData();
  const { data: skuConfiguration, isFetching } = useGetCalculationSkusQuery({
    skuId: skuId,
    calculationId: calculationId,
  });
  const categoryNames = useMemo(() => {
    if (skuConfiguration) {
      return extractCategoryTitles(skuConfiguration.categories);
    } else {
      return [];
    }
  }, [skuConfiguration]);

  const productIdComponent = useMemo(() => {
    const data = [{ label: 'ID', value: product.customerId }];

    if (!isSameId(product.id, product.customerId)) {
      data.push({ label: 'System ID', value: product.id.toString() });
    }

    return data.map(item => (
      <ReadOnlyItem label={item.label} value={item.value ?? ''} key={item.value} />
    ));
  }, [product]);

  /* Display only if the project uses product codes */
  const productCodeComponent = useMemo(() => {
    if (productCode) {
      return <ReadOnlyItem label="Code" value={product.code} />;
    }
  }, [product.code, productCode]);

  const brandComponent = useMemo(
    () => <ReadOnlyItem label="Brand" value={product.brand?.name} />,
    [product],
  );

  const categoryComponent = useMemo(() => {
    return (
      <ReadOnlyItem label="Category">
        {isFetching ? (
          <Spin size="small" />
        ) : (
          <CategoryList categories={categoryNames} maxCategories={3} />
        )}
      </ReadOnlyItem>
    );
  }, [categoryNames, isFetching]);

  const header = useMemo(() => {
    return (
      <Row $gap="1rem" $alignItems="center">
        <CalculationRedistributionSectionHeader content={product.name} />
        <Space split={splitter}>
          {productIdComponent}
          {productCodeComponent}
          {brandComponent}
          {categoryComponent}
        </Space>
      </Row>
    );
  }, [brandComponent, categoryComponent, product.name, productCodeComponent, productIdComponent]);

  return <Section header={header}></Section>;
};

export default CalculationRedistributionProductSummary;
