import React, { CSSProperties, useMemo } from 'react';

import { Column } from '@ydistri/ds';
import { styled } from 'styled-components';
import { TooltipContentSection, TooltipPosition } from './detailSaleChartTooltipTypes';
import { Units } from '../../../../../lib/charts/saleChartsTypes';
import TooltipTitle from './TooltipTitle';
import TooltipContent from './TooltipContent';

const SalesChartTooltipWrapper = styled.div`
  min-width: 22rem;
  transition: 0.5s all cubic-bezier(0.17, 1.09, 0.78, 0.87);
  background-color: white;
  box-shadow: 0 calc(2rem / ${({ theme }) => theme.rootFontSize})
    calc(50rem / ${({ theme }) => theme.rootFontSize}) #888;
  padding: 0.8rem;
  text-align: center;
  font-size: calc(12rem / ${({ theme }) => theme.rootFontSize});
  position: fixed;
  pointer-events: none;
  z-index: 999;
`;

export interface SalesChartTooltipProps {
  unit: Units;
  position: TooltipPosition;
  data: TooltipContentSection[];
  currentDate: Date;
  dateFrom: Date;
  dateTo: Date;
}

/**
 * Tooltip renders the tooltip for the sales chart. It does not make any choices
 * just renders the data it receives on a position it receives.
 * The data is a table with given headers and data. Table data are rows in table
 * with label, line information and primary and secondary value.
 * It is up to the caller to decide what data should go to each of the columns.
 * @param position Where to render the tooltip if at all (visible property)
 * @param data data to render as table
 * @param currentDate Date in the application
 * @param dateFrom
 * @param dateTo
 * @constructor
 */
const DetailSalesChartTooltip: React.FC<SalesChartTooltipProps> = ({
  position,
  data,
  currentDate,
  dateFrom,
  dateTo,
}) => {
  const tooltipStyle = useMemo((): CSSProperties => {
    return {
      opacity: position.visible ? '1' : '0',
      top: position.top,
      left: position.left,
      right: position.right,
    };
  }, [position]);

  return (
    <SalesChartTooltipWrapper style={tooltipStyle} data-type="SalesChartTooltipWrapper">
      <Column $gap="0.75rem">
        <TooltipTitle currentDate={currentDate} dateFrom={dateFrom} dateTo={dateTo}></TooltipTitle>
        {data.map(dataItem => {
          return (
            <TooltipContent
              key={dataItem.columnNames[0]}
              columnNames={dataItem.columnNames}
              data={dataItem.data}
            />
          );
        })}
      </Column>
    </SalesChartTooltipWrapper>
  );
};

export default DetailSalesChartTooltip;
