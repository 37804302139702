import React, { CSSProperties } from 'react';
import { PairingValueText, StyledPairingValueText } from './PairingValueText';
import { COLORS, Column } from '@ydistri/ds';
import { styled } from 'styled-components';
import CalculationTableText from './CalculationTableText';

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 15rem;
`;

interface ProductTypeValueProps {
  type: string;
  value: number;
  color: CSSProperties['color'];
  currency?: string;
  valueDisplay?: 'short' | 'full';
}

/**
 * Unifies the look of the SKU type and its value in the tooltip.
 * @param type SKU type used for label (Dead stock, Slow mover...)
 * @param value
 * @param color
 * @param currency
 * @constructor
 */
const SKUTypeValue: React.FC<ProductTypeValueProps> = ({
  type,
  value,
  color,
  currency = '...',
}) => {
  return (
    <RowWrapper>
      <StyledPairingValueText $color={color}>
        <CalculationTableText>{type}</CalculationTableText>
      </StyledPairingValueText>
      <PairingValueText value={value} color={color} currency={currency} valueDisplay="full" />
    </RowWrapper>
  );
};

interface CalculationFinalRedistributionTooltipContentProps {
  totalValue: number;
  deadStockValue: number;
  slowMoverValue: number;
  fastMoverValue: number;
  currency?: string;
}

/**
 * Displays calculation's final redistribution values in full form in a tooltip.
 * All four values are displayed at once to save number of tooltips and also
 * improve UX because the user does not have to move mouse over four values
 * to learn the details.
 * @param totalValue
 * @param deadStockValue
 * @param slowMoverValue
 * @param fastMoverValue
 * @param currency
 * @constructor
 */
const CalculationFinalRedistributionTooltipContent: React.FC<
  CalculationFinalRedistributionTooltipContentProps
> = ({ totalValue, deadStockValue, slowMoverValue, fastMoverValue, currency = '...' }) => {
  return (
    <Column $gap="0.5rem">
      <SKUTypeValue
        type="Dead stock"
        value={deadStockValue}
        color={COLORS.DEADSTOCK_COLOR}
        currency={currency}
      />
      <SKUTypeValue
        type="Slow mover"
        value={slowMoverValue}
        color={COLORS.SLOWMOVER_COLOR}
        currency={currency}
      />
      <SKUTypeValue
        type="Fast mover"
        value={fastMoverValue}
        color={COLORS.FASTMOVER_COLOR}
        currency={currency}
      />
      <SKUTypeValue
        type="Total"
        value={totalValue}
        color={COLORS.ALL_SKU_TYPES_COLOR}
        currency={currency}
      />
    </Column>
  );
};

export default CalculationFinalRedistributionTooltipContent;
