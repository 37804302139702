import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
import { resetFilters } from '../../../Calculations/calculationsSlice';
import { useIsRedistributionFilterSet } from './useRedistributionFilters';
import { styled } from 'styled-components';
import { Button } from '@ydistri/ds';

const RightAlignedButton = styled(Button)`
  margin-left: auto;
`;

/**
 * Resets all filters for the calculation's redistribution.
 * <p>When no filters are set, the button is disabled.</p>
 * <p>When a new filter is introduced, it's reset method should be added
 * to the resetFilters function in the calculationsSlice.</p>
 */
const ResetFiltersButton: React.FC = () => {
  const dispatch = useDispatch();
  const isFilterSet = useIsRedistributionFilterSet();

  const resetFiltersHandler = useCallback(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  return (
    <RightAlignedButton
      onClick={resetFiltersHandler}
      disabled={!isFilterSet}
      $colorVariant="ghostPrimary"
    >
      Reset filters
    </RightAlignedButton>
  );
};

export default ResetFiltersButton;
