import React, { useCallback } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Column, Label } from '@ydistri/ds';
import { SimpleTable } from '../../../Statistics/components/SimpleTable';

export interface SkuAttribute {
  label: string;
  value: React.ReactNode;
  secondaryValue?: React.ReactNode;
}

interface SkuAttributesProps {
  attributes: SkuAttribute[];
}

const columns: ColumnsType<SkuAttribute> = [
  {
    dataIndex: 'label',
    key: 'label',
    title: 'Attributes',
    width: '18rem',
    render: (value: string) => <Label>{value}</Label>,
  },
  {
    dataIndex: 'value',
    key: 'value',
    title: '',
    width: '11rem',
  },
  {
    dataIndex: 'secondaryValue',
    key: 'secondary-value',
    title: '',
  },
];

const SkuAttributes: React.FC<SkuAttributesProps> = ({ attributes }) => {
  const rowKeyProvider = useCallback((record: SkuAttribute) => record.label, []);

  return (
    <Column>
      <SimpleTable
        id="SkuAttributes"
        dataSource={attributes}
        columns={columns}
        showHeader={true}
        rowKey={rowKeyProvider}
      />
    </Column>
  );
};

export default SkuAttributes;
