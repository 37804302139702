import React from 'react';
import { styled } from 'styled-components';
import { COLORS, computeRemSize } from '@ydistri/ds';
import { Popover } from 'antd';
import { AiOutlineEyeInvisible } from 'react-icons/ai';

const PrivateCalculationIndicatorWrapper = styled.div`
  margin-left: auto;
  color: ${() => COLORS.PRIMARY};
`;

const PrivateCalculationIndicator: React.FC = () => {
  return (
    <Popover content="Private calculation">
      <PrivateCalculationIndicatorWrapper data-type="indicator-calculation-private">
        <AiOutlineEyeInvisible size={computeRemSize(24)} />
      </PrivateCalculationIndicatorWrapper>
    </Popover>
  );
};

export default PrivateCalculationIndicator;
