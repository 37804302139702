import { Chart, ChartDataset, TooltipModel } from 'chart.js';
import React from 'react';

export const LINE_TYPES = ['Sale', 'Forecast'];
export type LineTypesTuple = typeof LINE_TYPES;

export type TChartLineInfo = ChartDataset<'line'>;

/**
 * Key of this object is a name of line (TLineType.name) for example
 * "SaleRegular" or "SalePromo".
 * We create a set of such objects for each line.
 */
export type TParsedMonthlyTransactionsData = Record<
  string,
  {
    quantity: number | null;
    /** Sum o quantities of all lines below this line's priority and this line's quantity */
    quantityToThisPriority: number | null;
    quantityCumulative: number | null;
    /** Sum of cumulative quantities of all lines below this line's priority and this line's cumulative quantity */
    quantityToThisPriorityCumulative: number | null;
    value: number | null;
    /** Sum o values of all lines below this line's priority and this line's value */
    valueToThisPriority: number | null;
    /** Sum of cumulative values of all lines below this line's priority and this line's cumulative value */
    valueToThisPriorityCumulative: number | null;
    valueCumulative: number | null;
    avgValue: number;
  } | null
>;

export interface TParsedMonthlyTransactions {
  month: number;
  year: number;
  dates: {
    stringFrom: string;
    stringTo: string;
    dateFrom: Date;
    dateTo: Date;
  };
  data: TParsedMonthlyTransactionsData;
}

export enum Units {
  VALUE = 'Value',
  QUANTITY = 'Quantity',
}

/**
 * Definition of a chart line like Sale Regular, Sale Promo, Forecast Min, Forecast Max
 */
export interface TLineType {
  /** Each line has a priority within its type. Lines are stacked on top of each other based on priority (from lower to higher) */
  priority: number;
  /** Code name of the line */
  name: string;
  /** Either Sale or Forecast */
  type: LineTypesTuple[number];
  /** Display label of the lin e*/
  label: string;
  /** Line parameters for the charting framework */
  lineInfo: TChartLineInfo;
}

export type TCumulativeLine = Record<
  string,
  {
    quantity: number;
    value: number;
  }
>;

export type TChartLine = Record<string, (number | null)[]>;

export type TooltipCallback = (
  tooltipModel: TooltipModel<'line'>,
  lines: TLineType[],
  datasets: ChartDataset[],
  parsedMonthlyTransactions: TParsedMonthlyTransactions[],
  applicationDate: Date,
  chartRef: React.RefObject<Chart<'line'>>,
  currency: string,
  vat: number,
  showAvgValue: boolean,
) => Date | undefined;
