import React, { useMemo } from 'react';
import { styled } from 'styled-components';
import { Popover } from 'antd';
import { ImTruck } from 'react-icons/im';
import { Column, computeRemSize, ReadOnlyItem, Text, Title } from '@ydistri/ds';
import { CalculationResponse } from '@ydistri/api-sdk';
import { format } from 'date-fns';

const ProductionCalculationIndicatorWrapper = styled.div`
  margin-left: auto;
  color: #839883;
`;

interface ProductionCalculationIndicatorProps {
  finishExecutionUntil: CalculationResponse['finishExecutionUntil'];
}

const ProductionCalculationIndicator: React.FC<ProductionCalculationIndicatorProps> = ({
  finishExecutionUntil,
}) => {
  const popoverContent = useMemo(() => {
    if (!finishExecutionUntil) return null;

    return (
      <Column>
        <Title>Production calculation</Title>
        <ReadOnlyItem
          label="Finish execution until"
          value={format(new Date(finishExecutionUntil), 'd. M. yyyy')}
        />
        <Text>
          This calculation will affect available supply for next calculations up to this date.
        </Text>
      </Column>
    );
  }, [finishExecutionUntil]);

  return (
    <Popover content={popoverContent}>
      <ProductionCalculationIndicatorWrapper data-type="indicator-calculation-production">
        <ImTruck size={computeRemSize(24)} />
      </ProductionCalculationIndicatorWrapper>
    </Popover>
  );
};

export default ProductionCalculationIndicator;
