import React, { useCallback, useMemo } from 'react';
import { MenuProps } from 'antd';
import useCategoryId from '../../../hooks/useCategoryId';
import { ApiAction } from '@ydistri/api-sdk';
import { useResetCategoryConfigurationsMutation } from '../../../components/global/CategoryTree/apiCategoryTree';
import { addToast } from '../../../store/toastSlice';
import { useDispatch } from 'react-redux';
import { Button, computeRemSize, GenericConfirmationModal, ResetButtonDropdown } from '@ydistri/ds';
import { MenuInfo } from 'rc-menu/lib/interface';
import { MdExpandMore } from 'react-icons/md';

interface ResetOptionBase {
  key: string;
  label: string;
  toastMessage: string;
  toastErrorMessage: string;
  displayForRootCategory: boolean;
  displayForNonRootCategory: boolean;
  action: ApiAction;
}

type ResetOption = ResetOptionBase &
  (
    | {
        showConfirmationModal: true;
        confirmationModalTitle: string;
      }
    | {
        showConfirmationModal: false;
      }
  );

const resetOptions: ResetOption[] = [
  {
    key: 'current',
    label: 'Reset current category',
    toastMessage: 'Current category was successfully reset',
    toastErrorMessage: 'Reset of current categories failed',
    displayForRootCategory: false,
    displayForNonRootCategory: true,
    action: ApiAction.DeleteSingleCategoryDeviations,
    showConfirmationModal: false,
  },
  {
    key: 'current-and-sub',
    label: 'Reset current category and all subcategories',
    toastMessage: 'Current category and all its subcategories were successfully reset',
    toastErrorMessage: 'Reset of current category and its subcategories failed',
    displayForRootCategory: false,
    displayForNonRootCategory: true,
    action: ApiAction.DeleteSubordinateCategoryDeviations,
    showConfirmationModal: true,
    confirmationModalTitle: 'Reset current category and all subcategories?',
  },
  {
    key: 'root-current-and-sub',
    label: 'Reset all subcategories',
    toastMessage: 'Subcategories were successfully reset',
    toastErrorMessage: 'Reset of subcategories failed',
    displayForRootCategory: true,
    displayForNonRootCategory: false,
    action: ApiAction.DeleteSubordinateCategoryDeviations,
    showConfirmationModal: true,
    confirmationModalTitle: 'Reset all subcategories?',
  },
];

const icon = <MdExpandMore size={computeRemSize(24)} />;

const ConfigurationResetButton: React.FC = () => {
  const selectedCategoryId = useCategoryId();
  const dispatch = useDispatch();
  const [resetCategoryConfigurations, { isLoading: isResetting }] =
    useResetCategoryConfigurationsMutation();
  const [resetOptionForConfirmation, setResetOptionForConfirmation] = React.useState<ResetOption>();

  const resetCategoryCallback = useCallback(
    (key: string) => {
      const resetOption = resetOptions.find(o => o.key === key);

      if (!resetOption) {
        return;
      }

      resetCategoryConfigurations({ categoryId: selectedCategoryId, apiAction: resetOption.action })
        .then(() => {
          dispatch(addToast({ message: resetOption.toastMessage }));
        })
        .catch(() => {
          dispatch(addToast({ message: resetOption.toastErrorMessage, isError: true }));
        });

      setResetOptionForConfirmation(undefined);
    },
    [dispatch, selectedCategoryId, resetCategoryConfigurations],
  );

  const handleMenuClick: MenuProps['onClick'] = useCallback(
    (e: MenuInfo) => {
      const resetOption = resetOptions.find(o => o.key === e.key);

      if (!resetOption) return;

      if (resetOption.showConfirmationModal) {
        setResetOptionForConfirmation(resetOption);
        return;
      }
      resetCategoryCallback(e.key);
    },
    [resetCategoryCallback],
  );

  const handleResetAfterConfirmation: () => void = useCallback(
    () =>
      resetOptionForConfirmation?.key
        ? resetCategoryCallback(resetOptionForConfirmation.key)
        : undefined,
    [resetCategoryCallback, resetOptionForConfirmation?.key],
  );

  const handleConfirmationClose: () => void = useCallback(
    () => setResetOptionForConfirmation(undefined),
    [setResetOptionForConfirmation],
  );

  const menuProps = useMemo(() => {
    const items: MenuProps['items'] = resetOptions
      .filter(
        o =>
          (o.displayForRootCategory && selectedCategoryId === 1) ||
          (o.displayForNonRootCategory && selectedCategoryId > 1),
      )
      .map(o => ({
        label: o.label,
        key: o.key,
        danger: true,
      }));

    return {
      items,
      onClick: handleMenuClick,
    };
  }, [handleMenuClick, selectedCategoryId]);

  return (
    <>
      <ResetButtonDropdown menu={menuProps}>
        <Button loading={isResetting} $colorVariant="ghostDanger" suffix={icon}>
          Reset options
        </Button>
      </ResetButtonDropdown>
      {resetOptionForConfirmation?.showConfirmationModal && (
        <GenericConfirmationModal
          data-testid="ResetConfigurationConfirmationModal"
          title={resetOptionForConfirmation.confirmationModalTitle}
          onConfirmed={handleResetAfterConfirmation}
          onCanceled={handleConfirmationClose}
        />
      )}
    </>
  );
};

export default ConfigurationResetButton;
