import {
  ForecastMonthlyOverviewResponse,
  ForecastValueResponse,
  OverviewValueType,
  SaleMonthlyOverviewResponse,
  TransactionValueResponse,
} from '@ydistri/api-sdk';

import {
  LineTypesTuple,
  TChartLine,
  TCumulativeLine,
  TLineType,
  TParsedMonthlyTransactions,
  TParsedMonthlyTransactionsData,
  TChartLineInfo,
} from './saleChartsTypes';

import cloneDeep from 'lodash.clonedeep';
import { ChartData, ChartDataset } from 'chart.js';
import { getForecastApproachLabel } from '@ydistri/utils';

export const defaultLine: TChartLineInfo = {
  data: [],
  tension: 0,
  fill: false,
  backgroundColor: 'rgba(0,0,0,0.4)',
  borderColor: 'rgba(0,0,0,1)',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  borderWidth: 2,
  pointBorderColor: 'rgba(0,0,0,1)',
  pointBackgroundColor: '#fff',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
  pointHoverBorderColor: 'rgba(0, 0, 0, 1)',
  pointHoverBorderWidth: 2,
  pointRadius: 3,
  pointHitRadius: 3,
  hidden: false,
};

export const saleUsedForForecast: TLineType = {
  priority: 0,
  type: 'Sale',
  name: 'SaleUsedForForecast',
  label: 'Sale used for forecast',
  lineInfo: {
    ...defaultLine,
    backgroundColor: 'rgba(255, 204, 153,0.4)',
    borderColor: 'rgba(255, 204, 153,1)',
    borderWidth: 7,
    pointBorderColor: 'rgba(255, 204, 153,1)',
    pointHoverBackgroundColor: 'rgba(255, 204, 153,1)',
    pointHoverBorderColor: 'rgba(255, 204, 153,1)',
  },
};

export const saleTypesDefault: TLineType[] = [
  {
    priority: 1,
    type: 'Sale',
    name: 'SaleRegular',
    label: 'Sale Regular',
    lineInfo: {
      ...defaultLine,
    },
  },
  {
    priority: 2,
    type: 'Sale',
    name: 'SalePromo',
    label: 'Sale Promo',
    lineInfo: {
      ...defaultLine,
      backgroundColor: 'rgba(159, 159, 159, .13)',
      borderColor: 'rgba(59, 59, 59, 1)',
      borderDash: [6],
      pointHoverBorderColor: 'rgba(59, 59, 59, 1)',
    },
  },
  {
    priority: 3,
    type: 'Sale',
    name: 'SaleSellout',
    label: 'Sale Sellout',
    lineInfo: {
      ...defaultLine,
      fill: '-1',
      backgroundColor: 'rgba(159, 159, 159, .13)',
      borderColor: 'rgba(0, 0, 0, .7)',
      borderDash: [6],
      pointHoverBorderColor: 'rgba(0, 0, 0, .7)',
    },
  },
];

export const FORECAST_CONFIDENCES: number[] = [50, 75, 80];

export const forecastTypesDefault: TLineType[] = [
  {
    priority: 1,
    type: 'Forecast',
    name: 'ForecastMin',
    label: 'Forecast min',
    lineInfo: {
      ...defaultLine,
      backgroundColor: 'rgba(97, 225, 0, 0.4)',
      borderColor: 'rgba(97, 225, 0, 1)',
      borderWidth: 3,
      pointBorderColor: 'rgba(97, 225, 0, 1)',
      pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
      pointHoverBorderColor: 'rgba(97, 225, 0, 1)',
    },
  },
  {
    priority: 2,
    type: 'Forecast',
    name: 'ForecastMax',
    label: 'Forecast max',
    lineInfo: {
      ...defaultLine,
      backgroundColor: 'rgba(155, 255, 0, .2)',
      borderColor: 'rgba(255, 0, 0, 1)',
      borderWidth: 4,
      pointBorderColor: 'rgba(255, 0, 0, 1)',
      pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
      pointHoverBorderColor: 'rgba(255, 0, 0, 1)',
    },
  },
];

export const getForecastTypes = (confidences: number[]): TLineType[] => {
  const forecastLines: TLineType[] = [];
  const opacity = ['.2', '.45', '.7', '1'];

  FORECAST_CONFIDENCES.forEach((confidence, index) => {
    if (confidences.includes(confidence)) {
      forecastLines.push({
        priority: FORECAST_CONFIDENCES.length + (index + 1),
        type: 'Forecast',
        name: `ForecastMin${confidence}`,
        label: `Forecast min ${getForecastApproachLabel(confidence)}`,
        lineInfo: {
          ...defaultLine,
          borderColor: `rgba(97, 225, 0, ${opacity[index]})`,
          borderWidth: 3,
          pointBorderColor: `rgba(97, 225, 0, ${opacity[index]})`,
          pointHoverBackgroundColor: `rgba(255, 255, 255, ${opacity[index]})`,
          pointHoverBorderColor: `rgba(97, 225, 0, ${opacity[index]})`,
        },
      });

      forecastLines.unshift({
        priority: FORECAST_CONFIDENCES.length - index,
        type: 'Forecast',
        name: `ForecastMax${confidence}`,
        label: `Forecast max ${getForecastApproachLabel(confidence)}`,
        lineInfo: {
          ...defaultLine,
          borderColor: `rgba(255, 0, 0, ${opacity[index]})`,
          borderWidth: 4,
          pointBorderColor: `rgba(255, 0, 0, ${opacity[index]})`,
          pointHoverBackgroundColor: `rgba(255, 255, 255, ${opacity[index]})`,
          pointHoverBorderColor: `rgba(255, 0, 0, ${opacity[index]})`,
        },
      });
    }
  });

  return forecastLines;
};

const getTransactionOverviewType = (
  overview: TransactionValueResponse | ForecastValueResponse,
): string => {
  return `${overview.transactionOverviewType}${
    'confidence' in overview ? overview.confidence : ''
  }`;
};

const getMonthlySaleData = (
  monthlySale: SaleMonthlyOverviewResponse,
  transactionType: string,
  returnValueType: OverviewValueType,
): number | null => {
  return (
    monthlySale.overviews.find(
      overview =>
        getTransactionOverviewType(overview) === transactionType &&
        overview.overviewValueType === returnValueType,
    ) ?? { value: null }
  ).value;
};

const getMonthlySaleQuantity = (
  monthlySale: SaleMonthlyOverviewResponse,
  transactionType: string,
): number | null => {
  return getMonthlySaleData(monthlySale, transactionType, OverviewValueType.Quantity);
};

const getMonthlySaleValue = (
  monthlySale: SaleMonthlyOverviewResponse,
  transactionType: string,
): number | null => {
  return getMonthlySaleData(monthlySale, transactionType, OverviewValueType.Value);
};

export const parseMonthlyTransactions = (
  monthlySales: SaleMonthlyOverviewResponse[] | ForecastMonthlyOverviewResponse[],
  lineTypes: TLineType[],
  dateToStartCalculatingCumulativeValues: Date,
): TParsedMonthlyTransactions[] => {
  const actualCumulativeValue: TCumulativeLine = {};

  //initialize cumulative values to zero
  lineTypes.forEach((lineType: TLineType) => {
    actualCumulativeValue[lineType.name] = {
      quantity: 0,
      value: 0,
    };
  });

  let actualMonthData: TCumulativeLine = actualCumulativeValue;
  const parsedCumulativeData: TCumulativeLine[] = monthlySales.map(monthlySale => {
    const newCumulativeLine: TCumulativeLine = {};

    lineTypes.forEach((lineType: TLineType) => {
      newCumulativeLine[lineType.name] = {
        quantity: actualMonthData[lineType.name].quantity,
        value: actualMonthData[lineType.name].value,
      };

      if (new Date(monthlySale.monthInterval.dateTo) > dateToStartCalculatingCumulativeValues) {
        const saleTypeQuantity = getMonthlySaleQuantity(monthlySale, lineType.name);
        const saleTypeValue = getMonthlySaleValue(monthlySale, lineType.name);

        newCumulativeLine[lineType.name].quantity += saleTypeQuantity ?? 0;
        newCumulativeLine[lineType.name].value += saleTypeValue ?? 0;
      }
    });
    actualMonthData = newCumulativeLine;
    return newCumulativeLine;
  });

  const verticalCumulativeValuesDefault = {
    quantityToThisPriority: 0,
    quantityToThisPriorityCumulative: 0,
    valueToThisPriority: 0,
    valueToThisPriorityCumulative: 0,
  };

  return monthlySales.map((monthlySale, index) => {
    const parsedData: TParsedMonthlyTransactionsData = {};
    let verticalCumulativeValues = { ...verticalCumulativeValuesDefault };
    const cumulativeValues = parsedCumulativeData[index];

    let lastLineTypeType: LineTypesTuple[number];
    lineTypes.forEach(lineType => {
      if (lastLineTypeType !== lineType.type) {
        verticalCumulativeValues = { ...verticalCumulativeValuesDefault };
        lastLineTypeType = lineType.type;
      }
      const saleTypeQuantity = getMonthlySaleQuantity(monthlySale, lineType.name);
      const saleTypeValue = getMonthlySaleValue(monthlySale, lineType.name);

      verticalCumulativeValues.quantityToThisPriority += saleTypeQuantity ?? 0;
      verticalCumulativeValues.quantityToThisPriorityCumulative +=
        cumulativeValues[lineType.name].quantity;

      verticalCumulativeValues.valueToThisPriority += saleTypeValue ?? 0;
      verticalCumulativeValues.valueToThisPriorityCumulative +=
        cumulativeValues[lineType.name].value;

      parsedData[lineType.name] = {
        quantity: saleTypeQuantity ?? null,
        quantityToThisPriority: verticalCumulativeValues.quantityToThisPriority,
        quantityCumulative: cumulativeValues[lineType.name].quantity,
        quantityToThisPriorityCumulative: verticalCumulativeValues.quantityToThisPriorityCumulative,
        value: saleTypeValue ?? null,
        valueToThisPriority: verticalCumulativeValues.valueToThisPriority,
        valueCumulative: cumulativeValues[lineType.name].value,
        valueToThisPriorityCumulative: verticalCumulativeValues.valueToThisPriorityCumulative,
        avgValue:
          saleTypeQuantity && saleTypeValue && saleTypeQuantity > 0
            ? saleTypeValue / saleTypeQuantity
            : 0,
      };
    });

    return {
      month: monthlySale.monthInterval.month,
      year: monthlySale.monthInterval.year,
      dates: {
        stringFrom: monthlySale.monthInterval.dateFrom,
        stringTo: monthlySale.monthInterval.dateTo,
        dateFrom: new Date(monthlySale.monthInterval.dateFrom),
        dateTo: new Date(monthlySale.monthInterval.dateTo),
      },
      data: parsedData,
    };
  });
};

/**
 * Take sales used for forecast and add it to sale types.
 * The priority of the sale used for forecast is the max priority of the sale types + 1
 * @param salesUsedForForecast
 * @param saleTypes
 */
export const addSalesUsedForForecastToSaleTypes = (
  salesUsedForForecast: TLineType,
  saleTypes: TLineType[],
): TLineType[] => {
  const maxPriority = Math.max(...saleTypes.map(saleType => saleType.priority));

  salesUsedForForecast.priority = maxPriority + 1;
  return saleTypes.concat(saleUsedForForecast);
};

export function copyLineTypeToParsedMonthlyTransactionsByAnotherLineType(
  parsedMonthlyTransactions: TParsedMonthlyTransactions[],
  lineTypeToCopy: TLineType,
  copyDataFromThisLineTypeName: string,
): TParsedMonthlyTransactions[] {
  return parsedMonthlyTransactions.map(transaction => {
    return {
      ...transaction,
      data: {
        ...transaction.data,
        [lineTypeToCopy.name]: transaction.data[copyDataFromThisLineTypeName],
      },
    };
  });
}

/**
 * Sort line types by priority
 * @param left
 * @param right
 */
const lineTypePrioritySorter = (left: TLineType, right: TLineType): number => {
  if (left.priority > right.priority) {
    return 1;
  }
  if (left.priority < right.priority) {
    return -1;
  }
  return 0;
};

/**
 * Get sale line definitions sorted by priority
 */
export const getSaleLineDefinitions = (): TLineType[] => {
  saleTypesDefault.sort(lineTypePrioritySorter);
  return saleTypesDefault;
};

/**
 * Get forecast line definitions sorted by priority
 */
export const getForecastLineDefinitions = (): TLineType[] => {
  forecastTypesDefault.sort(lineTypePrioritySorter);
  return forecastTypesDefault;
};

export const setupChartLines = (
  lineTypes: TLineType[],
  parsedMonthlyTransactions: TParsedMonthlyTransactions[],
  cumulativeMode: boolean,
  dateToStartCalculatingCumulativeValues: Date,
  units: 'Quantity' | 'Value' = 'Value',
): TChartLine => {
  const chartLines: TChartLine = {};
  lineTypes.forEach(lineType => {
    chartLines[lineType.name] = parsedMonthlyTransactions.map(pmt => {
      const lineTypeData = pmt.data[lineType.name];

      if (
        pmt.dates.dateTo > dateToStartCalculatingCumulativeValues &&
        lineType.name === 'SaleUsedForForecast'
      ) {
        return null;
      }

      if (
        lineTypeData &&
        units === 'Quantity' &&
        lineTypeData.quantity !== null &&
        lineTypeData.quantity >= 0
      ) {
        if (cumulativeMode && pmt.dates.dateTo > dateToStartCalculatingCumulativeValues) {
          if (lineType.type === 'Sale') {
            return lineTypeData.quantityToThisPriorityCumulative ?? 0;
          } else {
            return lineTypeData.quantityCumulative ?? 0;
          }
        } else {
          return lineType.type === 'Sale'
            ? lineTypeData.quantityToThisPriority
            : lineTypeData.quantity;
        }
      } else if (
        lineTypeData &&
        units === 'Value' &&
        lineTypeData.value !== null &&
        lineTypeData.value >= 0
      ) {
        if (cumulativeMode && pmt.dates.dateTo > dateToStartCalculatingCumulativeValues) {
          if (lineType.type === 'Sale') {
            return lineTypeData.valueToThisPriorityCumulative ?? 0;
          } else {
            return lineTypeData.valueCumulative ?? 0;
          }
        } else {
          return lineType.type === 'Sale' ? lineTypeData.valueToThisPriority : lineTypeData.value;
        }
      } else {
        return null;
      }
    });
  });

  return chartLines;
};

export const hideNullLineTypes = (
  lineTypes: TLineType[],
  parsedMonthlyTransactions: TParsedMonthlyTransactions[],
): TLineType[] => {
  const lineTypesCopy = cloneDeep(lineTypes);

  lineTypesCopy.forEach((lineType: TLineType, index) => {
    const found = parsedMonthlyTransactions.find(pmt => {
      const pmtData = pmt.data[lineType.name];
      return lineType.name === 'SaleUsedForForecast' || (pmtData?.quantity && pmtData.quantity > 0);
    });

    if (!found) {
      lineTypesCopy[index].lineInfo.hidden = true;
    }
  });

  return lineTypesCopy;
};

export const changeFillInLineTypesBasedOnHiddenLines = (lineTypes: TLineType[]): TLineType[] => {
  const reversedLineTypes = [...lineTypes].reverse();

  lineTypes.forEach((lineType: TLineType, index) => {
    const fill = lineType.lineInfo.fill;

    if (fill !== true && fill !== false) {
      let numericFill = 0;
      if (typeof fill === 'number') {
        numericFill = fill;
      } else if (typeof fill === 'string') {
        numericFill = parseInt(fill);
      }

      if (numericFill !== 0) {
        const priority = lineType.priority;
        let positive = 1;
        let cutLineTypes: TLineType[];
        if (numericFill < 0) {
          positive = -1;
          cutLineTypes = reversedLineTypes.filter(lt => lt.priority < priority);
        } else {
          cutLineTypes = lineTypes.filter(lt => lt.priority > priority);
        }

        if (Math.abs(numericFill) > cutLineTypes.length && positive < 0) {
          lineTypes[index].lineInfo.fill = true;
        } else {
          let actuallyFilledLines = 0;
          let totalFilledLines = 0;
          cutLineTypes.forEach((lt: TLineType, index: number) => {
            if (
              actuallyFilledLines === Math.abs(numericFill) ||
              (Math.abs(numericFill) < index + 1 && actuallyFilledLines > 0)
            )
              return;
            totalFilledLines++;
            if (lt.lineInfo.hidden) {
              return;
            }
            actuallyFilledLines++;
            if (actuallyFilledLines === Math.abs(numericFill)) {
              return;
            }
          });
          lineTypes[index].lineInfo.fill = (totalFilledLines * positive).toString();
        }
      }
    }
  });

  return lineTypes;
};

export const createDatasetsFromLineTypesAndGraphLines = (
  lineTypes: TLineType[],
  chartLines: TChartLine,
): ChartDataset<'line'>[] => {
  return lineTypes.map(lineType => {
    const data: (number | null)[] = chartLines[lineType.name];

    return {
      label: lineType.label,
      ...lineType.lineInfo,
      data: data,
    };
  });
};

export const createChartDataFromDatasetsAndParsedMonthlyTransactions = (
  datasets: ChartDataset<'line'>[],
  parsedMonthlyTransactions: TParsedMonthlyTransactions[],
): ChartData<'line'> => {
  const labels = parsedMonthlyTransactions.map(m => m.dates.dateTo);
  return {
    labels,
    datasets,
  };
};

export const extractOnlySalesFromParsedData = (
  parsedMonthlyTransactions: TParsedMonthlyTransactions[],
): TParsedMonthlyTransactions[] => {
  return parsedMonthlyTransactions
    .map(transaction => {
      const newData: TParsedMonthlyTransactionsData = {};
      Object.keys(transaction.data).forEach(saleType => {
        if (saleType.includes('Sale') && saleType !== saleUsedForForecast.name) {
          newData[saleType] = transaction.data[saleType];
        }
      });

      return {
        ...transaction,
        data: newData,
      };
    })
    .filter(transaction => transaction.data.SaleRegular?.quantity !== null);
};

/**
 * Adds VAT to amount if vat is not NaN
 * @param amount
 * @param vat
 */
export const addVat = (amount: number, vat: number): number =>
  !isNaN(vat) ? Math.floor((amount + (amount / 100) * vat) * 100) / 100 : amount;
