import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CategoryConfigurationDefinitionResponse,
  EntityListResponse,
  TargetListResponse,
} from '@ydistri/api-sdk';
import { CalculationActionType, CalculationTableParams } from '../Calculations/calculationsTypes';

export enum AvailableModals {
  CATEGORY_INSIGHTS = 'categoryInsights',
  CATEGORY_EXCEPTIONS = 'categoryExceptions',
  TARGET_LIST_CONTENTS = 'targetListContents',
  ENTITY_LIST_CONTENTS = 'entityListContents',
  ENTITY_LIST_CONFIG_DELETE = 'entityListConfigDelete',
  NEW_APP_VERSION = 'newAppVersion',
  REPORT_ERROR = 'reportError',
  MULTISELECT_VALIDATION = 'multiselectValidation',
  SCOPE_EDIT = 'scopeEdit',
  STORE_OVERVIEW = 'storeOverview',
  SET_PRODUCTION = 'setProduction',
  UNSET_PRODUCTION = 'unsetProduction',
  MULTI_CALCULCATION_ACTIONS = 'multiCalculationActions',
  STORE_SALE_INFO = 'storeSaleInfo',
}

export type ModalDataMap = {
  [AvailableModals.CATEGORY_INSIGHTS]: undefined;
  [AvailableModals.CATEGORY_EXCEPTIONS]: undefined;
  [AvailableModals.TARGET_LIST_CONTENTS]: {
    targetList: TargetListResponse;
  };
  [AvailableModals.ENTITY_LIST_CONFIG_DELETE]: {
    entityList: EntityListResponse;
    configurationDefinition: CategoryConfigurationDefinitionResponse;
  };
  [AvailableModals.ENTITY_LIST_CONTENTS]: {
    entityList: EntityListResponse;
  };
  [AvailableModals.NEW_APP_VERSION]: undefined;
  [AvailableModals.REPORT_ERROR]: undefined;
  [AvailableModals.MULTISELECT_VALIDATION]: undefined;
  [AvailableModals.SCOPE_EDIT]: {
    scopeId: number;
  };
  [AvailableModals.STORE_OVERVIEW]: undefined;
  [AvailableModals.SET_PRODUCTION]: {
    calculationId: number;
    isErpDownload: boolean;
  };
  [AvailableModals.UNSET_PRODUCTION]: {
    calculationId: number;
  };
  [AvailableModals.MULTI_CALCULCATION_ACTIONS]: {
    calculationIds: number[];
    calculationTableParams: CalculationTableParams;
    calculationActionType: CalculationActionType;
  };
  [AvailableModals.STORE_SALE_INFO]: undefined;
};

interface ModalState {
  openedModal: AvailableModals | undefined;
  modalData: Partial<ModalDataMap>;
}

const initialState: ModalState = {
  openedModal: undefined,
  modalData: {},
};

export type OpenModalPayload<T extends AvailableModals> = {
  type: T;
  data: ModalDataMap[T];
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: <T extends AvailableModals>(
      state: ModalState,
      action: PayloadAction<OpenModalPayload<T>>,
    ) => {
      console.log(action.payload);
      state.openedModal = action.payload.type;
      state.modalData[action.payload.type] = action.payload.data;
    },
    closeModal: state => {
      state.openedModal = undefined;
      state.modalData = {};
    },
    resetState: () => initialState,
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export const modalReducer = modalSlice.reducer;
