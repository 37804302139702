import { ActionsBar, Section, Spinner } from '@ydistri/ds';
import React, { useCallback, useMemo, useState } from 'react';
import CreateIconButton from '../../../../components/buttons/CreateIconButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  archivedStateSorter,
  getArchivedItemsCount,
  onlyActive,
} from '../../common/administrationItemsLib';

import { addToast } from '../../../../store/toastSlice';
import ShowArchivedItemsToggle from '../../common/ShowArchivedItemsToggle';
import { ReduxState } from '../../../../store';
import { useGetEntityListsQuery } from '../apiEntityLists';
import { EntityListType } from '@ydistri/api-sdk';
import { setSelectedEntityList, setShowArchivedItems } from '../entityListAdministrationSlice';
import { titleByEntityListType } from '../entityListsLib';
import EntityListsList from './EntityListsList';
import CreateEntityListModal from '../modals/CreateEntityListModal';

interface EntityListAdministrationCatalogProps {
  entityListType: EntityListType;
}

const EntityListAdministrationCatalog: React.FC<EntityListAdministrationCatalogProps> = ({
  entityListType,
}) => {
  const dispatch = useDispatch();

  const { data: entityLists, isFetching } = useGetEntityListsQuery(entityListType);

  const showArchivedItems = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].showArchivedItems,
  );
  const selectedEntityList = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].selectedEntityList,
  );

  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const onCreateEntityListClicked = useCallback(() => {
    setModalOpened(previousModalOpened => !previousModalOpened);
  }, []);

  const onCreateEntityListModalClose = useCallback(() => {
    setModalOpened(false);
  }, []);

  //Archived entity lists are not displayed by default
  //If they are, they are listed after the active ones and sorted by name
  const displayedData = useMemo(() => {
    if (entityLists) {
      if (!showArchivedItems) {
        return onlyActive(entityLists);
      } else {
        return Array.from(entityLists).sort(archivedStateSorter);
      }
    }
  }, [entityLists, showArchivedItems]);

  const archivedItemsCount = useMemo(
    () => (!entityLists ? 0 : getArchivedItemsCount(entityLists)),
    [entityLists],
  );

  /**
   * Shows or hides archived items
   */
  const toggleShowArchived = useCallback(() => {
    const newShowArchivedItems = !showArchivedItems;
    dispatch(setShowArchivedItems({ entityListType, data: newShowArchivedItems }));
    if (newShowArchivedItems) {
      dispatch(
        addToast({
          message: `Showing archived ${titleByEntityListType[entityListType]}s`,
          description: `Archived ${titleByEntityListType[entityListType]}s are shown below the active ones`,
        }),
      );
    }

    if (selectedEntityList?.isArchived) {
      dispatch(setSelectedEntityList({ entityListType, data: undefined }));
    }
  }, [dispatch, entityListType, selectedEntityList?.isArchived, showArchivedItems]);

  const existingEntityListNames = useMemo(
    () => entityLists?.map(el => el.name ?? ''),
    [entityLists],
  );

  const contentActions = useMemo(() => {
    return (
      <ActionsBar>
        <ShowArchivedItemsToggle
          archivedItemsCount={archivedItemsCount}
          checked={showArchivedItems}
          onClick={toggleShowArchived}
        />
      </ActionsBar>
    );
  }, [archivedItemsCount, showArchivedItems, toggleShowArchived]);

  const headerActions = useMemo(() => {
    return (
      <ActionsBar>
        <CreateIconButton
          size="small"
          tooltip="Create list"
          onClick={onCreateEntityListClicked}
          data-testid="create_new_entity_list"
        />
      </ActionsBar>
    );
  }, [onCreateEntityListClicked]);

  return (
    <>
      <Section
        header={titleByEntityListType[entityListType]}
        headerActions={headerActions}
        contentActions={contentActions}
        $scrollableContent={true}
        $ratio={0}
        data-testid="EntityListsSection"
        $width="25rem"
      >
        {isFetching && <Spinner />}
        <EntityListsList
          entityListType={entityListType}
          selectedItem={selectedEntityList}
          data={displayedData}
          loading={isFetching}
        />
      </Section>
      {modalOpened && (
        <CreateEntityListModal
          entityListType={entityListType}
          onClose={onCreateEntityListModalClose}
          confirmButtonLabel="Create"
          restrictedNames={existingEntityListNames}
        />
      )}
    </>
  );
};

export default EntityListAdministrationCatalog;
