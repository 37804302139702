import {
  CalculationStatus,
  CalculationType,
  CalculationWithOverviewResponse,
} from '@ydistri/api-sdk';
import React from 'react';
import { Column, Row, Text, Title } from '@ydistri/ds';
import CalculationDescription from '../../common/CalculationDescription';
import { Divider } from 'antd';
import PrivateCalculationIndicator from './PrivateCalculationIndicator';
import ProductionCalculationIndicator from './ProductionCalculationIndicator';
import { styled } from 'styled-components';
import CalculationActions from '../../actions/CalculationActions';
import OverlapIndicator from './OverlapIndicator';
import PapCalculationIndicator from './PapCalculationIndicator';

const splitter = <Divider type="vertical" />;

const CalculationDecoration = styled(Row)`
  margin-left: auto;
`;

interface CalculationInfoComponentProps {
  calculation: CalculationWithOverviewResponse;
  onDelete?: (calculation: CalculationWithOverviewResponse) => void;
  onToggleStatistics?: (calculationId: number) => void;
  disabled?: boolean;
  showActions?: boolean;
}

/**
 * Displays calculation's title, source template and description.
 * Icon indicates the calculation's type.
 * @param calculation
 * @param onDelete
 * @param onToggleStatistics
 * @param disabled
 * @constructor
 */
const CalculationRowTitle: React.FC<CalculationInfoComponentProps> = ({
  calculation,
  onDelete,
  onToggleStatistics,
  disabled = false,
  showActions = true,
}) => {
  return (
    <Row $flexWrap="nowrap" $gap="1rem">
      <Column>
        <Title $size="small" $semiBold>
          {calculation.title}
        </Title>
        <Row $gap="0.5rem" $alignItems="center">
          <Text $type="light">
            {
              /* !!!!!!! Source template actually CAN be null, in case template is deleted */
              /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
              calculation.sourceTemplate?.title ?? 'Deleted template'
            }
          </Text>
          {calculation.description && (
            <>
              {splitter}
              <CalculationDescription value={calculation.description} />
            </>
          )}
        </Row>
      </Column>
      <CalculationDecoration
        $gap="1rem"
        $flexWrap="nowrap"
        $alignItems="center"
        id={`calculation-decoration-${calculation.id}`}
      >
        {(calculation.overlappingCalculations?.length ?? 0) > 0 &&
          calculation.status !== CalculationStatus.Crashed && (
            <OverlapIndicator overlappingCalculations={calculation.overlappingCalculations ?? []} />
          )}
        {calculation.type === CalculationType.Private && <PrivateCalculationIndicator />}
        {calculation.isPap && <PapCalculationIndicator />}
        {calculation.type === CalculationType.Production && (
          <ProductionCalculationIndicator finishExecutionUntil={calculation.finishExecutionUntil} />
        )}
        {showActions && (
          <CalculationActions
            calculation={calculation}
            onDelete={onDelete}
            onToggleStatistics={onToggleStatistics}
            disabled={disabled}
            id="calculation-row-actions"
          />
        )}
      </CalculationDecoration>
    </Row>
  );
};

export default CalculationRowTitle;
