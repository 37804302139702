import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import {
  configurationSelectOptions,
  rtkQueryPatchStatusToConfigurationFieldStatus,
  skuColumnBackgroundColors,
  SkuTypeColumn,
} from '@ydistri/utils';
import {
  ConfigurationDataType,
  ConfigurationFieldType,
  FieldConfigurationDeleteRequest,
  FieldConfigurationRequest,
  SkuType,
} from '@ydistri/api-sdk';
import {
  computeRemSize,
  ConfigurationInput,
  ConfigurationSelect,
  ConfigurationSwitch,
  STYLE,
} from '@ydistri/ds';
import useCategoryId from '../../../hooks/useCategoryId';
import { useCategoryConfiguration } from '../../../hooks/useCategoryConfiguration';
import { usePatchCategoryConfigurationMutation } from '../../../screens/Configuration/apiTemplate';
import { useDeleteCategoryConfigurationMutation } from '../CategoryTree/apiCategoryTree';
import { Popover } from 'antd';
import { css, styled } from 'styled-components';
import { getConfigurationFieldHoverData } from './getConfigurationFieldHoverData';
import { createPayload } from './createPayload';
import ConfigurationSelectTargetList from '../../../screens/Configuration/ConfigurationCategories/SegmentPotentialDefinition/ConfigurationSelectTargetList';

interface ConfigurationFieldWrapperProps {
  $skuTypeColumn: SkuTypeColumn;
  $inputWidth?: CSSProperties['width'];
  $padding?: CSSProperties['padding'];
  $noAdditionalSpace?: boolean;
}

export const ConfigurationFieldWrapper = styled.span<ConfigurationFieldWrapperProps>`
  display: flex;
  align-items: center;
  padding: ${({ $padding }) => $padding ?? '0.25rem 0.25rem 0.25rem 0.75rem'};
  min-height: ${computeRemSize(37)};
  height: 100%;

  ${({ $noAdditionalSpace, $inputWidth }) => {
    if (!$noAdditionalSpace) {
      return css`
        min-width: calc(${$inputWidth ?? STYLE.DEFAULT_INPUT_WIDTH} + 1.5rem);
      `;
    }
  }}

  ${({ $skuTypeColumn }) =>
    $skuTypeColumn !== null &&
    css`
      background-color: ${skuColumnBackgroundColors[$skuTypeColumn]};
    `}
`;

export interface ConfigurationFieldProps {
  disabled?: boolean;
  skuTypeColumn: SkuTypeColumn;
  fieldType: ConfigurationFieldType;
  dataType: ConfigurationDataType;
  inputWidth?: CSSProperties['width'];
  showValueAsText?: boolean;
  padding?: CSSProperties['padding'];
  noAdditionalSpace?: boolean;
  forcedCategoryId?: number;
}

const ConfigurationField: React.FC<ConfigurationFieldProps> = ({
  disabled = false,
  skuTypeColumn,
  fieldType,
  dataType,
  inputWidth,
  showValueAsText = false,
  padding,
  noAdditionalSpace,
  forcedCategoryId,
}) => {
  const selectedCategoryId = useCategoryId();
  const categoryId = forcedCategoryId ?? selectedCategoryId;
  const { rootCategoryConfiguration, categoryConfigurationData: configurations } =
    useCategoryConfiguration(categoryId);

  const [hovered, setHovered] = useState(false);

  const [patchConfiguration, patchConfigurationStatus] = usePatchCategoryConfigurationMutation();
  const [deleteConfiguration, { isLoading: clearing }] = useDeleteCategoryConfigurationMutation();

  const status = useMemo(
    () => rtkQueryPatchStatusToConfigurationFieldStatus(patchConfigurationStatus),
    [patchConfigurationStatus],
  );

  useEffect(() => {
    patchConfigurationStatus.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  const updateConfigHandler = useCallback(
    (value: number | string) => {
      const basePayload: FieldConfigurationRequest = {
        configurationFieldType: fieldType,
        value: typeof value === 'number' ? value.toString() : value,
        skuType: undefined,
      };

      const payload: FieldConfigurationRequest[] = createPayload(basePayload, skuTypeColumn);

      patchConfiguration({
        categoryId,
        payload,
      });
    },
    [fieldType, skuTypeColumn, patchConfiguration, categoryId],
  );

  const clearExceptionHandler = useCallback(() => {
    const basePayload: FieldConfigurationDeleteRequest = {
      configurationFieldType: fieldType,
      skuType: undefined,
    };

    const payload: FieldConfigurationDeleteRequest[] = createPayload(basePayload, skuTypeColumn);

    deleteConfiguration({
      categoryId,
      fields: payload,
    });

    setHovered(false);
  }, [fieldType, skuTypeColumn, deleteConfiguration, categoryId]);

  const validSkuType: SkuType = useMemo(
    () =>
      skuTypeColumn === null || skuTypeColumn === 'Target' ? SkuType.FastMover : skuTypeColumn,
    [skuTypeColumn],
  );

  const configuration = useMemo(
    () => configurations?.[fieldType]?.[validSkuType],
    [configurations, fieldType, validSkuType],
  );

  const exceptions = useMemo(() => {
    return configurations?.[fieldType]?.[validSkuType]?.exceptions ?? [];
  }, [configurations, fieldType, validSkuType]);

  const isHidden = useMemo(() => {
    let hidden = false;
    if (fieldType === ConfigurationFieldType.PsMaxForecastConfidence) {
      hidden =
        !configurations?.[ConfigurationFieldType.PsMonthsOfSupplyToKeep][validSkuType]?.value;
    } else if (
      fieldType === ConfigurationFieldType.PtMinForecastConfidence ||
      fieldType === ConfigurationFieldType.PtMinimal6MonthsFrequencyOfSales
    ) {
      hidden =
        !configurations?.[ConfigurationFieldType.PtMonthsOfSupplyToGetByForecast][validSkuType]
          ?.value;
    }
    return hidden;
  }, [configurations, fieldType, validSkuType]);

  const handleHoverChange = useCallback((open: boolean) => {
    setHovered(open);
  }, []);

  const hoverData = useMemo(
    () =>
      getConfigurationFieldHoverData({
        selectedCategoryId: categoryId,
        exceptions,
        rootCategoryConfiguration,
        fieldType,
        dataType,
        validSkuType,
        clearing,
        disabled,
        clearExceptionHandler,
      }),
    [
      categoryId,
      exceptions,
      rootCategoryConfiguration,
      fieldType,
      dataType,
      validSkuType,
      clearing,
      disabled,
      clearExceptionHandler,
    ],
  );

  const isTargetList = dataType === ConfigurationDataType.SelectBoxTargetList;

  const input = useMemo(() => {
    if (!configuration) {
      if (dataType === ConfigurationDataType.SelectBoxTargetList) {
        return <ConfigurationSelectTargetList width={inputWidth} disabled={disabled} />;
      }
      return null;
    }
    if (isHidden) return '-';
    if (showValueAsText) return configuration.value;

    switch (dataType) {
      case ConfigurationDataType.SelectBoxMinLayerList:
        return null; //special case, not here
      case ConfigurationDataType.SelectBoxTargetList:
        return null; //returned above
      case ConfigurationDataType.CheckBox:
        return (
          <ConfigurationSwitch
            status={status}
            overwritten={configuration.overwritten}
            parentException={exceptions.length > 0}
            value={parseInt(configuration.value) === 1}
            disabled={disabled}
            onChange={updateConfigHandler}
          />
        );
      case ConfigurationDataType.SelectBoxForecastConfidence:
        return (
          <ConfigurationSelect<number>
            status={status}
            overwritten={configuration.overwritten}
            parentException={exceptions.length > 0}
            options={
              disabled
                ? configurationSelectOptions.historicForecastConfidence
                : configurationSelectOptions[dataType]
            }
            disabled={disabled}
            value={parseInt(configuration.value)}
            onChange={updateConfigHandler}
            width={inputWidth}
          />
        );
      case ConfigurationDataType.SelectBoxMonthIdentifySkuType:
        return (
          <ConfigurationSelect
            status={status}
            overwritten={configuration.overwritten}
            parentException={exceptions.length > 0}
            options={configurationSelectOptions[dataType]}
            disabled={disabled}
            value={configuration.value}
            onChange={updateConfigHandler}
            width={inputWidth}
          />
        );
      case ConfigurationDataType.SelectBoxMonthsForForecast:
        return (
          <ConfigurationSelect
            status={status}
            overwritten={configuration.overwritten}
            parentException={exceptions.length > 0}
            options={configurationSelectOptions[dataType]}
            disabled={disabled}
            value={configuration.value}
            onChange={updateConfigHandler}
            width={inputWidth}
          />
        );
      case ConfigurationDataType.SelectBoxForcingLevelType:
        return (
          <ConfigurationSelect
            status={status}
            overwritten={configuration.overwritten}
            parentException={exceptions.length > 0}
            options={configurationSelectOptions[dataType]}
            disabled={disabled}
            value={configuration.value}
            onChange={updateConfigHandler}
            width={inputWidth}
          />
        );
      case ConfigurationDataType.InputNumber:
        return (
          <ConfigurationInput
            status={status}
            overwritten={configuration.overwritten}
            parentException={exceptions.length > 0}
            disabled={disabled}
            value={parseInt(configuration.value)}
            onChange={updateConfigHandler}
            width={inputWidth}
          />
        );
      default: {
        throw new Error(`Not implemented ConfigurationDataType case`);
      }
    }
  }, [
    configuration,
    isHidden,
    showValueAsText,
    dataType,
    inputWidth,
    disabled,
    status,
    exceptions.length,
    updateConfigHandler,
  ]);

  const popoverWidth = useMemo(
    () => ({ width: computeRemSize(500), minWidth: computeRemSize(500) }),
    [],
  );

  return (
    <Popover
      style={popoverWidth}
      content={hoverData}
      trigger="hover"
      placement={skuTypeColumn === 'Target' ? 'topRight' : undefined}
      open={hovered && !isTargetList && !!hoverData}
      onOpenChange={handleHoverChange}
    >
      <ConfigurationFieldWrapper
        $inputWidth={inputWidth}
        $skuTypeColumn={skuTypeColumn}
        $padding={padding}
        $noAdditionalSpace={noAdditionalSpace}
      >
        {input}
      </ConfigurationFieldWrapper>
    </Popover>
  );
};

export default ConfigurationField;
