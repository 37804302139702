import React, { useCallback, useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useCurrency } from '../../../hooks/useCurrency';
import { Column, computeRemSize, Section, tableScroll } from '@ydistri/ds';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { useGetStoreEvaluationRatesQuery } from './apiCalculationEvaluations';
import LoadingIndicator from '../../../components/global/LoadingIndicator/LoadingIndicator';
import {
  evaluationsRowClassName,
  EvaluationSummaryExtended,
  getBaseExecutionColumns,
} from './evaluationsLib';
import { SimpleTable } from '../Statistics/components/SimpleTable';
import OverallCalculationEvaluations from './OverallCalculationEvaluations';
import TableStoreEvaluationsExport from './TableStoreEvaluationsExport';
import { ApplicationDataType } from '@ydistri/utils';
import { useApplicationData } from '../../../hooks/useApplicationData';
import { CalculationStatisticClusterType } from '@ydistri/api-sdk';
import { ExpandableConfig } from 'antd/es/table/interface';
import TargetStoreDetailTable from './TargetStoreDetailTable';

const tableMarginTop = { marginTop: '-28px' };

const SectionStoreEvaluations: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const currencyId = useCurrency() ?? '...';
  const { [ApplicationDataType.STORE_CODE]: hasStoreCodes } = useApplicationData();
  const clusterType = useSelector((state: ReduxState) => state.evaluations.clusterType);

  const { data, isFetching: isFetchingStatistics } = useGetStoreEvaluationRatesQuery({
    calculationId: templateOrCalculation.id,
    clusterType,
  });

  const columns = useMemo(
    () =>
      getBaseExecutionColumns(
        currencyId,
        hasStoreCodes,
        clusterType === CalculationStatisticClusterType.StoreDepartment,
        true,
      ),
    [hasStoreCodes, clusterType, currencyId],
  );

  const expandableConfig: ExpandableConfig<EvaluationSummaryExtended> = useMemo(
    () => ({
      expandedRowRender: record => {
        if (!('store' in record)) return null;
        return <TargetStoreDetailTable sourceStoreId={record.store.id} />;
      },
    }),
    [],
  );

  const rowKeyProvider = useCallback(
    (record: EvaluationSummaryExtended) => ('store' in record ? record.store.id : '-invalid-'),
    [],
  );

  return (
    <Section>
      {isFetchingStatistics && <LoadingIndicator />}
      <Column $alignItems="flex-end" $padding={1}>
        <OverallCalculationEvaluations />
        {data && (
          <Column $alignItems="flex-end" $padding={1} style={tableMarginTop}>
            <TableStoreEvaluationsExport
              title={`Store evaluations (#${templateOrCalculation.id})`}
              dataSource={data}
              columns={columns}
            />
            <SimpleTable<EvaluationSummaryExtended>
              id="store-evaluations"
              scroll={tableScroll}
              rowKey={rowKeyProvider}
              dataSource={data}
              columns={columns}
              loading={isFetchingStatistics}
              showHeader={true}
              height={computeRemSize(480)}
              rowClassName={evaluationsRowClassName}
              expandable={expandableConfig}
            />
          </Column>
        )}
      </Column>
    </Section>
  );
};

export default SectionStoreEvaluations;
