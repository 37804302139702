import React from 'react';
import ValidatorContentSection from './ValidatorContentSection';
import { iterableValidatorSections } from '../../../helpers/types/iterableEnums';
import { Column, computeRemSize } from '@ydistri/ds';

interface ValidatorContentProps {}

const ValidatorContent: React.FC<ValidatorContentProps> = () => {
  return (
    <Column $width={computeRemSize(374)} $gap="1rem">
      {iterableValidatorSections.map(section => (
        <ValidatorContentSection key={section} section={section} />
      ))}
    </Column>
  );
};

export default ValidatorContent;
