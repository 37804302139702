import { computeRemSize } from '@ydistri/ds';
import {
  EvaluationRatesResponse,
  RoutesEvaluationRatesResponse,
  SourceStoreEvaluationRatesResponse,
} from '@ydistri/api-sdk';
import { computePercentage, formatNumber } from '@ydistri/utils';
import { ColumnsType } from 'antd/es/table';

export interface EvaluationSummaryObject {
  evaluationSummary?: EvaluationRatesResponse | RoutesEvaluationRatesResponse;
  evaluationRatesSummary?: EvaluationRatesResponse | RoutesEvaluationRatesResponse;
}

export type EvaluationSummaryExtended = Partial<
  EvaluationRatesResponse | RoutesEvaluationRatesResponse
> & {
  executedQuantityPercentage: number;
  executedValuePercentage: number;
  executedSkuCountPercentage: number;
} & (SourceStoreEvaluationRatesResponse | object);

export const extendEvaluationSummary = <T extends EvaluationSummaryObject>(
  d: T,
  key: keyof EvaluationSummaryObject,
): EvaluationSummaryExtended => {
  const values: EvaluationRatesResponse | undefined = d[key];
  if (!values) throw new Error('Error');

  return {
    ...d,
    executedQuantityPercentage: computePercentage(values.executedQuantity, values.totalQuantity),
    executedValuePercentage: computePercentage(values.executedValue, values.totalValue),
    executedSkuCountPercentage: computePercentage(values.executedSkuCount, values.totalSkuCount),
  };
};

export const evaluationsRowClassName = (tl: EvaluationSummaryExtended): string => {
  console.log({ tl });
  if ('evaluationSummary' in tl) {
    const {
      evaluationSummary: { executedRouteCount, totalRouteCount },
    } = tl;
    if (totalRouteCount) {
      if (totalRouteCount === executedRouteCount) return 'green';
      if (executedRouteCount > 0) return 'orange';
    } else {
      if (tl.executedValuePercentage >= 80) return 'green';
      if (tl.executedValuePercentage > 0) return 'orange';
    }
  }
  return 'red';
};

export const getBaseExecutionColumns = (
  currency: string,
  hasStoreCodes: boolean,
  includeDepartments: boolean,
  includeRoutes: boolean = true,
  colWidth: number = 150,
): ColumnsType<EvaluationSummaryExtended> => {
  const columns: ColumnsType<EvaluationSummaryExtended> = [];

  columns.push({
    title: `Store ID`,
    dataIndex: ['store', 'customerStoreId'],
    key: 'storeCustomerId',
    width: computeRemSize(100),
  });

  if (hasStoreCodes) {
    columns.push({
      title: `Store Code`,
      dataIndex: ['store', 'code'],
      key: 'storeCustomerCode',
      width: computeRemSize(100),
    });
  }

  columns.push({
    title: `Store Name`,
    dataIndex: ['store', 'name'],
    key: 'storeName',
  });

  if (includeDepartments) {
    columns.push({
      title: 'Department',
      key: 'departmentName',
      dataIndex: ['department', 'name'],
    });
  }

  columns.push(
    {
      title: `Executed Value [${currency}]`,
      dataIndex: ['evaluationSummary', 'executedValue'],
      key: 'executedValue',
      width: computeRemSize(160),
      sorter: (a, b) => (a.executedValue ?? 0) - (b.executedValue ?? 0),
      render: (value: number) => formatNumber(value, 0),
      align: 'right',
    },
    {
      title: `Total Value [${currency}]`,
      dataIndex: ['evaluationSummary', 'totalValue'],
      key: 'totalValue',
      width: computeRemSize(colWidth),
      sorter: (a, b) => (a.totalValue ?? 0) - (b.totalValue ?? 0),
      render: (value: number) => formatNumber(value, 0),
      align: 'right',
    },
    {
      title: 'Executed Value [%]',
      dataIndex: ['executedValuePercentage'],
      key: 'executedValuePercentage',
      width: computeRemSize(colWidth),
      sorter: (a, b) => a.executedValuePercentage - b.executedValuePercentage,
      defaultSortOrder: 'ascend',
      render: (value: number) => formatNumber(value, 0),
      align: 'right',
    },
    {
      title: `Executed SKUs`,
      dataIndex: ['evaluationSummary', 'executedSkuCount'],
      key: 'executedSkuCount',
      width: computeRemSize(colWidth),
      sorter: (a, b) => (a.executedSkuCount ?? 0) - (b.executedSkuCount ?? 0),
      align: 'right',
    },
    {
      title: `Total SKUs`,
      dataIndex: ['evaluationSummary', 'totalSkuCount'],
      key: 'totalSkuCount',
      width: computeRemSize(colWidth),
      sorter: (a, b) => (a.totalSkuCount ?? 0) - (b.totalSkuCount ?? 0),
      align: 'right',
    },
    {
      title: 'Executed SKUs [%]',
      dataIndex: ['executedSkuCountPercentage'],
      key: 'executedSkuCountPercentage',
      width: computeRemSize(110),
      sorter: (a, b) => (a.executedSkuCountPercentage ?? 0) - (b.executedSkuCountPercentage ?? 0),
      align: 'right',
    },
  );

  if (includeRoutes) {
    columns.push(
      {
        title: `Executed Routes`,
        dataIndex: ['evaluationSummary', 'executedRouteCount'],
        key: 'executedRouteCount',
        width: computeRemSize(colWidth),
        sorter: (a, b) =>
          'executedRouteCount' in a && 'executedRouteCount' in b
            ? (a.executedRouteCount ?? 0) - (b.executedRouteCount ?? 0)
            : 0,
        render: (value: number) => formatNumber(value, 0),
        align: 'right',
      },
      {
        title: `Total Routes`,
        dataIndex: ['evaluationSummary', 'totalRouteCount'],
        key: 'totalRouteCount',
        width: computeRemSize(colWidth),
        sorter: (a, b) =>
          'totalRouteCount' in a && 'totalRouteCount' in b
            ? (a.totalRouteCount ?? 0) - (b.totalRouteCount ?? 0)
            : 0,
        render: (value: number) => formatNumber(value, 0),
        align: 'right',
      },
    );
  }

  return columns;
};
