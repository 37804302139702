import React, { useEffect } from 'react';

import TargetListAdministrationCatalog from './catalog/TargetListAdministrationCatalog';
import { Helmet } from 'react-helmet';
import TargetListAdministrationContent from './content/TargetListAdministrationContent';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGetTargetListsQuery } from './apiTargetLists';
import { setSelectedTargetList } from './targetListAdministrationSlice';
import { setSubpage } from '../../../routes/routerSlice';
import { ProjectAdministrationSubpage } from '../projectAdministrationLib';
import { MainContentWrapper } from '../../../../../../../libs/ds/src/pages/PageLayout/pageLayoutComponents';

const TargetListsAdministration: React.FC = () => {
  const { id } = useParams();

  const { data: targetLists } = useGetTargetListsQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSubpage(ProjectAdministrationSubpage.TARGETLISTS));
  }, [dispatch]);

  useEffect(() => {
    if (id && targetLists) {
      const requestedTargetListId = parseInt(id);
      const requestedTargetList = targetLists.find(
        targetList => targetList.targetListId === requestedTargetListId,
      );
      if (requestedTargetList) {
        dispatch(setSelectedTargetList(requestedTargetList));
      }
    }
  }, [dispatch, id, targetLists]);

  return (
    <>
      <Helmet title="Target Lists Administration" />
      <MainContentWrapper>
        <TargetListAdministrationCatalog />
        <TargetListAdministrationContent />
      </MainContentWrapper>
    </>
  );
};

export default TargetListsAdministration;
