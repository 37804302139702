import React from 'react';
import LoadingIndicator from '../../../../components/global/LoadingIndicator/LoadingIndicator';
import { Alert } from 'antd';
import CalculationStatistics from './CalculationStatistics';
import { Row } from '@ydistri/ds';
import { styled } from 'styled-components';
import { useApplicationData } from '../../../../hooks/useApplicationData';
import { useGetStatisticsQuery } from '../../../CalculationDetail/Statistics/apiCalculationStatistics';
import { CalculationStatisticClusterType } from '@ydistri/api-sdk';

const CalculationStatisticsWrapper = styled.div`
  margin-left: 7.5rem;
`;

interface CalculationStatisticsProps {
  calculationId: number;
}

/**
 * Handles fetching and displaying calculation statistics or error messages
 * @param calculationId
 * @constructor
 */
const CalculationStatisticsController: React.FC<CalculationStatisticsProps> = ({
  calculationId,
}) => {
  const { data, isFetching, isError } = useGetStatisticsQuery({
    calculationId,
    clusterType: CalculationStatisticClusterType.StoreDepartment,
  });
  const appData = useApplicationData();

  if (isFetching) {
    return (
      <Row>
        <CalculationStatisticsWrapper>
          <LoadingIndicator />
        </CalculationStatisticsWrapper>
      </Row>
    );
  } else if (isError) {
    return <Alert message="Failed to load statistics" type="error" />;
  } else {
    if (data) {
      return (
        <CalculationStatisticsWrapper>
          <CalculationStatistics
            statistics={data}
            showForcedProductsStats={appData.forceQuantities}
            showMinimalLayerStats={appData.minimalLayers}
          />
        </CalculationStatisticsWrapper>
      );
    } else {
      return <Alert message="No statistics loaded" type="warning" />;
    }
  }
};

export default CalculationStatisticsController;
