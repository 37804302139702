import React, { useMemo } from 'react';
import { ImCalendar } from 'react-icons/im';
import ToggleIconButton from '../../../../../components/buttons/ToggleIconButton';
import { IconButton_IconSizes, IconButtonSize } from '@ydistri/ds';

interface ShowActualDataActionProps {
  onClick: () => void;
  checked?: boolean;
  size?: IconButtonSize;
}

const ShowActualDataAction: React.FC<ShowActualDataActionProps> = ({
  onClick,
  checked = false,
  size = 'small',
}) => {
  const icon = useMemo(() => <ImCalendar size={IconButton_IconSizes[size]} />, [size]);

  return (
    <ToggleIconButton
      size={size}
      tooltip={checked ? 'Show calculation SKU data' : 'Show actual SKU data'}
      onClick={onClick}
      icon={icon}
      checked={checked}
    />
  );
};

export default ShowActualDataAction;
