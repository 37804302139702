import React, { CSSProperties, useMemo, useState } from 'react';
import { InfiniteScrollParams, InfiniteScrollTable, Section } from '@ydistri/ds';
import { ColumnsType } from 'antd/es/table';
import { ProductResponse } from '@ydistri/api-sdk';
import { getColumnsForProductCatalog } from './productCatalogLib';
import { useApplicationData } from '../../../hooks/useApplicationData';
import { styled } from 'styled-components';
import { Alert, Space } from 'antd';
import SearchBox from '../SearchBox/SearchBox';
import { useColumnWidth } from '../../../hooks/useColumnWidth';

const ProductCatalogSection = styled(Section)`
  width: 40%;
  min-width: 40%;
`;

interface ProductCatalogProps<T extends InfiniteScrollParams> {
  header: string;
  headerActions?: React.ReactElement;
  id: string;
  data?: ProductResponse[];
  loading?: boolean;
  alertMessage?: string;
  alertDescription?: string;
  setParams: React.Dispatch<React.SetStateAction<T>>;
  height?: CSSProperties['height'];
}

const ProductCatalog = <U extends InfiniteScrollParams>({
  header,
  headerActions,
  id,
  data,
  loading = false,
  alertMessage,
  alertDescription,
  setParams,
  height = `calc(100vh - 300px)`,
}: ProductCatalogProps<U>): React.ReactElement => {
  const { productCode } = useApplicationData();
  const { productIdColumnWidth } = useColumnWidth();

  const [searchValue, setSearchValue] = useState<string>();

  const productListDetailColumns: ColumnsType<ProductResponse> = useMemo(
    () => getColumnsForProductCatalog(productCode, productIdColumnWidth),
    [productCode, productIdColumnWidth],
  );

  const headerActionsComplete = useMemo(
    () => (
      <Space>
        {headerActions}
        <SearchBox
          value={searchValue}
          onSearch={setSearchValue}
          disabled={alertMessage !== undefined}
        />
      </Space>
    ),
    [alertMessage, headerActions, searchValue],
  );

  return (
    <ProductCatalogSection
      header={header}
      headerActions={headerActionsComplete}
      headerActionsIncludeMarginBottom={false}
      $ratio={1}
    >
      {!alertMessage && (
        <InfiniteScrollTable<ProductResponse, U>
          id={id}
          searchValue={searchValue}
          height={height}
          rowKey="id"
          columns={productListDetailColumns}
          dataSource={data}
          loading={loading}
          setParams={setParams}
        />
      )}
      {alertMessage && (
        <Alert message={alertMessage} description={alertDescription} type="info" showIcon />
      )}
    </ProductCatalogSection>
  );
};

export default ProductCatalog;
