import React from 'react';
import { styled } from 'styled-components';
import { computeRemSize } from '@ydistri/ds';
import { MdAppShortcut } from 'react-icons/md';
import { COLORS } from '../../../../styles/style';

const PapCalculationIndicatorWrapper = styled.div`
  margin-left: auto;
  color: ${COLORS.PRIMARY};
`;

interface PapCalculationIndicatorProps {}

const PapCalculationIndicator: React.FC<PapCalculationIndicatorProps> = () => {
  return (
    <PapCalculationIndicatorWrapper data-type="indicator-calculation-pap">
      <MdAppShortcut size={computeRemSize(24)} />
    </PapCalculationIndicatorWrapper>
  );
};

export default PapCalculationIndicator;
