import React, { useMemo } from 'react';
import { ImEqualizer } from 'react-icons/im';
import ToggleIconButton from '../../../../../components/buttons/ToggleIconButton';
import { IconButton_IconSizes, IconButtonSize } from '@ydistri/ds';

interface ToggleShowSkuInfoActionProps {
  onClick: () => void;
  checked?: boolean;
  size?: IconButtonSize;
}

const ToggleShowSkuInfoAction: React.FC<ToggleShowSkuInfoActionProps> = ({
  onClick,
  checked = false,
  size = 'small',
}) => {
  const icon = useMemo(() => <ImEqualizer size={IconButton_IconSizes[size]} />, [size]);

  return (
    <ToggleIconButton
      size={size}
      tooltip="SKU information"
      onClick={onClick}
      icon={icon}
      checked={checked}
    />
  );
};

export default ToggleShowSkuInfoAction;
