import { IconButton, IconButton_IconSizes, IconButtonSize } from '@ydistri/ds';
import React, { useCallback, useMemo } from 'react';
import { MdOutlineDelete } from 'react-icons/md';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface DeleteIconButtonProps {
  disabled?: boolean;
  tooltip: string;
  onClick: () => void;
  size?: IconButtonSize;
  tooltipPosition?: TooltipPlacement;
}

const DeleteIconButton: React.FC<DeleteIconButtonProps> = ({
  disabled = false,
  tooltip,
  onClick,
  tooltipPosition = 'left',
  size = 'normal',
  ...rest
}: DeleteIconButtonProps) => {
  const icon = useMemo(() => <MdOutlineDelete size={IconButton_IconSizes[size]} />, [size]);

  const clickHandler = useCallback(
    (event: React.MouseEvent) => {
      console.log(event.target, event.currentTarget);
      event.stopPropagation();
      event.preventDefault();
      onClick();
    },
    [onClick],
  );

  return (
    <IconButton
      icon={icon}
      size={size}
      disabled={disabled}
      tooltip={tooltip}
      tooltipPosition={tooltipPosition}
      onClick={clickHandler}
      {...rest}
    />
  );
};

export default DeleteIconButton;
