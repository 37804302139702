import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CalculationCategoryPairingRequest,
  useGetCalculationCategoryPairingsQuery,
} from '../../../Calculations/apiCalculationsCategories';
import {
  computeRemSize,
  getDefaultSorting,
  InfiniteScrollTable,
  Panel,
  YColumnsType,
} from '@ydistri/ds';
import { Condition, Operation, SkuRedistributionResponse } from '@ydistri/api-sdk';
import { formatNumber } from '@ydistri/utils';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';
import {
  selectSKURedistribution,
  setRedistributionPairingCount,
} from '../../../Calculations/calculationsSlice';
import { useSearchParams } from 'react-router-dom';
import { NO_VALUE } from '../../../../lib/utils/utilsTypes';
import { createDebugLog } from '../../../../lib/utils/logging';
import { useColumnWidth } from '../../../../hooks/useColumnWidth';
import { useApplicationData } from '../../../../hooks/useApplicationData';
import useColumnDefinitionByType from '../../../../hooks/useColumnDefinitionByType';
const debugLog = createDebugLog('CalculationRedistributionBody');

const TABLE_OFFSET: number = 100; //to compensate for the table's header as it is rendered outside the table's container

const emptyData: SkuRedistributionResponse[] = [];

const SkuRedistributionTable = styled(
  InfiniteScrollTable<SkuRedistributionResponse, CalculationCategoryPairingRequest>,
)`
  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-small .ant-table-tbody > tr:not(:first-child) > td {
    padding-top: ${computeRemSize(2)} !important;
    padding-bottom: ${computeRemSize(2)} !important;
  }

  .selected-row > td.ant-table-cell-row-hover,
  .selected-row > td.ant-table-cell {
    background-color: #f29718 !important;
    color: white;
  }
`;

export interface CalculationRedistributionBodyProps {
  calculationId: number;
  categoryId: number;
  height?: number;
}

const computeTableSize = (newSize: number) => Math.max(newSize, 180);

const removeCondition = (conditions: Condition[], fieldName: string) => {
  //remove previous brand condition if any
  const conditionIndex = conditions.findIndex(condition => condition.fieldName === fieldName);
  if (conditionIndex !== -1) {
    debugLog('field %s is present in conditions, removing it', fieldName);
    conditions.splice(conditionIndex, 1);
    debugLog('new conditions: %s', JSON.stringify(conditions));
  }
};

const removeBrandCondition = (conditions: Condition[]) => {
  removeCondition(conditions, 'Product/Brand/Id');
};

const removeOutOfStockFilter = (conditions: Condition[]) => {
  removeCondition(conditions, 'TotalAvailableSupplyOfTargetSku');
};

const removeForcedRedistributionFilter = (conditions: Condition[]) => {
  removeCondition(conditions, 'ForcedQuantitySendToRedistribution');
};

export const CALCULATION_REDISTRIBUTION_PAGE_SIZE = 100;

const CalculationRedistributionBody: React.FC<CalculationRedistributionBodyProps> = ({
  calculationId,
  categoryId,
}) => {
  const dispatch = useDispatch();
  const { productIdColumnWidth } = useColumnWidth();
  const { departments: hasDepartments, brands: hasBrands } = useApplicationData();
  const productColumnGetterFunction = useColumnDefinitionByType<SkuRedistributionResponse>({
    type: 'Product',
  });

  const selectedItem = useSelector(state => state.calculations.selectedSKURedistribution);
  const columns = useMemo(() => {
    const result: YColumnsType<SkuRedistributionResponse>[] = [
      {
        ...productColumnGetterFunction(),
        width: productIdColumnWidth,
      },
      {
        title: 'Product name',
        key: 'productName',

        dataIndex: ['product', 'name'],
        sorter: true,
        apiColumnName: 'Product/Name',
        apiFilterable: true,
        apiFilterType: 'text',
      },
    ];

    if (hasBrands) {
      result.push({
        title: 'Brand',
        key: 'brandName',
        dataIndex: ['brand', 'name'],
        sorter: true,
        // width: '8rem',
        apiColumnName: 'Brand/Name',
        apiFilterable: true,
        apiFilterType: 'text',
      });
    }

    if (hasDepartments) {
      result.push({
        title: 'Department',
        key: 'departmentName',
        dataIndex: ['department', 'name'],
        sorter: true,
        // width: '8rem',
        apiColumnName: 'Department/Name',
        apiFilterable: true,
        apiFilterType: 'text',
      });
    }

    result.push({
      title: 'Source Store [from]',
      key: 'sourceStore',
      dataIndex: ['sourceWarehouse', 'name'],
      sorter: true,
      width: '18rem',
      apiColumnName: 'SourceWarehouse/Name',
      apiFilterable: true,
      apiFilterType: 'text',
    });

    result.push({
      title: 'Target Store [to]',
      key: 'targetStore',
      dataIndex: ['targetWarehouse', 'name'],
      sorter: true,
      width: '18rem',
      apiColumnName: 'TargetWarehouse/Name',
      apiFilterable: true,
      apiFilterType: 'text',
    });

    result.push({
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
      align: 'right',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: '8rem',
      apiColumnName: 'Quantity',
      apiFilterable: true,
      apiFilterType: 'number',
    });

    result.push({
      title: 'Value',
      key: 'value',
      dataIndex: 'value',
      align: 'right',
      width: '8rem',
      render: (value: number) => formatNumber(value, 2),
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      apiColumnName: 'Value',
      apiFilterable: true,
      apiFilterType: 'number',
    });

    result.push({
      title: 'Source SKU Type',
      key: 'sourceSkuType',
      dataIndex: 'sourceSkuTypeId',
      width: '12rem',
      sorter: true,
      apiColumnName: 'SourceSkuTypeId',
      apiFilterable: true,
      apiFilterType: 'options',
      filters: [
        {
          text: 'DeadStock',
          value: 'DeadStock',
        },
        {
          text: 'SlowMover',
          value: 'SlowMover',
        },
        {
          text: 'FastMover',
          value: 'FastMover',
        },
      ],
    });

    return result;
  }, [productColumnGetterFunction, productIdColumnWidth, hasBrands, hasDepartments]);

  const redistributionFilterBrandId = useSelector(
    state => state.calculations.redistributionFilterBrandId,
  );
  const redistributionFilterOutOfStock = useSelector(
    state => state.calculations.redistributionFilterOutOfStock,
  );
  const redistributionFilterForced = useSelector(
    state => state.calculations.redistributionFilterForced,
  );

  const [params, setParams] = useState<CalculationCategoryPairingRequest>({
    calculationId,
    categoryId,
    skip: 0,
    top: CALCULATION_REDISTRIBUTION_PAGE_SIZE,
    sortings: getDefaultSorting(columns),
  });

  const ref = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState<number>(400);
  const [resizeObserver, setResizeObserver] = useState<ResizeObserver | null>(null);
  // eslint-disable-next-line no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const searchQuery = useSelector(state => state.calculations.redistributionSearchQuery);

  //TODO review 6 - add to callback?
  useEffect(() => {
    if (ref.current) {
      if (resizeObserver === null) {
        debugLog('setting up resize observer');
        const initialHeight = Math.floor(ref.current.clientHeight);
        debugLog('initial height', initialHeight);

        // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
        setTableHeight(initialHeight);

        window.addEventListener('resize', () => {
          const newHeight = Math.floor(ref.current?.clientHeight ?? 400);
          debugLog('new height', newHeight);
          // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
          setTableHeight(newHeight);
        });

        //when the grandparent element is resized (the section), we need to recompute the table's height
        const tmpObserver = new ResizeObserver(entries => {
          for (const entry of entries) {
            const elementSize = entry.contentBoxSize[0].blockSize;
            const newSize = Math.floor(elementSize);

            if (newSize !== tableHeight) {
              const newComputedHeight = computeTableSize(newSize);
              debugLog(
                'resize observer, new size should be %d, setting new height to %d',
                newSize,
                newComputedHeight,
              );
              // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
              setTableHeight(newComputedHeight);
            }
          }
        });

        const parent = ref.current.parentElement;
        if (parent !== null) {
          const grandParent = parent.parentElement;
          if (grandParent !== null) {
            tmpObserver.observe(grandParent);
          }
        }

        // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
        setResizeObserver(tmpObserver);
      }
    }
  }, [ref, resizeObserver, tableHeight]);

  const { data: redistributionData, isFetching } = useGetCalculationCategoryPairingsQuery(params);

  useEffect(() => {
    //resetting paging after category change
    setParams(prevValue => {
      const newParams = {
        ...prevValue,
        categoryId,
        skip: 0,
        top: CALCULATION_REDISTRIBUTION_PAGE_SIZE,
      };

      const conditions: Condition[] = prevValue.conditions ? Array.from(prevValue.conditions) : [];
      if (redistributionFilterBrandId) {
        if (!(redistributionFilterBrandId === NO_VALUE)) {
          removeBrandCondition(conditions);

          //add new brand condition
          conditions.push({
            fieldName: 'Product/Brand/Id',
            operation: Operation.Eq,
            value: redistributionFilterBrandId,
          });
        }
      } else {
        removeBrandCondition(conditions);
      }

      if (redistributionFilterOutOfStock) {
        if (
          conditions.findIndex(item => item.fieldName === 'TotalAvailableSupplyOfTargetSku') === -1
        ) {
          debugLog('Out of stock filter is on and not present, adding to conditions');
          conditions.push({
            fieldName: 'TotalAvailableSupplyOfTargetSku',
            operation: Operation.Eq,
            value: 0,
          });
        }
      } else {
        debugLog('Out of stock filter is off, removing from conditions');
        removeOutOfStockFilter(conditions);
      }

      if (redistributionFilterForced) {
        if (
          conditions.findIndex(item => item.fieldName === 'ForcedQuantitySendToRedistribution') ===
          -1
        ) {
          debugLog('Forced redistribution filter is on and not present, adding to conditions');
          conditions.push({
            fieldName: 'ForcedQuantitySendToRedistribution',
            operation: Operation.Gt,
            value: 0,
          });
        }
      } else {
        removeForcedRedistributionFilter(conditions);
      }

      if (conditions.length > 0) {
        newParams.conditions = conditions;
      } else {
        delete newParams.conditions;
      }

      debugLog('setting params %s', JSON.stringify(newParams));
      return newParams;
    });
  }, [
    categoryId,
    redistributionFilterBrandId,
    redistributionFilterForced,
    redistributionFilterOutOfStock,
  ]);

  useEffect(() => {
    debugLog(`setting pairing count to ${redistributionData?.totalCount ?? 0}`);
    dispatch(setRedistributionPairingCount(redistributionData?.totalCount ?? 0));
  }, [dispatch, redistributionData]);

  const getRowKey = useCallback(
    (row: SkuRedistributionResponse) => `${row.sourceSkuId}#${row.targetSkuId}`,
    [],
  );

  const selectRow = useCallback(
    (record: SkuRedistributionResponse) => {
      dispatch(selectSKURedistribution(record));
      setSearchParams({ p: `${record.sourceSkuId}-${record.targetSkuId}` });
    },
    [dispatch, setSearchParams],
  );

  const rowConfig = useCallback(
    (record: SkuRedistributionResponse) => {
      return {
        onClick: (event: React.MouseEvent) => {
          event.stopPropagation();
          selectRow(record);
        },
      };
    },
    [selectRow],
  );

  const rowClassProvider = useCallback(
    (record: SkuRedistributionResponse) => {
      let result = '';

      if (selectedItem && getRowKey(selectedItem) === getRowKey(record)) {
        result = 'selected-row';
      }

      return result;
    },
    [getRowKey, selectedItem],
  );

  return (
    <Panel $height="100%" ref={ref}>
      <SkuRedistributionTable
        id="SkuRedistribution"
        columns={columns}
        height={`${tableHeight - TABLE_OFFSET}px`}
        setParams={setParams}
        searchValue={searchQuery}
        dataSource={redistributionData?.data ?? emptyData}
        loading={isFetching}
        rowKey={getRowKey}
        onRow={rowConfig}
        selectedRow={selectedItem}
        onSelectRow={selectRow}
        rowClassName={rowClassProvider}
      />
    </Panel>
  );
};

export default CalculationRedistributionBody;
