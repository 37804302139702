/**
 * Compares the numeric system id and text customer id and returns true
 * if the customer id is a numeric and is equal to the system id while ignoring
 * zeroes at the start of customer id.
 * @param systemId numeric id
 * @param customerId text id
 */
export const isSameId = (systemId: number, customerId: string | null | undefined): boolean => {
  if (customerId) {
    const customerIdAsNumber = parseInt(customerId, 10);

    // Check if the parsed integer is not NaN and is equal to the first argument
    return !isNaN(customerIdAsNumber) && customerIdAsNumber === systemId;
  } else {
    return false;
  }
};

/**
 * Check the date's validity.
 * Valid date is any date other than start of the epoch.
 * @param date
 */
export const isValidDate = (date: Date | undefined): boolean => {
  let result = false;
  if (date) {
    const epochStart = new Date(0);
    result = date.getTime() !== epochStart.getTime();
  }

  return result;
};
