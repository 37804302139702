import { useGetApplicationConfigurationQuery } from '../apis/apiApplication';
import { useMemo } from 'react';
import { ApplicationConfigurationResponse } from '@ydistri/api-sdk';

export function useApplicationConfiguration(): ApplicationConfigurationResponse {
  const { data: applicationConfiguration } = useGetApplicationConfigurationQuery();

  return useMemo(() => {
    if (!applicationConfiguration)
      return {
        applicationDate: null,
        lastSaleTransactionDate: null,
        finishExecutionWithinDays: 14,
        mdbProductIdAutoIncrement: 'INT',
        mdbWarehouseIdAutoIncrement: 'INT',
        isPickAndPackEnabled: false,
      };

    return applicationConfiguration;
  }, [applicationConfiguration]);
}
