import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { ScopeConfiguration } from './scopeLib';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetDepartmentsQuery, useGetStoresQuery } from '../../../apis/apiLists';
import { getStoreDepartmentConfigurationColumns } from './storeDepartmentColumns';
import { useApplicationData } from '../../../hooks/useApplicationData';
import { SimpleTable } from '../../CalculationDetail/Statistics/components/SimpleTable';
import { TablePaginationConfig } from 'antd/lib';
import { matrixKey } from './scopeLib2';
import { useCalculationConfiguration } from './useCalculationConfiguration';
import { useInjectLocalFilters } from '@ydistri/ds';
import { ApplicationDataType } from '@ydistri/utils';

export interface StoreDepartmentTableRow extends ScopeConfiguration {
  storeId: number;
  storeCustomerId: string;
  storeCode: string;
  storeName: string;
  departmentId: number;
  departmentName: string;
  storeTypeName: string;
  regionName: string;
}

interface StoreDepartmentTableProps {}

const StoreDepartmentTable: React.FC<StoreDepartmentTableProps> = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const {
    [ApplicationDataType.DEPARTMENTS]: hasDepartments,
    [ApplicationDataType.STORE_CODE]: hasStoreCodes,
  } = useApplicationData();

  const { data: availableStores, isFetching: isFetchingStores } =
    useGetStoresQuery(templateOrCalculation);
  const { data: availableDepartments, isFetching: isFetchingDepartments } =
    useGetDepartmentsQuery(templateOrCalculation);

  const storeDepartmentMatrix = useSelector(
    (state: ReduxState) => state.scope.exceptionOverlapData.matrix,
  );

  const { useClosing, useSuperTarget } = useCalculationConfiguration();

  const loading = isFetchingStores || isFetchingDepartments;

  const data: StoreDepartmentTableRow[] = useMemo(() => {
    const response: StoreDepartmentTableRow[] = [];
    if (loading) return response;

    for (const key in storeDepartmentMatrix) {
      if (Object.hasOwn(storeDepartmentMatrix, key)) {
        const sd = storeDepartmentMatrix[key];
        if (sd) {
          const storeObject = availableStores?.object[sd.storeId];
          response.push({
            ...sd.final,
            storeId: sd.storeId,
            storeCustomerId: storeObject?.customerStoreId ?? '',
            storeCode: storeObject?.code ?? '',
            storeName:
              availableStores?.object[sd.storeId]?.name ?? ` - Unknown store ${sd.storeId} -`,
            departmentId: sd.departmentId,
            departmentName:
              availableDepartments?.object[sd.departmentId]?.name ??
              ` - Unknown store ${sd.departmentId} -`,
            storeTypeName:
              availableStores?.object[sd.storeId]?.storeType?.name ?? ' - Unknown store type -',
            regionName: availableStores?.object[sd.storeId]?.region?.name ?? ' - Unknown region -',
          });
        }
      }
    }

    return response;
  }, [loading, storeDepartmentMatrix, availableDepartments?.object, availableStores?.object]);

  const columns = useMemo(
    () =>
      getStoreDepartmentConfigurationColumns(
        useClosing,
        useSuperTarget,
        hasDepartments,
        hasStoreCodes,
      ),
    [useClosing, useSuperTarget, hasDepartments, hasStoreCodes],
  );

  const filterableColumns = useInjectLocalFilters(columns);

  const pagination: TablePaginationConfig = useMemo(
    () => ({
      showTotal: (total, range) =>
        `Showing ${range[0]}-${range[1]} of ${total} store${
          hasDepartments ? ' department' : ''
        }s available`,
      pageSize: 15,
      showSizeChanger: false,
      size: 'default',
      position: ['bottomLeft'],
    }),
    [hasDepartments],
  );

  const rowKeySelector = useCallback(
    (r: StoreDepartmentTableRow) => matrixKey(r.storeId, r.departmentId),
    [],
  );

  return (
    <SimpleTable<StoreDepartmentTableRow>
      showBorder={true}
      showHeader={true}
      id="store-configurations"
      loading={loading}
      columns={filterableColumns}
      dataSource={data}
      size="small"
      pagination={pagination}
      rowKey={rowKeySelector}
    />
  );
};

export default StoreDepartmentTable;
