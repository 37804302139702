import React, { CSSProperties, useCallback } from 'react';
import { Popover, Tag } from 'antd';
import { useEntityListData } from './useEntityListData';
import { ConfigurationRuleScopeEntityType } from '@ydistri/api-sdk';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { styled } from 'styled-components';
import { computeRemSize } from '@ydistri/ds';
import { MdWarning } from 'react-icons/md';

const MarginedTag = styled(Tag)<{ $cursor?: CSSProperties['cursor'] }>`
  margin-top: ${computeRemSize(3)};
  margin-bottom: ${computeRemSize(3)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  ${p => p.$cursor && `cursor: ${p.$cursor};`}
`;

interface MultiselectTagProps extends Partial<CustomTagProps> {
  entityType: ConfigurationRuleScopeEntityType;
  entityId: number;
  error?: React.ReactNode;
  onClick?: () => void;
}

const MultiselectTag: React.FC<MultiselectTagProps> = ({
  entityType,
  closable,
  onClose,
  onClick,
  entityId,
  error,
  disabled,
}) => {
  const { data } = useEntityListData(entityType);

  const onPreventMouseDown = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (!disabled && onClick) onClick();
      return undefined;
    },
    [disabled, onClick],
  );

  const label = data?.object[entityId]?.name;

  if (!data) return null;

  if (error) {
    return (
      <Popover content={error}>
        <MarginedTag
          color="red"
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          $cursor={disabled ? undefined : 'pointer'}
        >
          {label}
          <MdWarning size={16} />
        </MarginedTag>
      </Popover>
    );
  }

  return (
    <MarginedTag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose}>
      {label}
    </MarginedTag>
  );
};

export default MultiselectTag;
