import { Divider, Popover, Space } from 'antd';
import { Label, Row } from '@ydistri/ds';
import { CalculationFileResponse } from '@ydistri/api-sdk';
import React from 'react';
import CalculationFile from '../../CalculationFile';

interface ExportCalculationFilesActionProps {
  files: CalculationFileResponse[];
  onFileClick?: (file: CalculationFileResponse) => void;
}

const splitter = <Divider type="vertical" />;

const ExportCalculationFilesAction: React.FC<ExportCalculationFilesActionProps> = ({
  files,
  onFileClick,
}) => {
  if (files.length === 0) return null;

  return (
    <Row $gap="1rem">
      <Popover
        content="Contains full redistribution, not only selected category or filtered rows"
        placement="topRight"
      >
        <Label>Full export:</Label>
      </Popover>
      <Space split={splitter}>
        {files.map(file => (
          <CalculationFile key={file.id} file={file} onFileClick={onFileClick} />
        ))}
      </Space>
    </Row>
  );
};

export default ExportCalculationFilesAction;
