import { ActionsBar } from '@ydistri/ds';
import React from 'react';
import ShowActualDataAction from './actions/ShowActualDataAction';
import ToggleCumulativeForecast from './actions/ToggleCumulativeForecast';
import ToggleShowSkuInfoAction from './actions/ToggleShowSkuInfoAction';

interface SkuDetailActionsProps {
  onShowActualData: () => void;
  showActualData: boolean;
  onToggleCumulativeForecast: () => void;
  cumulativeForecast: boolean;
  onToggleShowSkuInfo: () => void;
  showSkuInfo: boolean;
}

const SkuDetailActions: React.FC<SkuDetailActionsProps> = ({
  onShowActualData,
  showActualData,
  onToggleCumulativeForecast,
  cumulativeForecast,
  onToggleShowSkuInfo,
  showSkuInfo,
}) => {
  return (
    <ActionsBar wrap={false}>
      <ToggleShowSkuInfoAction onClick={onToggleShowSkuInfo} checked={showSkuInfo} size="xSmall" />
      <ToggleCumulativeForecast
        onClick={onToggleCumulativeForecast}
        checked={cumulativeForecast}
        size="xSmall"
      />
      <ShowActualDataAction onClick={onShowActualData} checked={showActualData} size="xSmall" />
    </ActionsBar>
  );
};

export default SkuDetailActions;
