import { ActionsBar, Section } from '@ydistri/ds';
import React, { useCallback, useMemo } from 'react';
import {
  resetTargetListItems,
  setActiveScreenType,
  setProductItemsContent,
} from '../../targetListAdministrationSlice';
import { useDispatch, useSelector } from 'react-redux';

import { getSectionTitle } from '../../targetListsLib';
import TargetListEditor from './TargetListEditor';
import { ActiveScreenType } from '../../../common/administrationItemsLib';
import ExitEditModeButton from '../../../common/buttons/ExitEditModeButton';
import { ReduxState } from '../../../../../store';

const TargetListEditModeSection: React.FC = () => {
  const selectedTargetList = useSelector(
    (state: ReduxState) => state.targetListAdministration.selectedTargetList,
  );

  const dispatch = useDispatch();

  const onExitEditMode = useCallback(() => {
    dispatch(setActiveScreenType(ActiveScreenType.DETAIL));
    dispatch(setProductItemsContent(''));
    dispatch(resetTargetListItems());
  }, [dispatch]);

  const headerActions = useMemo(() => {
    return (
      <ActionsBar>
        <ExitEditModeButton onClick={onExitEditMode} />
      </ActionsBar>
    );
  }, [onExitEditMode]);

  if (!selectedTargetList) {
    return null;
  } else {
    return (
      <Section
        header={`${getSectionTitle(selectedTargetList)} - Edit`}
        headerActions={headerActions}
      >
        <TargetListEditor />
      </Section>
    );
  }
};

export default TargetListEditModeSection;
