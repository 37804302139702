import React from 'react';
import { StatisticsModalType } from '../statisticsLib';
import { styled, css } from 'styled-components';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Space } from 'antd';
import { useCurrency } from '../../../../hooks/useCurrency';
import { ChartPropertyType } from './statisticsModalChartLib';
import { Column, computeRemSize } from '@ydistri/ds';

export const StyledTooltip = styled.div`
  transition: 0.5s all cubic-bezier(0.17, 1.09, 0.78, 0.87);
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 ${computeRemSize(2)} ${computeRemSize(10)} #888;
  padding: 0.8rem;
  text-align: center;
  font-size: ${computeRemSize(12)};
  opacity: 1;
  z-index: 100;
  position: fixed;
  pointer-events: none;
`;

export const TooltipTitle = styled.span`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${p => p.theme.colors.primary};
  min-width: 13rem;
`;

export const TooltipDepartmentTitle = styled.span`
  font-size: 1.25rem;
  min-width: 13rem;
`;

export const TooltipContent = styled.div`
  font-size: 1rem;
`;

export const TTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TRow = styled.tr<{ $highlighted?: boolean }>`
  ${p =>
    p.$highlighted &&
    css`
      font-weight: bold;

      & td {
        font-size: 1.25rem;
        background-color: ${p.theme.colors.hover};
      }
    `}

  td {
    text-align: left;
  }

  td:nth-child(2) {
    font-size: 1.25rem;
    padding-left: 1rem;
    text-align: right;
  }
`;

interface StatisticsModalChartTooltipProps<U extends StatisticsModalType> {
  modalType: U;
  showDepartments: boolean;
  chartValueType: ChartPropertyType<U>;
  customTooltipId: string;
}

const StatisticsModalChartTooltip = <U extends StatisticsModalType>({
  modalType,
  showDepartments,
  chartValueType,
  customTooltipId,
}: StatisticsModalChartTooltipProps<U>): React.ReactElement => {
  const currencyId = useCurrency();
  const showSourceStore =
    modalType === StatisticsModalType.ROUTE || modalType === StatisticsModalType.SOURCE_STORE;
  const showTargetStore =
    modalType === StatisticsModalType.ROUTE || modalType === StatisticsModalType.TARGET_STORE;

  const showFilledStockoutValue =
    modalType === StatisticsModalType.ROUTE || modalType === StatisticsModalType.TARGET_STORE;
  const showTargetStoreCount = modalType === StatisticsModalType.SOURCE_STORE;
  const showSourceStoreCount = modalType === StatisticsModalType.TARGET_STORE;

  return (
    <StyledTooltip id={customTooltipId}>
      <Space direction="vertical" size="large">
        {showDepartments && (
          <Column>
            <span>Department</span>
            <TooltipDepartmentTitle id={`${customTooltipId}-department.name`}>
              Department
            </TooltipDepartmentTitle>
          </Column>
        )}
        <Space align="center">
          {showSourceStore && (
            <Column>
              <span>Source store</span>
              <TooltipTitle id={`${customTooltipId}-sourceStore.name`}>Source store</TooltipTitle>
            </Column>
          )}
          {showSourceStore && showTargetStore && <AiOutlineArrowRight size={computeRemSize(24)} />}
          {showTargetStore && (
            <Column>
              <span>Target store</span>
              <TooltipTitle id={`${customTooltipId}-targetStore.name`}>Target store</TooltipTitle>
            </Column>
          )}
        </Space>
        <TooltipContent id={`${customTooltipId}-content`}>
          <TTable>
            <tbody>
              <TRow $highlighted={chartValueType === 'value'}>
                <td>Value:</td>
                <td id={`${customTooltipId}-value`}></td>
                <td>{currencyId}</td>
              </TRow>
              <TRow $highlighted={chartValueType === 'quantity'}>
                <td>Quantity:</td>
                <td id={`${customTooltipId}-quantity`}></td>
                <td>MU</td>
              </TRow>
              <TRow $highlighted={chartValueType === 'productCount'}>
                <td>Product count:</td>
                <td id={`${customTooltipId}-productCount`}></td>
                <td></td>
              </TRow>
              {showFilledStockoutValue && (
                <TRow $highlighted={chartValueType === 'filledStockoutValue'}>
                  <td>Replenished out-of-stock products:</td>
                  <td id={`${customTooltipId}-filledStockoutValue`}></td>
                  <td>{currencyId}</td>
                </TRow>
              )}
              {showTargetStoreCount && (
                <TRow $highlighted={chartValueType === 'targetStoreCount'}>
                  <td>Target store count:</td>
                  <td id={`${customTooltipId}-targetStoreCount`}></td>
                  <td></td>
                </TRow>
              )}
              {showSourceStoreCount && (
                <TRow $highlighted={chartValueType === 'sourceStoreCount'}>
                  <td>Source store count:</td>
                  <td id={`${customTooltipId}-sourceStoreCount`}></td>
                  <td></td>
                </TRow>
              )}
            </tbody>
          </TTable>
        </TooltipContent>
      </Space>
    </StyledTooltip>
  );
};

export default StatisticsModalChartTooltip;
