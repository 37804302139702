import React, { useCallback, useMemo } from 'react';
import { Popover } from 'antd';
import { TargetListVersionConfigurationType } from '@ydistri/api-sdk';
import { IconType } from 'react-icons';
import { targetListTypeOptions } from '../targetListsTypes';
import { TbTruckDelivery } from 'react-icons/tb';
import { BsHouseUp } from 'react-icons/bs';
import { RowCentered } from '@ydistri/ds';

type IconSize = 'small' | 'large';

interface TargetListTypeIndicatorProps {
  type: TargetListVersionConfigurationType;
  size?: IconSize;
  displayLabel?: boolean;
}

const TargetListTypeIndicator: React.FC<TargetListTypeIndicatorProps> = ({
  type,
  size = 'large',
  displayLabel = false,
}) => {
  const generateIcon = useCallback(
    // eslint-disable-next-line @typescript-eslint/naming-convention
    (Icon: IconType, size = 'small', tooltip?: string) => {
      const sizeInPixels = size === 'small' ? 20 : 24;
      const tmpIcon = <Icon size={sizeInPixels} color="#878585" />;
      if (tooltip) {
        return (
          <Popover content={tooltip}>
            <RowCentered $gap="1rem">
              {tmpIcon} {displayLabel ? tooltip : ''}
            </RowCentered>
          </Popover>
        );
      } else {
        return tmpIcon;
      }
    },
    [displayLabel],
  );

  const typeDescription = useMemo(() => {
    return targetListTypeOptions.find(option => option.value === type)?.label;
  }, [type]);

  let icon: IconType | null = null;
  switch (type) {
    case TargetListVersionConfigurationType.ReceiveDefinedQuantity:
      icon = TbTruckDelivery;
      break;
    case TargetListVersionConfigurationType.TopUpDefinedQuantity:
      icon = BsHouseUp;
      break;
    case TargetListVersionConfigurationType.NotApplicable: {
      return null;
    }
  }

  return generateIcon(icon, size, typeDescription);
};

export default TargetListTypeIndicator;
