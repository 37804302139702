import React, { useCallback, useMemo } from 'react';
import GenericSelector from '../../../../../../components/global/GenericSelector/GenericSelector';

export interface StoreOption {
  id: number;
  name: string;
  code: string | null;
  customerStoreId: string | null;
}

interface StoreSelectorProps {
  options: StoreOption[];
  onChange?: (storeId: number) => void;
  value?: number;
  noSelectionLabel?: string;
}

const StoreSelector: React.FC<StoreSelectorProps> = ({
  options,
  onChange,
  value,
  noSelectionLabel,
}) => {
  const storeOptions = useMemo(() => {
    return options.map(option => ({
      id: option.id,
      label: option.customerStoreId ? `${option.customerStoreId} - ${option.name}` : option.name,
    }));
  }, [options]);

  const changeHandler = useCallback(
    (value: number) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  return (
    <GenericSelector
      options={storeOptions}
      label="Store"
      onChange={changeHandler}
      value={value}
      noSelectionLabel={noSelectionLabel}
      showSearch={true}
    />
  );
};

export default StoreSelector;
