import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSubpage } from '../../../routes/routerSlice';
import { ConfigurationSubpage } from '../Configuration';
import RegionFlowTypeSection from './RegionFlowTypeSection/RegionFlowTypeSection';
import RegionGroupConfigurations from './RegionGroupConfigurations/RegionGroupConfigurations';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetCalculationConfigurationQuery } from '../../../apis/apiGlobalConfiguration';
import { RegionFlowType } from '@ydistri/api-sdk';
import { MainContent } from '../../../../../../../libs/ds/src/pages/PageLayout/pageLayoutComponents';

const ConfigurationRegions: React.FC = () => {
  const dispatch = useDispatch();
  const templateOrCalculation = useTemplateOrCalculation();

  const { data: calculationConfiguration } =
    useGetCalculationConfigurationQuery(templateOrCalculation);

  useEffect(() => {
    dispatch(setSubpage(ConfigurationSubpage.REGIONS));
  });

  return (
    <MainContent>
      <RegionFlowTypeSection />
      {calculationConfiguration?.regionFlowTypeId === RegionFlowType.CustomRedistribution && (
        <RegionGroupConfigurations />
      )}
    </MainContent>
  );
};

export default ConfigurationRegions;
