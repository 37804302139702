import { useMemo } from 'react';
import { useGetApplicationConfigurationQuery } from '../apis/apiApplication';
import { FilterComponent, YColumnsType } from '@ydistri/ds';
import { capitalizeFirstLetter } from '@ydistri/utils';
import { FilterDropdownProps } from 'antd/lib/table/interface';

type AllowedColumnType = 'Product' | 'Store';

interface BaseColumnDefinitionFunctionProps {
  sortedAndFilteredWithApi: boolean;
  dataIndex: string[];
  apiColumnNamePrefix: string;
  title: string;
  configurationValue: string;
  customerIdColumnName: string;
}

const baseColumnDefinitionFunction = <T,>(
  props: BaseColumnDefinitionFunctionProps,
): YColumnsType<T> => {
  const {
    sortedAndFilteredWithApi,
    dataIndex,
    apiColumnNamePrefix,
    title,
    configurationValue,
    customerIdColumnName,
  } = props;

  const finalDataIndex = [...dataIndex, configurationValue === 'INT' ? 'id' : customerIdColumnName];

  const key = 'key' + finalDataIndex.map(s => capitalizeFirstLetter(s)).join('');

  const sorterString = (a: T, b: T) => {
    // @ts-expect-error complex types...
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    const el1 = a[finalDataIndex[0]]?.[finalDataIndex[1]];
    // @ts-expect-error complex types...
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    const el2 = b[finalDataIndex[0]]?.[finalDataIndex[1]];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
    return el1?.localeCompare(el2);
  };

  const sorterNumber = (a: T, b: T) => {
    // @ts-expect-error complex types...
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    const el1 = a[finalDataIndex[0]]?.[finalDataIndex[1]];
    // @ts-expect-error complex types...
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    const el2 = b[finalDataIndex[0]]?.[finalDataIndex[1]];
    return el1 - el2;
  };

  const correctSorter = configurationValue === 'INT' ? sorterNumber : sorterString;

  const getFilterComponent = (props: FilterDropdownProps) => {
    console.log('props', props, key);
    return (
      <FilterComponent
        columnKey={key}
        filter={props}
        displayName={title}
        itemType={configurationValue === 'INT' ? 'number' : 'text'}
      />
    );
  };

  return {
    title,
    key,
    dataIndex: finalDataIndex,
    sortDirections: ['ascend', 'descend'],
    sorter: sortedAndFilteredWithApi ? true : correctSorter,
    apiColumnName: `${apiColumnNamePrefix.length > 0 ? apiColumnNamePrefix + '/' : ''}${
      configurationValue === 'INT' ? 'Id' : capitalizeFirstLetter(customerIdColumnName)
    }`,
    apiFilterable: true,
    apiFilterType: configurationValue === 'INT' ? 'number' : 'text',
    filterDropdown: sortedAndFilteredWithApi ? undefined : getFilterComponent,
  };
};

export interface ColumnDefinitionByTypeProps {
  type: AllowedColumnType;
  sortedAndFilteredWithApi?: boolean;
}

export type ColumnDefinitionFunction<T> = (
  dataIndex?: string[],
  apiColumnNamePrefix?: string,
  title?: string,
  customerIdColumnName?: string,
) => YColumnsType<T>;

const useColumnDefinitionByType = <T,>({
  type,
  sortedAndFilteredWithApi = true,
}: ColumnDefinitionByTypeProps): ColumnDefinitionFunction<T> => {
  const { data: applicationConfiguration } = useGetApplicationConfigurationQuery();

  const configurationValue = useMemo(() => {
    if (type === 'Product') {
      return applicationConfiguration?.mdbProductIdAutoIncrement;
    } else if (type === 'Store') {
      return applicationConfiguration?.mdbWarehouseIdAutoIncrement;
    }
    return undefined;
  }, [
    applicationConfiguration?.mdbProductIdAutoIncrement,
    applicationConfiguration?.mdbWarehouseIdAutoIncrement,
    type,
  ]);

  return useMemo(() => {
    switch (type) {
      case 'Product':
        return (
          dataIndex: string[] = ['product'],
          apiColumnNamePrefix: string = 'Product',
          title = 'Product ID',
          customerIdColumnName = 'customerId',
        ) =>
          baseColumnDefinitionFunction({
            sortedAndFilteredWithApi,
            dataIndex,
            apiColumnNamePrefix,
            title,
            configurationValue: configurationValue ?? '',
            customerIdColumnName,
          });

      case 'Store':
        return (
          dataIndex: string[] = ['store'],
          apiColumnNamePrefix: string = 'Store',
          title = 'Store ID',
          customerIdColumnName = 'customerStoreId',
        ) =>
          baseColumnDefinitionFunction({
            sortedAndFilteredWithApi,
            dataIndex,
            apiColumnNamePrefix,
            title,
            configurationValue: configurationValue ?? '',
            customerIdColumnName,
          });
    }
  }, [configurationValue, sortedAndFilteredWithApi, type]);
};

export default useColumnDefinitionByType;
