import { Row, computeRemSize, SectionHeader, ReadOnlyItem } from '@ydistri/ds';
import React, { useMemo } from 'react';
import { styled } from 'styled-components';
import { JSX } from 'react/jsx-runtime';
import { isSameId } from '../../../lib/utils/utils';

const HeaderWrapper = styled(Row)`
  min-height: ${computeRemSize(26)};
`;

interface CalculationRedistributionSectionHeaderProps {
  prefix?: string;
  content: string;
  customerId?: string | null;
  systemId?: number;
  skuId?: number;
  code?: string;
}

const CalculationRedistributionSectionHeader: React.FC<
  CalculationRedistributionSectionHeaderProps
> = ({ content, prefix, systemId, customerId, skuId, code }) => {
  const renderIds = () => {
    const result: JSX.Element[] = [];
    if (customerId && systemId) {
      result.push(<ReadOnlyItem key="id" label="ID" value={customerId} />);
      if (!isSameId(systemId, customerId)) {
        result.push(<ReadOnlyItem key="system-id" label="System ID" value={systemId.toString()} />);
      }
    }

    if (code) {
      result.push(<ReadOnlyItem key="code" label="Code" value={code} />);
    }

    if (skuId) {
      result.push(<ReadOnlyItem key="sku-id" label="Sku ID" value={skuId.toString()} />);
    }

    return result;
  };

  const sectionTitle = useMemo(
    () => (prefix ? `${prefix}: ${content}` : content),
    [prefix, content],
  );

  return (
    <HeaderWrapper $gap="1rem" $alignItems="center">
      <SectionHeader>{sectionTitle}</SectionHeader>
      {renderIds()}
    </HeaderWrapper>
  );
};

export default CalculationRedistributionSectionHeader;
