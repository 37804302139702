import React, { useEffect } from 'react';
import CategoryTree from '../../../components/global/CategoryTree/CategoryTree';
import { useDispatch } from 'react-redux';
import { setSubpage } from '../../../routes/routerSlice';
import { ConfigurationSubpage } from '../Configuration';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import SegmentPotentialDefinition from './SegmentPotentialDefinition/SegmentPotentialDefinition';

import { CalculationDetailSubpages } from '../../Calculations/calculationsLib';
import { Row } from '@ydistri/ds';
import { MainContent } from '../../../../../../../libs/ds/src/pages/PageLayout/pageLayoutComponents';

const ConfigurationCategories: React.FC = () => {
  const dispatch = useDispatch();
  const templateOrCalculation = useTemplateOrCalculation();

  useEffect(() => {
    dispatch(
      setSubpage(
        templateOrCalculation.type === 'Template'
          ? ConfigurationSubpage.CATEGORIES
          : CalculationDetailSubpages.CATEGORIES,
      ),
    );
  }, [dispatch, templateOrCalculation]);

  return (
    <>
      <CategoryTree />
      <MainContent>
        <Row $flexWrap="nowrap">
          <SegmentPotentialDefinition />
        </Row>
      </MainContent>
    </>
  );
};

export default ConfigurationCategories;
