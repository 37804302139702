import React, { useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelectedTemplateId } from '../../hooks/useSelectedTemplateId';
import ConfigurationHeader from './ConfigurationHeader';
import { RoutingPage, setPage } from '../../routes/routerSlice';
import { API } from '../../swagger/collections';
import ApplicationClosedOverlay from '../../components/global/ContentOverlay/ApplicationClosedOverlay';
import TemplateStatusOverlay from '../../components/global/ContentOverlay/TemplateStatusOverlay';
import { MainContentWrapper } from '../../../../../../libs/ds/src/pages/PageLayout/pageLayoutComponents';

export enum ConfigurationSubpage {
  GLOBAL = 'global',
  CATEGORIES = 'categories',
  REGIONS = 'regions',
  STORES_OLD = 'stores-old',
  STORES = 'stores',
  SKUCLASSES = 'skuclasses',
  PRODUCTLISTS = 'productlists',
  BRANDS = 'brands',
  ADVANCED = 'advanced',
  DRILLDOWN = 'drilldown',
}

const Configuration: React.FC = () => {
  const dispatch = useDispatch();
  const { projectShortName, templateId } = useParams();
  const selectedTemplateId = useSelectedTemplateId();

  useEffect(() => {
    dispatch(setPage(RoutingPage.CONFIGURATION));
  }, [dispatch]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (!API.instance.defaults.headers.common['Template-Id']) return <div>Loading...</div>;

  if (!templateId && selectedTemplateId && projectShortName) {
    return <Navigate to={`/${projectShortName}/configuration/${selectedTemplateId}`} />;
  }

  return (
    <>
      <ConfigurationHeader />
      <MainContentWrapper>
        <Outlet />
        <TemplateStatusOverlay />
      </MainContentWrapper>
      <ApplicationClosedOverlay />
    </>
  );
};

export default Configuration;
