import { CalculationTableParams } from '../../calculationsTypes';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  computeRemSize,
  InfiniteScrollParams,
  InfiniteScrollTable,
  Panel,
  Row,
  YColumnsType,
} from '@ydistri/ds';
import { generatePath, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  useDeleteCalculationMutation,
  useGetCalculationsQuery,
  useLoadCalculationToTemplateMutation,
} from '../../apiCalculations';
import { ReduxState } from '../../../../store';
import { useUser } from '../../../../hooks/useUser';
import {
  CalculationStatus,
  CalculationType,
  CalculationWithOverviewResponse,
  SortDirection,
} from '@ydistri/api-sdk';
import CalculationTableText from '../row/CalculationTableText';
import CalculationFinalRedistribution from '../row/CalculationFinalRedistribution';
import CalculationStatusComponent from '../row/CalculationStatusComponent';
import CalculationOwner from '../row/CalculationOwner';
import { format } from 'date-fns';
import { ExpandableConfig } from 'antd/es/table/interface';
import CalculationStatisticsController from '../statistics/CalculationStatisticsController';
import { addToast } from '../../../../store/toastSlice';
import {
  resetSelectedRowKeys,
  setCalculationsTableParams,
  toggleExpandedRowKey,
} from '../../calculationsSlice';
import { ROUTES } from '../../../../components/menu/menuLeftItemTemplate';
import {
  CalculationDetailSubpages,
  getRedistributionValues,
  isCalculationInTemporaryStatus,
} from '../../calculationsLib';
import { Overlay } from '../../../../components/global/Overlay';
import OverlayMessage, {
  OverlayMessageIcon,
} from '../../../../components/global/ContentOverlay/OverlayComponents';
import { styled } from 'styled-components';
import { useCurrency } from '../../../../hooks/useCurrency';
import LinkCell from './LinkCell';
import { createDebugLog } from '../../../../lib/utils/logging';
import CalculationRowTitle from '../row/CalculationRowTitle';
import { v4 as uuidv4 } from 'uuid';
import { produce } from 'immer';
import { useSelectedProjectCode } from '../../../../hooks/useSelectedProjectCode';
import CalculationCheckbox, {
  CALCULATION_CHECKBOX_CLASS_NAME,
  CALCULATION_HAS_SELECTED_CLASS_NAME,
} from './CalculationCheckbox';

const loadingOverlayContent = <OverlayMessage title="Loading" icon={OverlayMessageIcon.SPINNER} />;

const extractCellTitleParts = (
  calculation: CalculationExtended,
): {
  title: string;
  description: string | null;
  status: CalculationStatus;
  type: CalculationType;
  isPap: boolean;
  finishExecutionUntil?: string | null;
} => {
  return {
    title: calculation.data.title,
    description: calculation.data.description ?? null,
    status: calculation.data.status,
    type: calculation.data.type,
    isPap: calculation.data.isPap,
    finishExecutionUntil: calculation.data.finishExecutionUntil,
  };
};

const CalculationsTableStyled = styled(
  InfiniteScrollTable<CalculationExtended, InfiniteScrollParams>,
)`
  .ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 0;
  }

  .ant-table-cell.final-redistribution-cell {
    position: relative;
    padding: 0;
  }

  .calculationRowIncomplete {
    cursor: not-allowed;
  }

  .calculationRow {
    &.privateRow {
      background-color: #fffcf6;

      &:hover > td {
        background: #ffefcf !important;
      }
    }

    &.productionRow {
      background-color: #fafdfa;

      &:hover > td {
        background: #dff1df !important;
      }
    }

    &.expandedRow {
      background-color: #f9f9f9;

      & > td {
        border-bottom: 0;
      }

      &:hover > td {
        background: #f9f9f9 !important;
      }
    }

    &.selectedRow {
      background: #ffefcf !important;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }

      &:hover > td {
        background: #ffefcf !important;
      }
    }

    td.final-redistribution-cell {
      padding: 0;
    }
  }

  tr.ant-table-expanded-row {
    background-color: #fefefe;
  }

  .ant-table-middle .ant-table-tbody > tr:not(:first-child) > td {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .${CALCULATION_CHECKBOX_CLASS_NAME} {
    opacity: 0;
  }

  .${CALCULATION_HAS_SELECTED_CLASS_NAME} {
    .${CALCULATION_CHECKBOX_CLASS_NAME} {
      opacity: 1;
    }
  }

  .ant-table-cell-row-hover {
    .${CALCULATION_CHECKBOX_CLASS_NAME} {
      opacity: 1;
    }
  }
`;

export const CALCULATIONS_BLOCK_SIZE: number = 60;
export const CALCULATIONS_TABLE_ID = 'calculations-table';

const debugLog = createDebugLog('Calculations', 'CalculationsTable');
const debugLogRender = createDebugLog('Calculations', 'CalculationsTable', 'render');

interface CalculationExtended {
  data: CalculationWithOverviewResponse;
  isExpanded: boolean;
  isBeingDeleted: boolean;
}

const CalculationsTable: React.FC = () => {
  const renderingId = uuidv4();
  const startTime = new Date();

  debugLogRender(`${renderingId}: ${startTime.toISOString()} | START rendering`);

  const { projectShortName } = useParams();
  const projectCode = useSelectedProjectCode();

  const dispatch = useDispatch();
  const [deleteCalculation] = useDeleteCalculationMutation();
  const calculationFilters = useSelector(
    (state: ReduxState) => state.calculations.calculationFilters,
  );
  const expandedRowKeys = useSelector((state: ReduxState) => state.calculations.expandedRowKeys);

  const user = useUser();
  const [isBusy, setIsBusy] = useState(true);

  useEffect(() => {
    debugLog('useEffect: projectCode changed');

    //eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
    setIsBusy(true);
    setCalculations([]);
    setParams(prevParams => {
      return {
        ...prevParams,
        skip: 0,
        top: CALCULATIONS_BLOCK_SIZE,
        projectCode: projectCode,
      };
    });

    return () => {
      dispatch(resetSelectedRowKeys());
    };
  }, [dispatch, projectCode]);

  const currency = useCurrency();

  const [, loadCalculationToTemplateStatus] = useLoadCalculationToTemplateMutation({
    fixedCacheKey: 'loading-to-template',
  });

  const toggleExpandedRowHandler = useCallback(
    (rowKey: number) => {
      dispatch(toggleExpandedRowKey(rowKey));
    },
    [dispatch],
  );

  const deleteCalculationHandler = useCallback(
    (calculation: CalculationWithOverviewResponse) => {
      setCalculations(
        produce(draft => {
          const deletedCalculationIndex = draft.findIndex(
            tmpCalculation => tmpCalculation.data.id === calculation.id,
          );
          if (deletedCalculationIndex !== -1) {
            draft[deletedCalculationIndex].isBeingDeleted = true;
          }
        }),
      );
      +debugLog(`Deleting calculation ${calculation.id}`);
      deleteCalculation(calculation.id)
        .unwrap()
        .then(() => {
          //remove the calculation from our data
          setCalculations(
            produce(draft => {
              const deletedCalculationIndex = draft.findIndex(
                tmpCalculation => tmpCalculation.data.id === calculation.id,
              );
              if (deletedCalculationIndex !== -1) {
                draft.splice(deletedCalculationIndex, 1);
              }
            }),
          );

          setParams(prevParams => {
            const loadItemsCount =
              (prevParams.top ?? CALCULATIONS_BLOCK_SIZE) + (prevParams.skip ?? 0);
            const newParams = {
              ...prevParams,
              skip: 0,
              top: loadItemsCount,
            };
            debugLog('Calculation deleted, setting new params', newParams);
            return newParams;
          });
          dispatch(addToast({ message: 'Calculation deleted' }));
        })
        .catch(() => {
          dispatch(addToast({ message: 'Calculation was not deleted', isError: true }));
        });
    },
    [deleteCalculation, dispatch],
  );

  const urlProvider = useCallback(
    (calculation: CalculationWithOverviewResponse) => {
      if (calculation.status === CalculationStatus.Completed) {
        return generatePath(ROUTES.calculationDetail, {
          projectShortName: projectShortName ?? '',
          calculationId: `${calculation.id}`,
          subpage: CalculationDetailSubpages.REDISTRIBUTION,
          slug: '1',
        });
      }
    },
    [projectShortName],
  );

  const columns: YColumnsType<CalculationExtended>[] = useMemo(() => {
    return [
      {
        title: 'ID',
        key: 'id',
        width: computeRemSize(90),
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        apiColumnName: 'Id',
        apiFilterable: true,
        apiFilterType: 'number',
        render: (calculation: CalculationExtended) => (
          <LinkCell<CalculationWithOverviewResponse>
            record={calculation.data}
            urlProvider={urlProvider}
          >
            <Row $gap="0.5rem" $alignItems="center">
              <CalculationTableText>{calculation.data.id}</CalculationTableText>
              <CalculationCheckbox
                calculationId={calculation.data.id}
                disabled={
                  calculation.data.type === CalculationType.Production ||
                  isCalculationInTemporaryStatus(calculation.data.status)
                }
              />
            </Row>
          </LinkCell>
        ),
      },
      {
        title: 'Title',
        key: 'title',
        dataIndex: ['data', 'title'],
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        apiColumnName: 'Title',
        apiFilterable: true,
        apiFilterType: 'text',
        shouldCellUpdate: (record, prevRecord) =>
          !shallowEqual(extractCellTitleParts(record), extractCellTitleParts(prevRecord)),
        render: (title: string, calculation: CalculationExtended) => {
          return (
            <LinkCell<CalculationWithOverviewResponse>
              record={calculation.data}
              urlProvider={urlProvider}
            >
              <CalculationRowTitle
                calculation={calculation.data}
                onDelete={deleteCalculationHandler}
                onToggleStatistics={toggleExpandedRowHandler}
                disabled={calculation.isBeingDeleted}
              />
            </LinkCell>
          );
        },
      },
      {
        title: 'Final Redistribution',
        key: 'finalRedistribution',
        className: 'final-redistribution-cell',
        width: computeRemSize(360),
        shouldCellUpdate: (record, prevRecord) =>
          !shallowEqual(extractCellTitleParts(record), extractCellTitleParts(prevRecord)),
        render: (calculation: CalculationExtended) => {
          if (calculation.isBeingDeleted) {
            return <CalculationStatusComponent status={CalculationStatus.SoftDeleted} />;
          }

          if (calculation.data.status === CalculationStatus.Completed) {
            const redistributionValues = getRedistributionValues(
              calculation.data.rootCategoryRedistributions,
              calculation.data.type,
            );
            return (
              <LinkCell<CalculationWithOverviewResponse>
                record={calculation.data}
                urlProvider={urlProvider}
              >
                <CalculationFinalRedistribution
                  production={redistributionValues.isProduction}
                  totalValue={redistributionValues.totalValue}
                  deadStockValue={redistributionValues.deadStockValue}
                  slowMoverValue={redistributionValues.slowMoverValue}
                  fastMoverValue={redistributionValues.fastMoverValue}
                  calculationId={calculation.data.id}
                  currency={currency}
                />
              </LinkCell>
            );
          } else {
            return <CalculationStatusComponent status={calculation.data.status} />;
          }
        },
      },
      {
        title: 'Owner',
        key: 'owner',
        render: (calculation: CalculationExtended) => (
          <LinkCell<CalculationWithOverviewResponse>
            record={calculation.data}
            urlProvider={urlProvider}
          >
            <CalculationOwner fullName={calculation.data.owner.fullName} />
          </LinkCell>
        ),
        width: computeRemSize(200),
        shouldCellUpdate: () => false,
      },
      {
        title: 'Created',
        key: 'createdAt',
        width: '11rem',
        sorter: true,
        sortDirections: ['descend'],
        defaultSortOrder: 'descend',
        apiColumnName: 'Created',
        render: (calculation: CalculationExtended) => (
          <LinkCell<CalculationWithOverviewResponse>
            record={calculation.data}
            urlProvider={urlProvider}
          >
            <CalculationTableText>
              {format(new Date(calculation.data.created), 'd. M. yyyy HH:mm')}
            </CalculationTableText>
          </LinkCell>
        ),
        shouldCellUpdate: () => false,
      },
    ];
  }, [urlProvider, deleteCalculationHandler, toggleExpandedRowHandler, currency]);

  const [params, setParams] = useState<CalculationTableParams>(() => {
    return {
      skip: 0,
      top: CALCULATIONS_BLOCK_SIZE,
      sortings: [
        {
          fieldName: 'Created',
          direction: SortDirection.Desc,
        },
      ],
      includePrivateCalculations: calculationFilters.showAllPrivateCalculations,
      productionCalculationsOnly: calculationFilters.showProductionOnly,
      calculationOwner: user?.id,
      showOwnersCalculationsOnly: calculationFilters.showCurrentUserCalculationsOnly,
      projectCode: projectCode,
    };
  });

  useEffect(() => {
    dispatch(setCalculationsTableParams(params));
  }, [dispatch, params]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    debugLog('filters useEffect running');

    //eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
    setIsBusy(true);
    setParams(prevParams => {
      return {
        ...prevParams,
        skip: 0,
        top: CALCULATIONS_BLOCK_SIZE,
        includePrivateCalculations: calculationFilters.showAllPrivateCalculations,
        showOwnersCalculationsOnly: calculationFilters.showCurrentUserCalculationsOnly,
        productionCalculationsOnly: calculationFilters.showProductionOnly,
        calculationOwner: user?.id,
      };
    });
  }, [calculationFilters, user]);

  const ref = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState<number>(500);

  const { data } = useGetCalculationsQuery(params);

  const [calculations, setCalculations] = useState<CalculationExtended[]>([]);

  const expandableConfig: ExpandableConfig<CalculationExtended> = useMemo(
    () => ({
      expandedRowKeys: expandedRowKeys,
      expandedRowRender: record => (
        <CalculationStatisticsController calculationId={record.data.id} />
      ),
      showExpandColumn: false,
    }),
    [expandedRowKeys],
  );

  useEffect(() => {
    setCalculations(
      (data?.data ?? []).map(calculation => {
        return {
          data: calculation,
          isExpanded: expandedRowKeys.includes(calculation.id),
          isBeingDeleted: false,
        };
      }),
    );
    if (data?.data) {
      debugLog(`data changed to ${data.data.length} long array, setting isBusy to false`);
      // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
      setIsBusy(false);
    }
  }, [data, expandedRowKeys]);

  const resizeTable = useCallback(() => {
    const TABLE_OFFSET = 16;
    if (ref.current) {
      let tableHeaderSize: number = 37; //to compensate for the table's header as it is rendered outside the table's container
      const tableHeader = ref.current.querySelector('.ant-table-header');
      if (tableHeader) {
        tableHeaderSize = tableHeader.clientHeight;
      }

      const parentHeight = ref.current.clientHeight;
      const newHeight = parentHeight - (tableHeaderSize + TABLE_OFFSET);

      debugLog(
        'resizeTable: parentHeight: %d, newHeight: %d, headerSize: %d',
        parentHeight,
        newHeight,
        tableHeaderSize,
      );
      //initial height
      setTableHeight(newHeight);
    }
  }, [ref]);

  useEffect(() => {
    resizeTable();
    //compute height on window resize
    window.addEventListener('resize', resizeTable, { passive: true });
    return () => window.removeEventListener('resize', resizeTable);
  }, [resizeTable]);

  const getRowClassName = useCallback((calculation: CalculationExtended) => {
    if (calculation.isExpanded) {
      return 'calculationRow expandedRow';
    } else if (calculation.data.type === CalculationType.Production) {
      return `calculationRow productionRow`;
    } else if (calculation.data.type === CalculationType.Private) {
      return `calculationRow privateRow`;
    } else if (calculation.data.status !== CalculationStatus.Completed) {
      return 'calculationRowIncomplete';
    }
    return '';
  }, []);

  const rowKeyProvider = useCallback((calculation: CalculationExtended) => calculation.data.id, []);

  const result = (
    <Panel ref={ref} $grow={1} $noAutoHeight={true}>
      <Overlay
        active={isBusy || loadCalculationToTemplateStatus.isLoading}
        contentIsCentered={true}
        overlayContent={loadingOverlayContent}
      >
        <CalculationsTableStyled
          id={CALCULATIONS_TABLE_ID}
          columns={columns}
          height={`${tableHeight}px`}
          setParams={setParams}
          dataSource={calculations}
          rowKey={rowKeyProvider}
          expandable={expandableConfig}
          size="middle"
          rowClassName={getRowClassName}
          totalRowCount={data?.totalCount ?? 0}
        />
      </Overlay>
    </Panel>
  );

  const endTime = new Date();
  debugLogRender(
    `${renderingId}: ${endTime.toISOString()} | END returning result from render, isBusy: ${isBusy}; took ${
      endTime.getTime() - startTime.getTime()
    } ms`,
  );
  return result;
};

export default CalculationsTable;
