import React, { useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RoutingPage, setPage } from '../../routes/routerSlice';
import { useTemplateOrCalculation } from '../../hooks/useTemplateOrCalculation';
import { useGetCalculationQuery } from './apiCalculationDetail';
import CalculationHeader from './CalculationHeader';
import { Overlay } from '../../components/global/Overlay';
import { Section } from '@ydistri/ds';
import OverlayMessage, {
  OverlayMessageIcon,
} from '../../components/global/ContentOverlay/OverlayComponents';
import { Helmet } from 'react-helmet';
import { MainContentWrapper } from '../../../../../../libs/ds/src/pages/PageLayout/pageLayoutComponents';

const busyOverlayContent = <OverlayMessage title="Loading" icon={OverlayMessageIcon.SPINNER} />;

const CalculationDetail: React.FC = () => {
  const dispatch = useDispatch();
  const { calculationId, projectShortName } = useParams();
  const calculationData = useTemplateOrCalculation();
  const { data, isError, isLoading } = useGetCalculationQuery(calculationData.id);

  useEffect(() => {
    dispatch(setPage(RoutingPage.CALCULATION_DETAIL));
  }, [dispatch]);

  if (projectShortName) {
    if (!calculationId) {
      return <Navigate to={`/${projectShortName}/calculations`} />;
    } else {
      if (isError) {
        return <Navigate to={`/${projectShortName}/calculations`} />;
      } else if (isLoading) {
        return (
          <Section header="Calculation">
            <Overlay active={true} overlayContent={busyOverlayContent} contentIsCentered={true} />
          </Section>
        );
      } else {
        return (
          <>
            <Helmet title={`Calculation ${data?.id ?? ''}`} />
            <CalculationHeader />
            <MainContentWrapper>
              <Outlet />
            </MainContentWrapper>
          </>
        );
      }
    }
  }

  return null;
};

export default CalculationDetail;
