import {
  ApiOperationType,
  CalculationResponse,
  ProductListRequest,
  ProductListResponse,
  ProductResponse,
  SortDirection,
} from '@ydistri/api-sdk';
import { apiSlice, ErrorType } from '../../../apis/api';
import { ProductListsCollection } from '../../../swagger/collections';

import { signalrClient } from '../../../signalr/client';
import { ReduxState } from '../../../store';
import {
  refreshProductListContent,
  setSelectedProductList,
} from './productListAdministrationSlice';
import { addToast } from '../../../store/toastSlice';
import { SignalProjectConfigurationChanged } from '../common/administrationItemsTypes';
import {
  forceRefetchForInfiniteScroll,
  InfiniteScrollParams,
  mergeForInfiniteScroll,
  serializeQueryArgsForInfiniteScroll,
} from '@ydistri/ds';
import { getTags } from '../../../apis/apiLib';
import {
  SignalConfigurationChanged,
  SignalConfigurationsChanged,
} from '../../../signalr/signalrInterfaces';

const { TAGS, TAGS_ARRAY } = getTags('productLists');

export type ProductListSetArchiveRequest = Pick<
  ProductListResponse,
  'productListId' | 'isArchived'
>;

export type ProductListUpdateRequest = Pick<ProductListResponse, 'productListId' | 'name'>;

export interface ProductListItemsRequest extends InfiniteScrollParams {
  productListId: number;
}

export const apiProductLists = apiSlice
  .enhanceEndpoints({
    addTagTypes: TAGS_ARRAY,
  })
  .injectEndpoints({
    endpoints: builder => ({
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      getProductLists: builder.query<ProductListResponse[], void>({
        queryFn: async () =>
          (await ProductListsCollection.productListsList({ sortings: [{ fieldName: 'Name' }] }))
            .data,
        providesTags: [TAGS.productLists],
        async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved, getState, dispatch }) {
          const signalerListener = (signal: SignalProjectConfigurationChanged) => {
            if (signal.entityType !== 'ProductList') return;

            dispatch(apiProductLists.util.invalidateTags([TAGS.productLists]));

            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const state = getState() as ReduxState;

            //if the currently selected target list was deleted,
            //we need to clear the selected target list
            const selectedProductList = state.productListsAdministration.selectedProductList;
            if (selectedProductList && signal.entityId === selectedProductList.productListId) {
              if (signal.operationType === ApiOperationType.Delete) {
                dispatch(setSelectedProductList(undefined));
                dispatch(
                  addToast({
                    message: 'Product list has been deleted',
                  }),
                );
              }
            }

            if (signal.operationType === ApiOperationType.Patch) {
              dispatch(refreshProductListContent());
            }
          };

          try {
            await cacheDataLoaded;
            signalrClient.on('ProjectConfigurationChanged', signalerListener);

            cacheEntryRemoved.then(() => {
              signalrClient.off('ProjectConfigurationChanged', signalerListener);
            });
            await cacheEntryRemoved;
          } catch (error) {
            console.error(error);
          }
        },
      }),
      createProductList: builder.mutation<ProductListResponse, ProductListRequest>({
        queryFn: async body => {
          try {
            const response = await ProductListsCollection.createCustomProductList(body);
            return { data: response.data.data };
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const err = error as ErrorType;
            return { error: err };
          }
        },
        invalidatesTags: [TAGS.productLists],
      }),
      deleteProductList: builder.mutation<number, number>({
        queryFn: async (productListId: number) => {
          await ProductListsCollection.deleteCustomProductList(productListId);
          return { data: productListId };
        },
        invalidatesTags: [TAGS.productLists],
      }),
      setProductListArchived: builder.mutation<ProductListResponse, ProductListSetArchiveRequest>({
        queryFn: async (request: ProductListSetArchiveRequest) => {
          const response = await ProductListsCollection.updateCustomProductList(
            request.productListId,
            {
              isArchived: request.isArchived,
            },
          );
          return { data: response.data.data };
        },
        invalidatesTags: [TAGS.productLists],
      }),
      updateProductList: builder.mutation<ProductListResponse, ProductListUpdateRequest>({
        queryFn: async (request: ProductListUpdateRequest) => {
          const response = await ProductListsCollection.updateCustomProductList(
            request.productListId,
            {
              name: request.name,
            },
          );
          return { data: response.data.data };
        },
        invalidatesTags: [TAGS.productLists],
      }),
      getProductListItems: builder.query<ProductResponse[], ProductListItemsRequest>({
        queryFn: async args => {
          const query = {
            top: args.top,
            skip: args.skip,
            inlineCount: true,
            sortings: args.sortings,
            conditions: args.conditions,
            search: args.search,
          };
          const response = await ProductListsCollection.getProducts(args.productListId, query);
          return { data: response.data.data };
        },
        providesTags: [TAGS.productListItems],
        serializeQueryArgs: serializeQueryArgsForInfiniteScroll<ProductListItemsRequest>(),
        merge: mergeForInfiniteScroll<ProductResponse, InfiniteScrollParams>(),
        forceRefetch: forceRefetchForInfiniteScroll<InfiniteScrollParams | undefined>(),
      }),

      getProductListCalculations: builder.query<CalculationResponse[], number>({
        queryFn: async targetListId => {
          const response = await ProductListsCollection.getProductListCalculations(targetListId, {
            sortings: [
              {
                fieldName: 'Title',
                direction: SortDirection.Asc,
              },
            ],
          });

          return { data: response.data.data };
        },
        providesTags: [TAGS.productListCalculations],
        async onCacheEntryAdded(productListId, { cacheDataLoaded, cacheEntryRemoved, dispatch }) {
          try {
            await cacheDataLoaded;

            const listener = (signal: SignalConfigurationChanged) => {
              console.log('CONFIGURATION CHANGED', signal);
              if (signal.entityType !== 'ProductListConfiguration') return;
              if (signal.entityId !== productListId) return;
              if (signal.operationType === ApiOperationType.Patch) {
                dispatch(apiProductLists.util.invalidateTags([TAGS.productListCalculations]));
              }
            };

            const listenerForDeletes = (signals: SignalConfigurationsChanged[]) => {
              console.log('CONFIGURATIONS CHANGED', signals);
              let invalidate = false;

              signals.forEach(signal => {
                if (signal.entityType !== 'ProductListConfiguration') return;
                if (signal.entityId !== productListId) return;
                if (signal.operationType === ApiOperationType.Delete) {
                  invalidate = true;
                }
              });

              if (invalidate) {
                dispatch(apiProductLists.util.invalidateTags([TAGS.productListCalculations]));
              }
            };

            signalrClient.on('TemplateConfigurationChanged', listener);
            signalrClient.on('TemplateConfigurationsChanged', listenerForDeletes);

            cacheEntryRemoved.then(() => {
              signalrClient.off('TemplateConfigurationChanged', listener);
              signalrClient.off('TemplateConfigurationsChanged', listenerForDeletes);
            });
          } catch {
            // no-op in case cache entry was removed before data was loaded
          }
          await cacheEntryRemoved;
        },
      }),
    }),
  });

export const {
  useGetProductListsQuery,
  useCreateProductListMutation,
  useDeleteProductListMutation,
  useSetProductListArchivedMutation,
  useUpdateProductListMutation,
  useGetProductListItemsQuery,
  useGetProductListCalculationsQuery,
} = apiProductLists;
