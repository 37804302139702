import React, { PropsWithChildren } from 'react';
import { styled } from 'styled-components';
import { computeRemSize } from '@ydistri/ds';
import { Link } from 'react-router-dom';

export type LinkUrlProvider<T extends object> = (record: T) => string | undefined;

export interface LinkCellProps<T extends object> extends PropsWithChildren {
  record: T;
  urlProvider: LinkUrlProvider<T>;
  active?: boolean;
}

export const CellWrapper = styled.div`
  padding-top: ${computeRemSize(10)} !important;
  padding-right: ${computeRemSize(8)} !important;
  padding-bottom: ${computeRemSize(10)} !important;
  padding-left: ${computeRemSize(8)} !important;
`;

const RecordLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  /* to make the link stretch across the cell so the whole cell is clickable */
  display: block;
  width: 100%;
  height: 100%;
  padding-top: ${computeRemSize(10)} !important;
  padding-right: ${computeRemSize(8)} !important;
  padding-bottom: ${computeRemSize(10)} !important;
  padding-left: ${computeRemSize(8)} !important;

  &:hover {
    color: inherit;
  }
`;

const LinkCell = <T extends object>({
  record,
  children,
  urlProvider,
  active = true,
  ...rest // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
}: LinkCellProps<T>): React.ReactElement => {
  const url = urlProvider(record);
  if (url && active) {
    return (
      <RecordLink to={url} rel="noreferrer" {...rest}>
        {children}
      </RecordLink>
    );
  }

  return <CellWrapper>{children}</CellWrapper>;
};

export default LinkCell;
