import { styled } from 'styled-components';
import { computeRemSize } from '@ydistri/ds';
import { TChartLineInfo } from '../../../../../lib/charts/saleChartsTypes';

/**
 * Renders a graphical representation of the line based on the line info
 */
const ChartLineLegendGraphic = styled.div<{ $lineInfo: TChartLineInfo }>`
  width: ${computeRemSize(25)};
  height: ${props => {
    if (props.$lineInfo.fill !== false) {
      return computeRemSize(12);
    } else {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return computeRemSize((props.$lineInfo.borderWidth ?? 2) as number);
    }
  }};

  background-color: ${props => {
    if (props.$lineInfo.fill !== false) {
      return props.$lineInfo.backgroundColor?.toString() ?? 'black';
    } else {
      return props.$lineInfo.borderColor?.toString() ?? 'black';
    }
  }};

  ${props => {
    const info = props.$lineInfo;
    if (props.$lineInfo.fill !== false) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return `border: ${computeRemSize((info.borderWidth ?? 1) as number)} ${
        info.borderDash && info.borderDash.length > 0 ? 'dashed' : 'solid'
      } ${info.borderColor?.toString() ?? 'black'}`;
    } else {
      if (info.borderDash && info.borderDash.length > 0) {
        return `border-left: ${computeRemSize(8)} solid ${
          info.borderColor?.toString() ?? 'black'
        }; border-right: ${computeRemSize(8)} solid ${
          info.borderColor?.toString() ?? 'black'
        }; background-color: white;`;
      }
    }
  }};
`;

export default ChartLineLegendGraphic;
