import React, { useMemo } from 'react';
import { MultiselectCustomerPartialEntity } from './ConfigurationMultiselect';
import { ConfigurationRuleScopeEntityType, ConfigurationRuleScopeMode } from '@ydistri/api-sdk';
import { useScope } from './useScope';
import { Row, Text, Column } from '@ydistri/ds';
import MultiselectTag from './MultiselectTag';
import { useOpenScopeModal } from './useOpenScopeModal';
import { getScopeErrorText } from './scopeLib';
import { Popover } from 'antd';

interface ScopeViewModeProps {
  scopeId: number;
  entityType: ConfigurationRuleScopeEntityType;
  loading?: boolean;
  options: MultiselectCustomerPartialEntity[];
}

const visibleLimit = 5;

const ScopeViewMode: React.FC<ScopeViewModeProps> = ({ scopeId, entityType, options }) => {
  const scope = useScope(scopeId);
  const openModalHandler = useOpenScopeModal(scope);

  const selectedIds = useMemo(
    () => scope?.entities[entityType].selectedIds ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scope?.entities[entityType].selectedIds],
  );
  const selectionOption = scope?.entities[entityType].selectionOption;

  const selectedOptions = useMemo(() => {
    const idsToFilter: number[] = [];

    for (let i = 0; i < selectedIds.length && i < visibleLimit; i++) {
      idsToFilter.push(selectedIds[i]);
    }

    return idsToFilter.map(o => ({ id: o, error: !options.find(x => x.id === o) }));
  }, [selectedIds, options]);

  const popoverContent = useMemo(() => {
    return (
      <Row $gap="0.25rem" $flexWrap="wrap" $maxWidth="25rem">
        {selectedIds.map(o => (
          <MultiselectTag
            key={o}
            entityType={entityType}
            closable={false}
            entityId={o}
            error={getScopeErrorText(
              scope?.priority ?? 0,
              !options.find(x => x.id === o),
              entityType,
            )}
            onClick={openModalHandler}
          />
        ))}
      </Row>
    );
  }, [entityType, openModalHandler, options, scope?.priority, selectedIds]);

  if (selectionOption === ConfigurationRuleScopeMode.All) {
    // return scope?.priority === 0 ? 'All' : 'All in playground';
    return '';
  }

  return (
    <Column $gap="0.5rem">
      <Row $gap="0.25rem" $flexGrow={1} $flexWrap="wrap" $alignItems="center">
        {selectedIds.length === 0 && 'None'}
        {selectedOptions.map(o => (
          <MultiselectTag
            key={o.id}
            entityType={entityType}
            closable={false}
            entityId={o.id}
            error={getScopeErrorText(scope?.priority ?? 0, o.error, entityType)}
            onClick={openModalHandler}
          />
        ))}
        {selectedIds.length > visibleLimit && (
          <Popover content={popoverContent}>
            <Text $semiBold>+{selectedIds.length - selectedOptions.length} more</Text>
          </Popover>
        )}
      </Row>
    </Column>
  );
};

export default ScopeViewMode;
