import { apiSlice } from '../../apis/api';
import {
  CalculationSkuResponse,
  ForecastMonthlyOverviewResponse,
  SaleMonthlyOverviewResponse,
  SkuAttributeResponse,
} from '@ydistri/api-sdk';
import { CalculationsCollection, SkusCollection } from '../../swagger/collections';
import { getTags } from '../../apis/apiLib';

const { TAGS, TAGS_ARRAY } = getTags('calculationSkus');

interface CalculationCategorySkusRequest {
  calculationId: number;
  skuId: number;
}

interface SkuAttributesRequest extends CalculationCategorySkusRequest {
  retrieveActualData: boolean;
}

interface CalculationSkuMonthlySalesRequest extends CalculationCategorySkusRequest {
  /** Number of months to the past to retrieve data for starting with calculation's application date and adding six months after that. */
  monthsCount: number;
}

export const apiCalculationsSkus = apiSlice
  .enhanceEndpoints({ addTagTypes: TAGS_ARRAY })
  .injectEndpoints({
    endpoints: builder => ({
      getCalculationSkus: builder.query<CalculationSkuResponse, CalculationCategorySkusRequest>({
        queryFn: async args => {
          const response = await CalculationsCollection.getCalculationSkus(
            args.calculationId,
            args.skuId,
          );
          return { data: response.data.data };
        },
        providesTags: result => {
          if (result) {
            return [{ type: TAGS.calculationSkus, id: result.skuId }];
          } else {
            return [];
          }
        },
      }),
      getSkuAttributes: builder.query<SkuAttributeResponse[], SkuAttributesRequest>({
        queryFn: async args => {
          if (args.retrieveActualData) {
            const response = await SkusCollection.getSkuAttributes(args.skuId);
            return { data: response.data.data };
          } else {
            const response = await CalculationsCollection.getCalculationSkuAttributes(
              args.calculationId,
              args.skuId,
            );
            return { data: response.data.data };
          }
        },
        providesTags: result => {
          if (result) {
            return [{ type: TAGS.skuAttributes, id: 'LIST' }];
          } else {
            return [];
          }
        },
      }),
      getMonthlySales: builder.query<
        SaleMonthlyOverviewResponse[],
        CalculationSkuMonthlySalesRequest
      >({
        queryFn: async args => {
          const response = await CalculationsCollection.getCalculationSkuMonthlySaleWithMonthsRange(
            args.calculationId,
            args.skuId,
            args.monthsCount,
          );
          return { data: response.data.data };
        },
        providesTags: result => {
          if (result) {
            return [{ type: TAGS.calculationSkuMonthlySales, id: 'LIST' }];
          } else {
            return [];
          }
        },
      }),
      getMonthlyForecast: builder.query<
        ForecastMonthlyOverviewResponse[],
        CalculationCategorySkusRequest
      >({
        queryFn: async args => {
          const response = await CalculationsCollection.getCalculationSkuMonthlyForecast(
            args.calculationId,
            args.skuId,
          );
          return { data: response.data.data };
        },
        providesTags: result => {
          if (result) {
            return [{ type: TAGS.calculationSkuMonthlyForecast, id: 'LIST' }];
          } else {
            return [];
          }
        },
      }),
    }),
  });

export const {
  useGetCalculationSkusQuery,
  useGetSkuAttributesQuery,
  useGetMonthlySalesQuery,
  useGetMonthlyForecastQuery,
} = apiCalculationsSkus;
