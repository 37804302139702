import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAddTargetListForConfigurationMutation,
  useGetTargetListsForConfigurationQuery,
  useRemoveTargetListForConfigurationMutation,
  useReplaceTargetListForConfigurationMutation,
} from '../../apiTargetListConfiguration';
import { useGetTargetListsQuery } from '../../../ProjectAdministration/TargetLists/apiTargetLists';
import { Popover, Spin, TooltipProps } from 'antd';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import {
  ConfigurationFieldStatus,
  rtkQueryPatchStatusToConfigurationFieldStatus,
  SearchableSelectOptionType,
} from '@ydistri/utils';
import { computeRemSize, ConfigurationSelect } from '@ydistri/ds';
import { styled } from 'styled-components';
import useIsRootCategory from '../../../../hooks/useIsRootCategory';
import { TargetListResponse } from '@ydistri/api-sdk';
import type { RequestStatusFlags } from '@reduxjs/toolkit/src/query/core/apiState';

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

interface ConfigurationSelectTargetListProps {
  disabled?: boolean;
  width?: CSSProperties['width'];
}

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
`;

const OptionTargetListName = styled.div`
  width: ${computeRemSize(225)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const OptionTargetListItemCount = styled.div`
  font-style: italic;
`;

const targetListSelectOptionLabel = (
  t: TargetListResponse,
): SearchableSelectOptionType['label'] => (
  <OptionWrapper>
    <OptionTargetListName>
      {t.isArchived ? '[archived] ' : ''}
      {t.name ?? '-'}
    </OptionTargetListName>
    <OptionTargetListItemCount>
      ({t.skuCount > 0 ? `${t.skuCount} items` : `empty`})
    </OptionTargetListItemCount>
  </OptionWrapper>
);

const ConfigurationSelectTargetList: React.FC<ConfigurationSelectTargetListProps> = ({
  disabled = false,
  width,
}) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const rootCategory = useIsRootCategory();

  const { data: targetLists, isFetching: targetListsFetching } = useGetTargetListsQuery();
  const { data: targetListsForConfiguration, isFetching: targetListsForConfigurationFetching } =
    useGetTargetListsForConfigurationQuery(templateOrCalculation);
  const [removeTargetListForConfiguration, removeTargetListForConfigurationStatus] =
    useRemoveTargetListForConfigurationMutation();
  const [addTargetListForConfiguration, addTargetListForConfigurationStatus] =
    useAddTargetListForConfigurationMutation();
  const [replaceTargetListForConfiguration, replaceTargetListForConfigurationStatus] =
    useReplaceTargetListForConfigurationMutation();

  const [latestStatus, setLatestStatus] = useState<ConfigurationFieldStatus>(
    ConfigurationFieldStatus.DEFAULT,
  );

  const setStatusByLatestChange = useCallback((status: RequestStatusFlags) => {
    setLatestStatus(rtkQueryPatchStatusToConfigurationFieldStatus(status));
  }, []);

  useEffect(
    () => setStatusByLatestChange(removeTargetListForConfigurationStatus),
    [removeTargetListForConfigurationStatus, setStatusByLatestChange],
  );

  useEffect(
    () => setStatusByLatestChange(addTargetListForConfigurationStatus),
    [addTargetListForConfigurationStatus, setStatusByLatestChange],
  );

  useEffect(
    () => setStatusByLatestChange(replaceTargetListForConfigurationStatus),
    [replaceTargetListForConfigurationStatus, setStatusByLatestChange],
  );

  const options: SearchableSelectOptionType[] = useMemo(() => {
    if (targetLists) {
      const filtered =
        templateOrCalculation.type === 'Template'
          ? targetLists.filter(t =>
              templateOrCalculation.type === 'Template' ? !t.isArchived : true,
            )
          : targetLists;

      return filtered
        .map(t => ({
          value: t.targetListId,
          label: targetListSelectOptionLabel(t),
          searchableString: t.name ?? '-',
        }))
        .concat([
          {
            value: 0,
            label: <span> == None ==</span>,
            searchableString: ' == None ==',
          },
        ]);
    }
    return [];
  }, [targetLists, templateOrCalculation.type]);

  const targetListChanged = useCallback(
    (targetListId: number | null) => {
      //are we in the root category?

      if (targetListId !== null && targetListId !== 0) {
        //a target list was selected
        //if there was a previously selected target list, we need to replace it
        //replace is a two-step process - remove the old one and add the new one
        if (targetListsForConfiguration && targetListsForConfiguration.length > 0) {
          replaceTargetListForConfiguration({
            templateId: templateOrCalculation.id,
            targetListId: targetListId,
          });
        } else {
          //there was no previously selected target list, we need to add the new one
          //not set as the backend allows more than one target list per configuration
          addTargetListForConfiguration({
            templateId: templateOrCalculation.id,
            targetListId: targetListId,
          });
        }
      } else {
        //the 'None' option was selected - we need to remove the previously selected target list
        if (targetListsForConfiguration && targetListsForConfiguration.length > 0) {
          removeTargetListForConfiguration({
            templateId: templateOrCalculation.id,
            targetListId: targetListsForConfiguration[0].targetListId,
          });
        }
      }
    },
    [
      targetListsForConfiguration,
      replaceTargetListForConfiguration,
      templateOrCalculation.id,
      addTargetListForConfiguration,
      removeTargetListForConfiguration,
    ],
  );

  const isSelected = targetListsForConfiguration?.[0]?.targetListId ?? 0;

  const isLoading = useMemo(
    () => targetListsFetching || targetListsForConfigurationFetching,
    [targetListsForConfigurationFetching, targetListsFetching],
  );

  const tooltipTrigger: TooltipProps['trigger'] = useMemo(() => {
    if (!rootCategory) return ['hover'];
    return [];
  }, [rootCategory]);

  return (
    <Spin spinning={isLoading}>
      <SelectWrapper>
        <Popover content="Editable only in root category" trigger={tooltipTrigger}>
          <div>
            <ConfigurationSelect<number>
              value={isSelected}
              onChange={targetListChanged}
              status={latestStatus}
              options={options}
              showArrow={false}
              disabled={disabled || !rootCategory}
              width={width}
              popupMatchSelectWidth={false}
            />
          </div>
        </Popover>
      </SelectWrapper>
    </Spin>
  );
};

export default ConfigurationSelectTargetList;
