import React, { useCallback } from 'react';
import { ApiAction } from '@ydistri/api-sdk';
import { Button, Row, Text } from '@ydistri/ds';
import { useResetCategoryConfigurationsMutation } from '../../../components/global/CategoryTree/apiCategoryTree';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { addToast } from '../../../store/toastSlice';
import { useDispatch } from 'react-redux';

interface ResetFormCategoryProps {
  categoryId: number;
}

const ResetFormCategory: React.FC<ResetFormCategoryProps> = ({ categoryId }) => {
  const dispatch = useDispatch();
  const templateOrCalculation = useTemplateOrCalculation();

  const [resetCategoryConfigurations, { isLoading: isResetting }] =
    useResetCategoryConfigurationsMutation();

  const handleClear = useCallback(() => {
    resetCategoryConfigurations({ categoryId, apiAction: ApiAction.DeleteSingleCategoryDeviations })
      .then(() => {
        dispatch(addToast({ message: `Category was successfully reset` }));
      })
      .catch(() => {
        dispatch(addToast({ message: `There was an error resetting category`, isError: true }));
      });
  }, [categoryId, dispatch, resetCategoryConfigurations]);

  if (templateOrCalculation.type !== 'Template') return null;

  return (
    <Row $gap="0.5rem">
      <Text $bold>Clear all exceptions </Text>
      <Button
        $sizeVariant="xSmall"
        $colorVariant="ghostDanger"
        onClick={handleClear}
        loading={isResetting}
      >
        Clear
      </Button>
    </Row>
  );
};

export default ResetFormCategory;
