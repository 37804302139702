/* eslint-disable @typescript-eslint/naming-convention */
// noinspection JSUnusedGlobalSymbols

import * as Sentry from '@sentry/react';
import {
  ApplicationConfigurations,
  ApplicationData,
  Brands,
  Calculations,
  Categories,
  CategoryConfigurationDefinitions,
  CurrentSetup,
  EntityLists,
  Hints,
  HttpClient,
  Notifications,
  ProductLists,
  Products,
  Regions,
  Settings,
  SkuClasses,
  Skus,
  TargetLists,
  Templates,
  Triggers,
  Warehouses,
  WarehouseTypes,
} from '@ydistri/api-sdk';
import Auth from '../auth/Auth';
import { getApiUrl } from '../helpers';
import { runInterceptor } from '@ydistri/utils';
import { getItem, LSKey, setItem, setSelectedTemplateForProject } from '../store/localStore';

export const API = new HttpClient({
  baseURL: getApiUrl(),
});

API.instance.interceptors.request.use(
  req => runInterceptor(req),
  error => {
    // eslint-disable-next-line no-console,@typescript-eslint/no-unsafe-member-access
    console.log(error.response);
    Sentry.captureException(error);
    return Promise.reject(error);
  },
);

API.instance.interceptors.response.use(
  req => req,
  error => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (error?.response && error.response.status === 401) {
      new Auth().signOut();
      console.log('LOGOUT - INTERCEPTOR');
      //window.location.reload();
    }
    return Promise.reject(error);
  },
);

export let urlProjectShortName: undefined | string;
export let urlProjectCode: undefined | string;
export let urlSelectedTemplateId: undefined | number;
export const pathSplit = window.location.pathname.split('/');

if (pathSplit.length > 1 && !['login', 'register', 'account'].includes(pathSplit[1])) {
  urlProjectShortName = pathSplit[1];
  urlProjectCode = getItem(LSKey.PROJECTS_BY_SHORT_NAME)?.[urlProjectShortName]?.id;
  if (urlProjectCode) {
    API.instance.defaults.headers.common['Project-Code'] = urlProjectCode;
    setItem(LSKey.SELECTED_PROJECT_CODE, urlProjectCode);
  }
}

if (pathSplit.length > 3 && pathSplit[2] === 'configuration') {
  urlSelectedTemplateId = parseInt(pathSplit[3]);
  if (urlSelectedTemplateId && urlProjectCode) {
    API.instance.defaults.headers.common['Template-Id'] = urlSelectedTemplateId;
    setSelectedTemplateForProject(urlProjectCode, urlSelectedTemplateId);
    setItem(LSKey.SELECTED_TEMPLATE_ID, urlSelectedTemplateId);
  }
}

export const ApplicationConfigurationsCollection = new ApplicationConfigurations(API);
export const ApplicationDataCollection = new ApplicationData(API);
export const CalculationsCollection = new Calculations(API);
export const CategoriesCollection = new Categories(API);
export const CategoryConfigurationDefinitionsCollection = new CategoryConfigurationDefinitions(API);
export const CurrentSetupCollection = new CurrentSetup(API);
export const HintsCollection = new Hints(API);
export const NotificationsCollection = new Notifications(API);
export const ProductsCollection = new Products(API);
export const ProductListsCollection = new ProductLists(API);
export const RegionsCollection = new Regions(API);
export const SettingsCollection = new Settings(API);
export const SkuClassesCollection = new SkuClasses(API);
export const SkusCollection = new Skus(API);
export const TemplatesCollection = new Templates(API);
export const TriggersCollection = new Triggers(API);
export const StoresCollection = new Warehouses(API);
export const WarehouseTypesCollection = new WarehouseTypes(API);
export const TargetListsCollections = new TargetLists(API);
export const BrandsCollection = new Brands(API);
export const EntityListsCollection = new EntityLists(API);
