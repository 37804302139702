import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useCurrency } from '../../../hooks/useCurrency';
import { ApplicationDataType } from '@ydistri/utils';
import { useApplicationData } from '../../../hooks/useApplicationData';
import { useGetStoresPickingResultsQuery } from './apiCalculationPickingResults';
import React, { useCallback, useMemo } from 'react';
import {
  evaluationsRowClassName,
  getBasePickResultsColumns,
  SourceStorePickingResultExtendedResponse,
} from './pickingResultsLib';
import { Column, computeRemSize, tableScroll } from '@ydistri/ds';
import TableStorePickingResultsExport from './TableStorePickingResultsExport';
import { SimpleTable } from '../Statistics/components/SimpleTable';
import { ExpandableConfig } from 'antd/es/table/interface';
import TargetStorePickingResultsDetailTable from './TargetStorePickingResultsDetailTable';
import LoadingIndicator from '../../../components/global/LoadingIndicator/LoadingIndicator';

const tableMarginTop = { marginTop: '-28px' };

/**
 * Body of the section with the table of store picking results.
 */
const PickingResultsTable: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const currencyId = useCurrency() ?? '...';
  const { [ApplicationDataType.STORE_CODE]: hasStoreCodes } = useApplicationData();

  const { data, isFetching: isFetchingStatistics } = useGetStoresPickingResultsQuery({
    calculationId: templateOrCalculation.id,
  });

  const tableColumns = useMemo(
    () => getBasePickResultsColumns(currencyId, hasStoreCodes),
    [currencyId, hasStoreCodes],
  );

  const expandableConfig: ExpandableConfig<SourceStorePickingResultExtendedResponse> = useMemo(
    () => ({
      expandedRowRender: record => {
        if (!('store' in record)) return null;
        return <TargetStorePickingResultsDetailTable sourceStoreId={record.store.id} />;
      },
    }),
    [],
  );

  const rowKeyProvider = useCallback(
    (record: SourceStorePickingResultExtendedResponse) =>
      'store' in record ? record.store.id : '-invalid-',
    [],
  );

  if (isFetchingStatistics) {
    return <LoadingIndicator />;
  } else {
    if (data) {
      return (
        <Column $alignItems="flex-end" $padding={1} style={tableMarginTop}>
          <TableStorePickingResultsExport
            title={`Store picking results (#${templateOrCalculation.id})`}
            dataSource={data}
            columns={tableColumns}
          />
          <SimpleTable<SourceStorePickingResultExtendedResponse>
            id="store-picking-results"
            scroll={tableScroll}
            dataSource={data}
            columns={tableColumns}
            loading={isFetchingStatistics}
            showHeader={true}
            height={computeRemSize(480)}
            rowKey={rowKeyProvider}
            rowClassName={evaluationsRowClassName}
            expandable={expandableConfig}
          />
        </Column>
      );
    }
  }
};

export default PickingResultsTable;
