import React, { useCallback, useMemo, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import { styled } from 'styled-components';
import { computeRemSize } from '@ydistri/ds';
import skuSaleGraphTooltip from '../../../screens/CalculationDetail/Redistribution/SkuSaleChart/SkuSaleChartTooltip';
import { format } from 'date-fns';
import annotationPlugin, { AnnotationOptions } from 'chartjs-plugin-annotation';
import { useCurrency } from '../../../hooks/useCurrency';
import {
  TLineType,
  TooltipCallback,
  TParsedMonthlyTransactions,
} from '../../../lib/charts/saleChartsTypes';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  annotationPlugin,
  Filler,
);

interface ChartProps {
  width?: number;
  height?: number;
}

/**
 * Wrapper for the Line chart to set the width and height of the chart
 */
const Chart = styled.div<ChartProps>`
  display: flex;
  flex-direction: column;

  ${({ width }) => width && `width: ${computeRemSize(width)};`}
  ${({ height }) => height && `height: ${computeRemSize(height)};`}
`;

interface SaleChartProps {
  chartData: ChartData<'line'>;
  transactions: TParsedMonthlyTransactions[];
  currentDate: Date;
  vat?: number;
  width?: number;
  height?: number;
  lines: TLineType[];
  animate?: boolean;
  showAverageValue?: boolean;
  tooltipCallback?: TooltipCallback;
}

/**
 * Line chart to display sales data
 * @param chartData data to display
 * @param width width of the chart
 * @param height height of the chart
 * @param currentDate this date will be marked on the chart with a vertical line
 * @param transactions transactions to display in the tooltip
 * @param lines definition of the lines
 * @param animate if true, the chart will be animated when data changes, defaults to false
 * @param vat vat to incorporate in the tooltip
 * @param showAverageValue
 * @param tooltipCallback
 * @constructor
 */
const SaleChart: React.FC<SaleChartProps> = ({
  chartData,
  width,
  height,
  currentDate,
  transactions,
  lines,
  animate = false,
  vat = 0,
  showAverageValue = false,
  tooltipCallback = skuSaleGraphTooltip,
}) => {
  const chartRef = useRef<ChartJS<'line'>>(null);
  const currency = useCurrency();

  const timeScaleTicksCallback = useCallback(
    (value: string | number, index: number) => {
      const newDateLabel = new Date(value);
      let label = format(newDateLabel, 'MMM yyyy');

      if (newDateLabel > currentDate) {
        const tickCounter = 6 - (transactions.length - (index + 1));

        if (currentDate.getDate() > 14 && tickCounter === 0) {
          label = '';
        } else {
          //last 6 months shown as "Outlook X"
          label = `OTLK ${tickCounter}`;
        }
      }
      return label;
    },
    [currentDate, transactions],
  );

  const markers: AnnotationOptions<'line'>[] = useMemo(
    () => [
      {
        scaleID: 'x',
        type: 'line',
        mode: 'vertical',
        borderColor: 'rgb(255, 99, 132)',
        borderWidth: 2,
        value: currentDate.getTime(),
      },
    ],
    [currentDate],
  );

  const options = useMemo((): ChartOptions<'line'> => {
    const defaultOptions: ChartOptions<'line'> = {
      plugins: {
        legend: { display: false },
        annotation: {
          annotations: markers,
        },
        tooltip: {
          enabled: false,
          external: ({ tooltip }) => {
            if (chartRef.current) {
              tooltipCallback(
                tooltip,
                lines,
                chartData.datasets,
                transactions,
                currentDate,
                chartRef,
                currency,
                vat,
                showAverageValue,
              );
            }
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          stacked: false,
          beginAtZero: true,
          ticks: {
            stepSize: 1,
          },
        },
        x: {
          type: 'time',
          time: {
            unit: 'month',
            displayFormats: {
              day: 'MMM YYYY',
            },
          },
          ticks: {
            source: 'data',
            autoSkip: false,
            callback: timeScaleTicksCallback,
          },
        },
      },
    };

    if (!animate) {
      defaultOptions.animation = false;
    }

    return defaultOptions;
  }, [
    animate,
    chartData.datasets,
    currency,
    currentDate,
    lines,
    markers,
    showAverageValue,
    timeScaleTicksCallback,
    tooltipCallback,
    transactions,
    vat,
  ]);

  return (
    <Chart className="chart-container" width={width} height={height} data-type="chart-container">
      <Line data={chartData} options={options} ref={chartRef} />
    </Chart>
  );
};

export default SaleChart;
