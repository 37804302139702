import { useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { MultiselectCustomerPartialEntity } from './ConfigurationMultiselect';
import { ConfigurationRuleScopeEntityType, ConfigurationRuleScopeMode } from '@ydistri/api-sdk';
import { useScope } from './useScope';
import { useMainScope } from './useMainScope';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { ViewMode } from './scopeLib';
import {
  useGetDepartmentsQuery,
  useGetRegionsQuery,
  useGetStoresQuery,
  useGetStoreTypesQuery,
} from '../../../apis/apiLists';

interface UserScopeOptionsDataResponse {
  regionOptions: MultiselectCustomerPartialEntity[];
  storeOptions: MultiselectCustomerPartialEntity[];
  storeTypeOptions: MultiselectCustomerPartialEntity[];
  departmentOptions: MultiselectCustomerPartialEntity[];
  loading: boolean;
}

export function useScopeOptionsData(
  scopeId: number,
  viewMode: ViewMode,
): UserScopeOptionsDataResponse {
  const mainScope = useMainScope();
  const scopeById = useScope(scopeId);
  const templateOrCalculation = useTemplateOrCalculation();
  const modalScope = useSelector((state: ReduxState) => state.scope.modalScope);

  const { data: availableRegions, isFetching: isFetchingRegions } =
    useGetRegionsQuery(templateOrCalculation);
  const { data: availableStores, isFetching: isFetchingStores } =
    useGetStoresQuery(templateOrCalculation);
  const { data: availableStoreTypes, isFetching: isFetchingStoreTypes } =
    useGetStoreTypesQuery(templateOrCalculation);
  const { data: availableDepartments, isFetching: isFetchingDepartments } =
    useGetDepartmentsQuery(templateOrCalculation);

  const scope = useMemo(
    () =>
      [ViewMode.ADJUST_SCOPE_MODAL, ViewMode.NEW_EXCEPTION_MODAL].includes(viewMode)
        ? modalScope
        : scopeById,
    [modalScope, scopeById, viewMode],
  );

  const pgRSelectionOption =
    mainScope?.entities[ConfigurationRuleScopeEntityType.Regions].selectionOption;
  const pgRSelectedIds = mainScope?.entities[ConfigurationRuleScopeEntityType.Regions].selectedIds;

  const pgStSelectionOption =
    mainScope?.entities[ConfigurationRuleScopeEntityType.StoreTypes].selectionOption;
  const pgStSelectedIds =
    mainScope?.entities[ConfigurationRuleScopeEntityType.StoreTypes].selectedIds;

  const pgSSelectionOption =
    mainScope?.entities[ConfigurationRuleScopeEntityType.Stores].selectionOption;
  const pgSSelectedIds = mainScope?.entities[ConfigurationRuleScopeEntityType.Stores].selectedIds;

  const pgDSelectionOption =
    mainScope?.entities[ConfigurationRuleScopeEntityType.Departments].selectionOption;
  const pgDSelectedIds =
    mainScope?.entities[ConfigurationRuleScopeEntityType.Departments].selectedIds;

  const curRSelectionOption =
    scope?.entities[ConfigurationRuleScopeEntityType.Regions].selectionOption;
  const curRSelectedIds = scope?.entities[ConfigurationRuleScopeEntityType.Regions].selectedIds;

  const curStSelectionOption =
    scope?.entities[ConfigurationRuleScopeEntityType.StoreTypes].selectionOption;
  const curStSelectedIds = scope?.entities[ConfigurationRuleScopeEntityType.StoreTypes].selectedIds;

  const isFetching =
    isFetchingRegions || isFetchingStores || isFetchingStoreTypes || isFetchingDepartments;

  //if not in playground, apply playground regions filters (All / specified / excluding filter)
  const finalRegions = useMemo(() => {
    let regions = availableRegions?.array ?? [];

    if (pgRSelectionOption) {
      switch (pgRSelectionOption) {
        case ConfigurationRuleScopeMode.All:
          break;
        case ConfigurationRuleScopeMode.Specified:
          regions = regions.filter(d => (pgRSelectedIds ?? []).includes(d.id));
          break;
        case ConfigurationRuleScopeMode.AllExcept:
          regions = regions.filter(d => !(pgRSelectedIds ?? []).includes(d.id));
          break;
      }
    }
    return regions;
  }, [availableRegions?.array, pgRSelectionOption, pgRSelectedIds]);

  const finalRegionsForStores = useMemo(() => {
    let regions = finalRegions;

    if (curRSelectionOption) {
      switch (curRSelectionOption) {
        case ConfigurationRuleScopeMode.All:
          break;
        case ConfigurationRuleScopeMode.Specified:
          regions = regions.filter(d => (curRSelectedIds ?? []).includes(d.id));
          break;
        case ConfigurationRuleScopeMode.AllExcept:
          regions = regions.filter(d => !(curRSelectedIds ?? []).includes(d.id));
          break;
      }
    }

    return regions;
  }, [finalRegions, curRSelectionOption, curRSelectedIds]);

  const regionEnabledStores = useMemo(() => {
    return (availableStores?.array ?? []).filter(
      s => finalRegionsForStores?.find(rc => rc.id === s.region?.id) !== undefined,
    );
  }, [availableStores?.array, finalRegionsForStores]);

  //if not in playground, apply playground store type filters (All / specified / excluding filter)
  const finalStoreTypes = useMemo(() => {
    let storeTypes = (availableStoreTypes?.array ?? []).filter(st =>
      regionEnabledStores.some(s => s.storeType?.id === st.id),
    );

    if (scope?.priority !== 0 /*&& pgStSelectedIds*/ && pgStSelectionOption) {
      switch (pgStSelectionOption) {
        case ConfigurationRuleScopeMode.All:
          break;
        case ConfigurationRuleScopeMode.Specified:
          storeTypes = storeTypes.filter(st => (pgStSelectedIds ?? []).includes(st.id));
          break;
        case ConfigurationRuleScopeMode.AllExcept:
          storeTypes = storeTypes.filter(st => !(pgStSelectedIds ?? []).includes(st.id));
          break;
      }
    }

    return storeTypes;
  }, [
    regionEnabledStores,
    availableStoreTypes?.array,
    scope?.priority,
    pgStSelectionOption,
    pgStSelectedIds,
  ]);

  const finalDepartments = useMemo(() => {
    let departments = availableDepartments?.array ?? [];

    if (scope?.priority !== 0 && pgDSelectionOption) {
      switch (pgDSelectionOption) {
        case ConfigurationRuleScopeMode.All:
          break;
        case ConfigurationRuleScopeMode.Specified:
          departments = departments.filter(d => (pgDSelectedIds ?? []).includes(d.id));
          break;
        case ConfigurationRuleScopeMode.AllExcept:
          departments = departments.filter(d => !(pgDSelectedIds ?? []).includes(d.id));
          break;
      }
    }

    return departments;
  }, [availableDepartments, scope?.priority, pgDSelectionOption, pgDSelectedIds]);

  const finalStores = useMemo(() => {
    let stores = regionEnabledStores;

    if (
      scope?.priority !== 0 &&
      pgSSelectionOption &&
      pgStSelectionOption &&
      pgDSelectionOption &&
      pgRSelectionOption
    ) {
      switch (pgRSelectionOption) {
        case ConfigurationRuleScopeMode.All:
          break;
        case ConfigurationRuleScopeMode.Specified:
          stores = stores.filter(s => (pgRSelectedIds ?? []).includes(s.region?.id ?? 0));
          break;
        case ConfigurationRuleScopeMode.AllExcept:
          stores = stores.filter(s => !(pgRSelectedIds ?? []).includes(s.region?.id ?? 0));
          break;
      }

      switch (pgStSelectionOption) {
        case ConfigurationRuleScopeMode.All:
          break;
        case ConfigurationRuleScopeMode.Specified:
          stores = stores.filter(s => (pgStSelectedIds ?? []).includes(s.storeType?.id ?? 0));
          break;
        case ConfigurationRuleScopeMode.AllExcept:
          stores = stores.filter(s => !(pgStSelectedIds ?? []).includes(s.storeType?.id ?? 0));
          break;
      }

      switch (pgSSelectionOption) {
        case ConfigurationRuleScopeMode.All:
          break;
        case ConfigurationRuleScopeMode.Specified:
          stores = stores.filter(s => (pgSSelectedIds ?? []).includes(s.id));
          break;
        case ConfigurationRuleScopeMode.AllExcept:
          stores = stores.filter(s => !(pgSSelectedIds ?? []).includes(s.id));
          break;
      }
    }

    if (curStSelectionOption) {
      switch (curStSelectionOption) {
        case ConfigurationRuleScopeMode.All:
          break;
        case ConfigurationRuleScopeMode.Specified:
          stores = stores.filter(s => (curStSelectedIds ?? []).includes(s.storeType?.id ?? 0));
          break;
        case ConfigurationRuleScopeMode.AllExcept:
          stores = stores.filter(s => !(curStSelectedIds ?? []).includes(s.storeType?.id ?? 0));
          break;
      }
    }

    if (curRSelectionOption) {
      switch (curRSelectionOption) {
        case ConfigurationRuleScopeMode.All:
          break;
        case ConfigurationRuleScopeMode.Specified:
          stores = stores.filter(s => (curRSelectedIds ?? []).includes(s.region?.id ?? 0));
          break;
        case ConfigurationRuleScopeMode.AllExcept:
          stores = stores.filter(s => !(curRSelectedIds ?? []).includes(s.region?.id ?? 0));
          break;
      }
    }

    return stores;
  }, [
    regionEnabledStores,
    scope?.priority,
    pgSSelectionOption,
    pgStSelectionOption,
    pgDSelectionOption,
    pgRSelectionOption,
    curStSelectionOption,
    curRSelectionOption,
    pgRSelectedIds,
    pgStSelectedIds,
    pgSSelectedIds,
    curStSelectedIds,
    curRSelectedIds,
  ]);

  const storeOptions = useMemo(
    () =>
      finalStores.map(s => ({
        id: s.id,
        title: s.name,
        code: s.code,
        customerId: s.customerStoreId ?? '',
      })),
    [finalStores],
  );

  const regionOptions = useMemo(
    () =>
      ((scope?.priority === 0 ? availableRegions?.array : finalRegions) ?? []).map(r => ({
        id: r.id,
        title: r.name,
        customerId: r.customerRegionId ?? '',
      })),
    [availableRegions?.array, finalRegions, scope?.priority],
  );

  const storeTypeOptions = useMemo(
    () =>
      finalStoreTypes.map(s => ({
        id: s.id,
        title: s.name,
        customerId: s.customerWarehouseTypeId ?? '',
      })),
    [finalStoreTypes],
  );

  const departmentOptions = useMemo(
    () =>
      finalDepartments.map(d => ({
        id: d.id,
        title: d.name,
        customerId: d.customerDepartmentId ?? '',
      })),
    [finalDepartments],
  );

  return useMemo(
    () => ({
      storeOptions,
      regionOptions,
      storeTypeOptions,
      departmentOptions,
      loading: isFetching,
    }),
    [departmentOptions, isFetching, regionOptions, storeOptions, storeTypeOptions],
  );
}
