import React, { useMemo } from 'react';
import { useCurrency } from '../../../../hooks/useCurrency';
import { statisticsModalTableColumns } from './statisticsModalTableColumns';
import { StatisticsModalType } from '../statisticsLib';
import { computeRemSize, getDefaultSorting, InfiniteScrollTable } from '@ydistri/ds';
import { CalculationStatisticRouteResponse } from '@ydistri/api-sdk';
import { useGetStatsRoutesQuery, ModalDataInfiniteScrollParams } from '../apiCalculationStatistics';
import useColumnDefinitionByType from '../../../../hooks/useColumnDefinitionByType';

interface StatisticsModalTableRouteProps {
  params: ModalDataInfiniteScrollParams;
  setParams: React.Dispatch<React.SetStateAction<ModalDataInfiniteScrollParams>>;
  displayDepartment?: boolean;
}

const StatisticsModalTableRoute: React.FC<StatisticsModalTableRouteProps> = ({
  params,
  setParams,
  displayDepartment = false,
}) => {
  const currencyId = useCurrency();

  const storeColumnGetterFunction = useColumnDefinitionByType<unknown>({
    type: 'Store',
  });

  const columns = useMemo(
    () =>
      statisticsModalTableColumns(currencyId ?? '', displayDepartment, storeColumnGetterFunction)[
        StatisticsModalType.ROUTE
      ],
    [currencyId, displayDepartment, storeColumnGetterFunction],
  );

  const { data, isFetching } = useGetStatsRoutesQuery({
    ...params,
    tableOrChart: 'table',
    sortings: params.sortings ?? getDefaultSorting(columns),
  });

  return (
    <InfiniteScrollTable<CalculationStatisticRouteResponse, ModalDataInfiniteScrollParams>
      id="statistics-modal-route-table"
      columns={columns}
      dataSource={data}
      loading={isFetching}
      size="small"
      setParams={setParams}
      height={computeRemSize(400)}
    />
  );
};

export default StatisticsModalTableRoute;
