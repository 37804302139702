import { ActionsBar, Section } from '@ydistri/ds';
import React, { useCallback, useMemo, useState } from 'react';
import CreateIconButton from '../../../../components/buttons/CreateIconButton';
import ProductListsList from './ProductListsList';
import { useGetProductListsQuery } from '../apiProductLists';
import { setSelectedProductList, setShowArchivedItems } from '../productListAdministrationSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  archivedStateSorter,
  getArchivedItemsCount,
  onlyActive,
} from '../../common/administrationItemsLib';

import { addToast } from '../../../../store/toastSlice';
import ShowArchivedItemsToggle from '../../common/ShowArchivedItemsToggle';
import ProductListModal from '../modals/ProductListModal';
import { ReduxState } from '../../../../store';

const ProductListAdministrationCatalog: React.FC = () => {
  const dispatch = useDispatch();

  const { data: productLists, isFetching } = useGetProductListsQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  const showArchivedItems = useSelector(
    (state: ReduxState) => state.productListsAdministration.showArchivedItems,
  );
  const selectedProductList = useSelector(
    (state: ReduxState) => state.productListsAdministration.selectedProductList,
  );

  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const onCreateProductListClicked = useCallback(() => {
    setModalOpened(previousModalOpened => !previousModalOpened);
  }, []);

  const onCreateProductListModalClose = useCallback(() => {
    setModalOpened(false);
  }, []);

  //Archived product lists are not displayed by default
  //If they are, they are listed after the active ones and sorted by name
  const displayedData = useMemo(() => {
    if (productLists) {
      if (!showArchivedItems) {
        return onlyActive(productLists);
      } else {
        return Array.from(productLists).sort(archivedStateSorter);
      }
    }
  }, [productLists, showArchivedItems]);

  const archivedItemsCount = useMemo(
    () => (!productLists ? 0 : getArchivedItemsCount(productLists)),
    [productLists],
  );

  /**
   * Shows or hides archived items
   */
  const toggleShowArchived = useCallback(() => {
    const newShowArchivedItems = !showArchivedItems;
    dispatch(setShowArchivedItems(newShowArchivedItems));
    if (newShowArchivedItems) {
      dispatch(
        addToast({
          message: `Showing archived Product lists`,
          description: 'Archived Product lists are shown below the active ones',
        }),
      );
    }

    if (selectedProductList?.isArchived) {
      dispatch(setSelectedProductList(undefined));
    }
  }, [dispatch, selectedProductList, showArchivedItems]);

  const existingProductListNames = useMemo(
    () => productLists?.map(productList => productList.name ?? ''),
    [productLists],
  );

  const contentActions = useMemo(() => {
    return (
      <ActionsBar>
        <ShowArchivedItemsToggle
          archivedItemsCount={archivedItemsCount}
          checked={showArchivedItems}
          onClick={toggleShowArchived}
        />
      </ActionsBar>
    );
  }, [archivedItemsCount, showArchivedItems, toggleShowArchived]);

  const headerActions = useMemo(() => {
    return (
      <ActionsBar>
        <CreateIconButton
          size="small"
          tooltip="Create Product List"
          onClick={onCreateProductListClicked}
          data-testid="create_new_product_list"
        />
      </ActionsBar>
    );
  }, [onCreateProductListClicked]);

  return (
    <>
      <Section
        header="Product lists"
        headerActions={headerActions}
        contentActions={contentActions}
        $scrollableContent={true}
        $ratio={0}
        data-testid="ProductListsSection"
        $width="25rem"
      >
        <ProductListsList
          selectedItem={selectedProductList}
          data={displayedData}
          loading={isFetching}
        />
      </Section>
      {modalOpened && (
        <ProductListModal
          onClose={onCreateProductListModalClose}
          confirmButtonLabel="Create"
          restrictedNames={existingProductListNames}
        />
      )}
    </>
  );
};

export default ProductListAdministrationCatalog;
