import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getItem, LSKey, setItem } from './localStore';
import { LeftMenuItem } from '../../../../../libs/utils/src/lib/menuHelpers';

export interface MenuLeftState {
  leftMenuAutomaticallyCollapsed: boolean;
  leftMenuManuallyCollapsed: boolean;
  items: LeftMenuItem[];
}

const initialState: MenuLeftState = {
  leftMenuAutomaticallyCollapsed: false,
  leftMenuManuallyCollapsed: getItem(LSKey.MENU_COLLAPSED) ?? false,
  items: [],
};

export const menuSlice = createSlice({
  name: 'menuReducer',
  initialState,
  reducers: {
    setLeftMenuAutomaticallyCollapsed: (state, action: PayloadAction<boolean>) => {
      state.leftMenuAutomaticallyCollapsed = action.payload;
    },
    toggleLeftMenuManuallyCollapsed: state => {
      const newCollapsedValue = !state.leftMenuManuallyCollapsed;
      state.leftMenuManuallyCollapsed = newCollapsedValue;
      setItem(LSKey.MENU_COLLAPSED, newCollapsedValue);
    },
    resetState: state => {
      return state;
    },
  },
});

export const { setLeftMenuAutomaticallyCollapsed, toggleLeftMenuManuallyCollapsed } =
  menuSlice.actions;

export const menuReducer = menuSlice.reducer;
