import { apiSlice, APITAGS, ErrorType } from '../../apis/api';
import { CalculationsCollection } from '../../swagger/collections';
import {
  CalculationDetailResponse,
  CalculationFileType,
  CalculationType,
  CalculationUpdateRequest,
  DataRetrievalStatus,
  PapProgressStatus,
  PapSyncStatus,
} from '@ydistri/api-sdk';
import { apiCalculations } from '../Calculations/apiCalculations';
import { getTags } from '../../apis/apiLib';
import { SignalCalculationFileStatusChanged } from '../Calculations/calculationsTypes';
import { signalrClient } from '../../signalr/client';
import { addToast } from '../../store/toastSlice';
import { SignalConfigurationChanged } from '../../signalr/signalrInterfaces';

const { TAGS, TAGS_ARRAY } = getTags('calculationDetail');

export interface CalculationUpdateRequestParams {
  calculationId: number;
  updateRequest: CalculationUpdateRequest;
}

export const apiCalculationDetail = apiSlice
  .enhanceEndpoints({ addTagTypes: TAGS_ARRAY })
  .injectEndpoints({
    endpoints: builder => ({
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      getCalculation: builder.query<CalculationDetailResponse, number>({
        queryFn: async calculationId => {
          const { data } = await CalculationsCollection.getCalculation(calculationId);
          return data;
        },
        providesTags: result => [{ type: TAGS.calculation, id: result?.id ?? 'undefined' }],
        async onCacheEntryAdded(
          calculationId,
          { dispatch, updateCachedData, cacheDataLoaded, cacheEntryRemoved, getCacheEntry },
        ) {
          await cacheDataLoaded;

          const cacheEntry = getCacheEntry();
          if (cacheEntry.data) {
            const listener = (signal: SignalCalculationFileStatusChanged) => {
              if (signal.calculationId === calculationId) {
                //when a file was saved we fetch the calculation to replace the
                //files array with a new one that contains the new file and possibly another
                //file that is computing as we have no way of knowing whether the saved file
                //was the last one
                if (signal.dataRetrievalStatus === DataRetrievalStatus.Saved) {
                  CalculationsCollection.getCalculation(calculationId)
                    .then(response => {
                      const files = response.data.data.files;
                      updateCachedData(draft => {
                        draft.files = files;
                      });
                      const file = files.find(f => f.id === signal.fileId);
                      if (file) {
                        dispatch(
                          addToast({
                            message: `Calculation ${
                              file.calculationFileType === CalculationFileType.PairingXlsx
                                ? 'XLSX'
                                : 'ERP'
                            } file is ready`,
                          }),
                        );
                      }
                    })
                    .catch(error => {
                      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                      const err = error as ErrorType;
                      console.error('Error when retrieving list of files', err);
                      dispatch(
                        addToast({
                          message:
                            'There was an error when retrieving list of files. Please reload the page',
                          isError: true,
                        }),
                      );
                    });
                }
              }
            };

            const listener2 = (signal: SignalConfigurationChanged) => {
              console.log('SIGNAL! SignalConfigurationChanged', signal);
              updateCachedData(draft => {
                if (draft.id !== signal.templateId) return;

                signal.updates.forEach(u => {
                  if (u.value) {
                    switch (u.fieldName) {
                      case 'name':
                        draft.title = u.value;
                        break;
                      case 'description':
                        draft.description = u.value;
                        break;
                      case 'isPap':
                        draft.isPap = u.value === '1';
                        break;
                      case 'papSyncStatus':
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        draft.papDbSyncStatus = u.value as PapSyncStatus;

                        //if synced, refetch calculation data to get new "synced" time
                        if (draft.papDbSyncStatus === PapSyncStatus.Synced) {
                          console.log('Invalidating...');

                          dispatch(
                            addToast({
                              message: `Calculation ${draft.id} synced to PaP. Refreshing data.`,
                            }),
                          );

                          dispatch(
                            apiCalculationDetail.util.invalidateTags([
                              { type: TAGS.calculation, id: draft.id },
                            ]),
                          );
                          return;
                        }
                        break;
                      case 'papResultCalculationProgressStatus':
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        draft.papResultCalculationProgressStatus = u.value as PapProgressStatus;
                        break;
                      case 'calculationTypeId':
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        draft.type = u.value as CalculationType;

                        break;
                    }
                  }
                });
              });
            };

            signalrClient.on('CalculationFileStatusChanged', listener);
            signalrClient.on('TemplateConfigurationChanged', listener2);

            cacheEntryRemoved.then(() => {
              signalrClient.off('CalculationFileStatusChanged', listener);
              signalrClient.off('TemplateConfigurationChanged', listener2);
            });
          }

          await cacheEntryRemoved;
        },
      }),
      updateCalculation: builder.mutation<
        CalculationDetailResponse,
        CalculationUpdateRequestParams
      >({
        queryFn: async ({ calculationId, updateRequest }) => {
          const response = await CalculationsCollection.patchCalculation(
            calculationId,
            updateRequest,
          );
          return { data: response.data.data };
        },
        onQueryStarted: ({ calculationId, updateRequest }, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            apiCalculationDetail.util.updateQueryData('getCalculation', calculationId, draft => {
              const requestCopy = { ...updateRequest };
              const newType = requestCopy.calculationType ?? draft.type;
              if ('calculationType' in requestCopy) delete requestCopy.calculationType;
              Object.assign(draft, { type: newType, ...requestCopy });
            }),
          );

          //we need to invalidate the list of calculations after the update
          //so when user returns to the list of calculations, the list is refreshed with new type, name or description
          queryFulfilled
            .then(() => {
              dispatch(
                apiCalculations.util.invalidateTags([
                  { type: APITAGS.calculations.calculations, id: calculationId },
                  { type: APITAGS.calculations.calculations, id: 'LIST' },
                ]),
              );
            })
            .catch(patchResult.undo);
        },
      }),
    }),
  });

export const { useGetCalculationQuery, useUpdateCalculationMutation } = apiCalculationDetail;
