import React, { useCallback, useMemo } from 'react';
import { Alert, Card } from 'antd';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useTemplateStatus } from '../../../hooks/useTemplateStatus';
import { usePatchCurrentSetupMutation } from '../../../screens/Configuration/apiTemplate';
import { ConfigurationStatus } from '@ydistri/api-sdk';
import { MdReplay } from 'react-icons/md';
import { IconButton, IconButton_IconSizes, Row } from '@ydistri/ds';
import { styled } from 'styled-components';

const SmallCard = styled(Card)`
  .ant-card-body {
    padding: 0.5rem;
  }
`;

const recomputeIcon = <MdReplay size={IconButton_IconSizes.xSmall} />;

interface AlertOutdatedProps {}

const AlertOutdated: React.FC<AlertOutdatedProps> = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const { isRunning, isOutdated } = useTemplateStatus();

  const [recomputeTemplateMutation, recomputeTemplateMutationStatus] =
    usePatchCurrentSetupMutation();

  const recomputeTemplate = useCallback(() => {
    recomputeTemplateMutation({ status: ConfigurationStatus.RefreshPotential });
  }, [recomputeTemplateMutation]);

  const show = isOutdated || isRunning;

  const outdatedMessage = useMemo(() => {
    return (
      <Row $gap="1rem" $alignItems="center">
        <p>
          Outdated - these results are outdated, because configuration was changed since this
          template was recomputed. These results are valid for last recomputed configuration.
        </p>
        <SmallCard>
          <Row $gap="1rem" $alignItems="center">
            {isRunning ? 'Computation running' : 'Recompute statistics'}
            <IconButton
              size="xSmall"
              icon={recomputeIcon}
              loading={recomputeTemplateMutationStatus.isLoading || isRunning}
              tooltip="Recompute statistics"
              disabled={!isOutdated}
              onClick={recomputeTemplate}
            />
          </Row>
        </SmallCard>
      </Row>
    );
  }, [isRunning, recomputeTemplateMutationStatus.isLoading, isOutdated, recomputeTemplate]);

  if (templateOrCalculation.type === 'Calculation' || !show) return null;

  return <Alert message={outdatedMessage} type="warning" showIcon />;
};

export default AlertOutdated;
