import React, { useMemo } from 'react';
import { CategoryConfigurationDefinitionResponse, EntityListType } from '@ydistri/api-sdk';
import ConfigurationEntityListRow from './ConfigurationEntityListRow';
import useCategoryId from '../../../../../hooks/useCategoryId';
import { useCategoryConfiguration } from '../../../../../hooks/useCategoryConfiguration';
import { iterableSkuTypes } from '../../../../../helpers/types/iterableEnums';
import { EntityListConfiguration, parseEntityListConfigs } from './entityListLib';
import ConfigurationEntityListMainRow from './ConfigurationEntityListMainRow';
import { useGetEntityListsQuery } from '../../../../ProjectAdministration/EntityListAdministration/apiEntityLists';

interface ConfigurationEntityListsProps {
  definition: CategoryConfigurationDefinitionResponse;
  forcedTitle?: string;
  forcedCategoryId?: number;
  forcedEntityListId?: number;
  fullyFeatured?: boolean;
}

const ConfigurationEntityLists: React.FC<ConfigurationEntityListsProps> = ({
  definition,
  forcedTitle,
  forcedCategoryId,
  forcedEntityListId,
  fullyFeatured = true,
}) => {
  const selectedCategoryId = useCategoryId();
  const categoryId = forcedCategoryId ?? selectedCategoryId;
  const { rootCategoryConfiguration, categoryConfigurationData: configurations } =
    useCategoryConfiguration(categoryId);

  const { data } = useGetEntityListsQuery(EntityListType.MinLayerList);

  const configuredEntityLists = useMemo(() => {
    if (!rootCategoryConfiguration) return [];
    const config = rootCategoryConfiguration[definition.fieldType];
    const usedEntityListObject: Record<number, boolean | undefined> = {};
    const usedEntityListIds: number[] = [];

    iterableSkuTypes.forEach(st => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const json = JSON.parse(config[st]) as EntityListConfiguration[];
      json.forEach(c => {
        if (usedEntityListObject[c.EntityListId] === undefined) {
          usedEntityListIds.push(c.EntityListId);
          usedEntityListObject[c.EntityListId] = c.IsEnabled === 1;
        }
      });
    });

    const result = (data ?? []).filter(
      d =>
        d.entityListTypeId === EntityListType.MinLayerList &&
        usedEntityListIds.includes(d.entityListId),
    );

    return result;
  }, [data, definition.fieldType, rootCategoryConfiguration]);

  return (
    <>
      {fullyFeatured && (
        <ConfigurationEntityListMainRow
          definition={definition}
          forcedTitle={forcedTitle}
          forcedCategoryId={forcedCategoryId}
          fullyFeatured={fullyFeatured}
        />
      )}
      {configuredEntityLists.map(ml => {
        //TODO fullyFeatured - if not, display only lists with exceptions
        let displayRow = fullyFeatured;
        if (!fullyFeatured) {
          const cfg = configurations?.[definition.fieldType];
          console.log('CFG: ', cfg);
          if (cfg) {
            displayRow = !!iterableSkuTypes.find(skuType => {
              const exc = cfg[skuType]?.exceptions;
              if (exc) {
                return !!exc.find(
                  e =>
                    e.categoryId === categoryId &&
                    !!parseEntityListConfigs(e.value).find(x => x.EntityListId === ml.entityListId),
                );
              }
            });
          }

          if (forcedEntityListId && displayRow) {
            displayRow = ml.entityListId === forcedEntityListId;
          }
        }

        if (!displayRow) return null;

        return (
          <ConfigurationEntityListRow
            definition={definition}
            entityList={ml}
            key={ml.entityListId}
            forcedTitle={forcedTitle}
            forcedCategoryId={forcedCategoryId}
            fullyFeatured={fullyFeatured}
          />
        );
      })}
    </>
  );
};

export default ConfigurationEntityLists;
