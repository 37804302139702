import {
  CalculationStatus,
  CalculationType,
  CategoryRedistributionResponse,
  CategoryRedistributionType,
  SkuType,
} from '@ydistri/api-sdk';
import { RedistributionValues } from './calculationsTypes';

const temporaryStatuses = [
  CalculationStatus.Queued,
  CalculationStatus.Pending,
  CalculationStatus.Running,
];

const finishedStatuses = [CalculationStatus.Crashed, CalculationStatus.Completed];

//group of utility functions to check the status of a calculation processing
//when created, the calculation is in pending status, the proceeds to pending, queued and running
//and when processing finishes it is either completed or crashed
export const isCalculationCreated = (calculationStatus: CalculationStatus): boolean =>
  calculationStatus === CalculationStatus.Pending;
export const isCalculationDeleted = (calculationStatus: CalculationStatus): boolean =>
  calculationStatus === CalculationStatus.SoftDeleted;
export const isCalculationInTemporaryStatus = (calculationStatus: CalculationStatus): boolean =>
  temporaryStatuses.includes(calculationStatus);
export const isCalculationFinished = (calculationStatus: CalculationStatus): boolean =>
  finishedStatuses.includes(calculationStatus);

export enum CalculationDetailSubpages {
  GLOBAL = 'global',
  CATEGORIES = 'categories',
  REGIONS = 'regions',
  STORES_AND_DEPARTMENTS = 'stores',
  SKUCLASSES = 'skuclasses',
  PRODUCTLISTS = 'productlists',
  BRANDS = 'brands',
  ADVANCED = 'advanced',
  STATISTICS = 'statistics',
  TARGET_LIST_STATISTICS = 'target-list-statistics',
  REDISTRIBUTION = 'redistribution',
  DRILLDOWN = 'drilldown',
  EVALUATION = 'evaluation',
  PICKING_RESULTS = 'picking-results',
}

/**
 * Gather the values of the different types of stock to be displayed for the Calculation.
 * @param rootCategoryRedistributions data received from the API
 * @param calculationType to decide if this calculation is in production
 */
export const getRedistributionValues = (
  rootCategoryRedistributions: CategoryRedistributionResponse[],
  calculationType: CalculationType,
): RedistributionValues => {
  let totalValue = 0;
  let deadStockValue = 0;
  let slowMoverValue = 0;
  let fastMoverValue = 0;

  /* for sums use only these types of the values */
  const categoryRedistributionTypesToSum: CategoryRedistributionType[] = [
    CategoryRedistributionType.SourceStandardValue,
    CategoryRedistributionType.SourceForcedValue,
    CategoryRedistributionType.SourceIsClosingValue,
  ];

  rootCategoryRedistributions.forEach(pairing => {
    if (
      pairing.value > 0 &&
      categoryRedistributionTypesToSum.includes(pairing.categoryRedistributionTypeId)
    ) {
      totalValue += pairing.value;

      switch (pairing.skuTypeId) {
        case SkuType.DeadStock:
          deadStockValue += pairing.value;
          break;
        case SkuType.SlowMover: {
          slowMoverValue += pairing.value;
          break;
        }
        case SkuType.FastMover: {
          fastMoverValue += pairing.value;
          break;
        }
      }
    }
  });

  return {
    totalValue,
    deadStockValue,
    slowMoverValue,
    fastMoverValue,
    isProduction: calculationType === CalculationType.Production,
  };
};
