import { Column, Row, Label } from '@ydistri/ds';
import React, { useCallback, useMemo } from 'react';
import { EntityIdentificationType } from '@ydistri/api-sdk';
import AdministrationItemSelect from './AdministrationItemSelect';
import { ReactSelectType, SelectionType } from './administrationItemsLib';

interface EntityIdentificationTypeComponentProps {
  type: 'Product' | 'Store';
  disabled?: boolean;
  value?: EntityIdentificationType;
  onChange: (selectedValue: EntityIdentificationType) => void;
  id?: string;
}

/**
 * Lets the user select how a product or warehouse are identified.
 * They can be identified either by Id or Code.
 * Used in Product list and Target list administration.
 * @param type Type of select makes the wording change
 * @param isDisabled whether the component is selectable or not
 * @param onChange callback when the user selects different value
 * @param value Current value
 * @constructor
 */
const EntityIdentificationTypeComponent: React.FC<EntityIdentificationTypeComponentProps> =
  React.memo(({ type, disabled, onChange, value, ...rest }) => {
    const changeHandler = useCallback(
      (newValue: SelectionType) => {
        if (
          newValue === EntityIdentificationType.Code ||
          newValue === EntityIdentificationType.CustomerId
        ) {
          onChange(newValue);
        }
      },
      [onChange],
    );

    const options = useMemo(() => {
      const selectOptions: ReactSelectType<EntityIdentificationType>[] = [
        { label: `${type} ID`, value: EntityIdentificationType.CustomerId },
        { label: `${type} Code`, value: EntityIdentificationType.Code },
      ];

      return selectOptions;
    }, [type]);

    return (
      <Row $alignItems="center" $gap="2rem">
        <Column $minWidth="13rem">
          <Label>{`${type} identifications:`}</Label>
        </Column>
        <Column $flexGrow={1} $minWidth="30rem">
          <AdministrationItemSelect
            disabled={disabled}
            value={value}
            onChange={changeHandler}
            options={options}
            {...rest}
          />
        </Column>
      </Row>
    );
  });

EntityIdentificationTypeComponent.displayName = 'IdentificationTypeComponent';
export default EntityIdentificationTypeComponent;
