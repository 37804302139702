import React, { useCallback } from 'react';
import { styled } from 'styled-components';
import { COLORS } from '../../../styles/style';
import { Divider } from 'antd';
import { RowCentered, IconButton, IconButton_IconSizes } from '@ydistri/ds';
import { useDispatch } from 'react-redux';
import { AvailableModals, openModal } from '../../../screens/Modals/modalSlice';
import { MdOpenInNew } from 'react-icons/md';
import PotentialValues from './PotentialValues';

const PotentialOverviewWrapper = styled(RowCentered)<{ $isOutdated: boolean }>`
  flex-wrap: nowrap;
  font-size: 1.5rem;
  color: ${COLORS.ALL_SKU_TYPES_COLOR};
  background-color: ${p => (p.$isOutdated ? COLORS.GREY_LIGHT : COLORS.PRIMARY_STEPS[0])};
  border-radius: 0.5rem;
  gap: 0.5rem;
  padding: 0.25rem 1rem;

  .ant-statistic-title {
    font-size: 0.8rem;
    margin-bottom: 0;
  }

  .ant-statistic-content {
    font-size: 1rem;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  transition: 0.3s all;
  cursor: pointer;
  gap: 0.5rem;
`;

const openModalIcon = <MdOpenInNew size={IconButton_IconSizes.small} />;

const CalculationPotentialOverview: React.FC = () => {
  const dispatch = useDispatch();

  const openCategoryInsightsModal = useCallback(() => {
    dispatch(openModal({ type: AvailableModals.CATEGORY_INSIGHTS, data: undefined }));
  }, [dispatch]);

  return (
    <PotentialOverviewWrapper $isOutdated={false}>
      <PotentialValues />
      <Divider type="vertical" />
      <IconsWrapper>
        <IconButton
          size="small"
          icon={openModalIcon}
          onClick={openCategoryInsightsModal}
          tooltip="Open detailed statistics"
          tooltipPosition="left"
        />
      </IconsWrapper>
    </PotentialOverviewWrapper>
  );
};

export default CalculationPotentialOverview;
