import { TargetListVersionConfigurationType } from '@ydistri/api-sdk';
import React, { useCallback } from 'react';

import { Column, Row, Label } from '@ydistri/ds';
import { SelectionType } from '../../common/administrationItemsLib';
import AdministrationItemSelect from '../../common/AdministrationItemSelect';
import { targetListTypeOptions } from '../targetListsTypes';

interface TargetListQuantityComponentProps {
  disabled?: boolean;
  value?: TargetListVersionConfigurationType;
  onChange: (selectedValue: TargetListVersionConfigurationType) => void;
  id?: string;
}

const TargetListQuantityComponent: React.FC<TargetListQuantityComponentProps> = React.memo(
  ({ disabled, value, onChange, id, ...rest }) => {
    const changeHandler = useCallback(
      (newValue: SelectionType) => {
        if (
          newValue === TargetListVersionConfigurationType.ReceiveDefinedQuantity ||
          newValue === TargetListVersionConfigurationType.TopUpDefinedQuantity
        ) {
          onChange(newValue);
        }
      },
      [onChange],
    );

    return (
      <Row $alignItems="center" $gap="2rem">
        <Column $minWidth="13rem">
          <Label>Quantity type:</Label>
        </Column>
        <Column $flexGrow={1} $minWidth="30rem">
          <AdministrationItemSelect
            onChange={changeHandler}
            options={targetListTypeOptions}
            value={value}
            disabled={disabled}
            id={id}
            {...rest}
          />
        </Column>
      </Row>
    );
  },
);

TargetListQuantityComponent.displayName = 'TargetListQuantityComponent';
export default TargetListQuantityComponent;
