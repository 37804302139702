import React from 'react';
import { Column, Section } from '@ydistri/ds';
import CalculationPickingResultsHeader from './CalculationPickingResultsHeader';
import PickingResultsTable from './PickingResultsTable';

/**
 * Content of the section with header, table and button to export the table.
 */
const SectionStorePickingResults: React.FC = () => {
  return (
    <Section>
      <Column $padding={1}>
        <CalculationPickingResultsHeader />
        <PickingResultsTable />
      </Column>
    </Section>
  );
};

export default SectionStorePickingResults;
