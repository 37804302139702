import { ReadOnlyItem, Text } from '@ydistri/ds';
import React from 'react';
import { formatNumber } from '@ydistri/utils';
import { NO_VALUE } from '../../../lib/utils/utilsTypes';

interface PriceProps {
  price?: number | string | null;
  label: string;
  currency: string;
  labelWidth?: string;
}

/**
 * Component to display a price with a label and a currency.
 * Rendered using a ReadOnlyItem with a labelWidth of 6rem.
 * @param price
 * @param label
 * @param currency
 * @param labelWidth Width of the label, defaults to 6rem
 * @constructor
 */
const Price: React.FC<PriceProps> = ({ price, label, currency, labelWidth = '6rem' }) => {
  return (
    <ReadOnlyItem label={label} plainLabel labelWidth={labelWidth}>
      <Text $semiBold>{price ? `${formatNumber(price, 2)} ${currency}` : NO_VALUE}</Text>
    </ReadOnlyItem>
  );
};

export default Price;
