import { StatisticsModalType } from '../statisticsLib';
import { ColumnsType } from 'antd/es/table';
import {
  CalculationStatisticRouteResponse,
  CalculationStatisticStoreSourceResponse,
  CalculationStatisticStoreTargetResponse,
  DepartmentResponse,
  StoreResponse,
} from '@ydistri/api-sdk';
import { formatNumber } from '@ydistri/utils';
import { computeRemSize, YColumnsType } from '@ydistri/ds';
import { ColumnDefinitionFunction } from '../../../../hooks/useColumnDefinitionByType';

interface TableColumns {
  [StatisticsModalType.ROUTE]: ColumnsType<CalculationStatisticRouteResponse>;
  [StatisticsModalType.SOURCE_STORE]: ColumnsType<CalculationStatisticStoreSourceResponse>;
  [StatisticsModalType.TARGET_STORE]: ColumnsType<CalculationStatisticStoreTargetResponse>;
}

type ModalData =
  | CalculationStatisticRouteResponse
  | CalculationStatisticStoreSourceResponse
  | CalculationStatisticStoreTargetResponse;

const columnSourceStoreId = <T>(
  storeColumnGetterFunction: ColumnDefinitionFunction<T>,
): YColumnsType<T> => ({
  ...storeColumnGetterFunction(['sourceStore'], 'SourceStore', 'Source store ID'),
  width: computeRemSize(80),
});

const columnSourceStoreName = <T>(): YColumnsType<T> => ({
  title: 'Source store',
  dataIndex: 'sourceStore',
  key: 'sourceStoreName',
  width: computeRemSize(250),
  sorter: true,
  render: (sourceStore: StoreResponse) => sourceStore.name,
  apiFilterable: true,
  apiFilterType: 'text',
  apiColumnName: 'SourceStore/Name',
});

const columnTargetStoreId = <T>(
  storeColumnGetterFunction: ColumnDefinitionFunction<T>,
): YColumnsType<T> => ({
  ...storeColumnGetterFunction(['targetStore'], 'TargetStore', 'Target store ID'),
  width: computeRemSize(80),
});

// const columnTargetStoreId2 = <T>(): YColumnsType<T> => ({
//   title: 'Target store ID',
//   dataIndex: 'targetStore',
//   key: 'targetStoreId',
//   width: computeRemSize(80),
//   sorter: true,
//   render: (targetStore: StoreResponse) => targetStore.customerStoreId,
//   apiFilterable: true,
//   apiFilterType: 'number',
//   apiColumnName: 'TargetStore/Id',
// });

const columnTargetStoreName = <T>(): YColumnsType<T> => ({
  title: 'Target store',
  dataIndex: 'targetStore',
  key: 'targetStoreName',
  width: computeRemSize(250),
  sorter: true,
  render: (targetStore: StoreResponse) => targetStore.name,
  apiFilterable: true,
  apiFilterType: 'text',
  apiColumnName: 'TargetStore/Name',
});

const columnValue = <T>(currencyId: string): YColumnsType<T> => ({
  title: `Value [${currencyId}]`,
  dataIndex: 'value',
  key: 'value',
  align: 'right',
  width: computeRemSize(150),
  sorter: true,
  defaultSortOrder: 'descend',
  render: (value: number) => formatNumber(value),
  apiFilterable: true,
  apiFilterType: 'number',
  apiColumnName: 'Value',
});

const columnQuantity = <T>(): YColumnsType<T> => ({
  title: `Quantity [MU]`,
  dataIndex: 'quantity',
  key: 'quantity',
  align: 'right',
  width: computeRemSize(150),
  sorter: true,
  render: (value: number) => formatNumber(value),
  apiFilterable: true,
  apiFilterType: 'number',
  apiColumnName: 'Quantity',
});

const columnProductCount = <T>(): YColumnsType<T> => ({
  title: `Product count`,
  dataIndex: 'productCount',
  key: 'productCount',
  align: 'right',
  width: computeRemSize(150),
  sorter: true,
  render: (productCount: number) => formatNumber(productCount),
  apiFilterable: true,
  apiFilterType: 'number',
  apiColumnName: 'ProductCount',
});

const columnFilledStockoutValue = <T>(currencyId: string): YColumnsType<T> => ({
  title: `Replenished out-of-stock value [${currencyId}]`,
  dataIndex: 'filledStockoutValue',
  key: 'filledStockoutValue',
  align: 'right',
  width: computeRemSize(150),
  sorter: true,
  render: (filledStockoutValue: number) => formatNumber(filledStockoutValue),
  apiFilterable: true,
  apiFilterType: 'number',
  apiColumnName: 'FilledStockoutValue',
});

const columnFilledStockoutPercentage = <T extends ModalData>(): YColumnsType<T> => ({
  title: `Replenished out-of-stock [%]`,
  dataIndex: 'filledStockoutValue',
  key: 'filledStockoutPercentage',
  width: computeRemSize(150),
  render: (filledStockoutValue: number, record: T) =>
    'value' in record
      ? `${record.value > 0 ? Math.floor((filledStockoutValue / record.value) * 100) : 0}%`
      : '',
});

const columnTargetStoreCount = <T>(): YColumnsType<T> => ({
  title: `Target store count`,
  dataIndex: 'targetStoreCount',
  key: 'targetStoreCount',
  align: 'right',
  sorter: true,
  width: computeRemSize(150),
  apiFilterable: true,
  apiFilterType: 'number',
  apiColumnName: 'TargetStoreCount',
});

const columnSourceStoreCount = <T>(): YColumnsType<T> => ({
  title: `Source store count`,
  dataIndex: 'sourceStoreCount',
  key: 'sourceStoreCount',
  align: 'right',
  sorter: true,
  width: computeRemSize(150),
  apiFilterable: true,
  apiFilterType: 'number',
  apiColumnName: 'SourceStoreCount',
});

const columnDepartmentName = <T>(displayDepartment: boolean): YColumnsType<T>[] =>
  displayDepartment
    ? [
        {
          title: `Department`,
          dataIndex: 'department',
          key: 'department',
          sorter: true,
          render: (department: DepartmentResponse) => department?.name ?? '',
          apiFilterable: true,
          apiFilterType: 'text',
          apiColumnName: 'Department/Name',
        },
      ]
    : [];

export const statisticsModalTableColumns = (
  currencyId: string,
  displayDepartment: boolean,
  storeColumnGetterFunction: ColumnDefinitionFunction<unknown>,
): TableColumns => ({
  [StatisticsModalType.ROUTE]: [
    columnSourceStoreId(
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      storeColumnGetterFunction as ColumnDefinitionFunction<CalculationStatisticRouteResponse>,
    ),
    columnSourceStoreName(),
    columnTargetStoreId(
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      storeColumnGetterFunction as ColumnDefinitionFunction<CalculationStatisticRouteResponse>,
    ),
    columnTargetStoreName(),
    ...columnDepartmentName<CalculationStatisticRouteResponse>(displayDepartment),
    columnValue(currencyId),
    columnQuantity(),
    columnProductCount(),
    columnFilledStockoutValue(currencyId),
  ],
  [StatisticsModalType.SOURCE_STORE]: [
    columnSourceStoreId(
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      storeColumnGetterFunction as ColumnDefinitionFunction<CalculationStatisticStoreSourceResponse>,
    ),
    columnSourceStoreName(),
    ...columnDepartmentName<CalculationStatisticStoreSourceResponse>(displayDepartment),
    columnValue(currencyId),
    columnQuantity(),
    columnProductCount(),
    columnTargetStoreCount(),
  ],
  [StatisticsModalType.TARGET_STORE]: [
    columnTargetStoreId(
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      storeColumnGetterFunction as ColumnDefinitionFunction<CalculationStatisticStoreTargetResponse>,
    ),
    columnTargetStoreName(),
    ...columnDepartmentName<CalculationStatisticStoreTargetResponse>(displayDepartment),
    columnValue(currencyId),
    columnQuantity(),
    columnProductCount(),
    columnSourceStoreCount(),
    columnFilledStockoutValue(currencyId),
    columnFilledStockoutPercentage(),
  ],
});
