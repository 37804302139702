import React, { useCallback, useMemo } from 'react';
import { styled } from 'styled-components';
import { Divider, Statistic } from 'antd';
import { RowCentered, IconButton, IconButton_IconSizes } from '@ydistri/ds';
import { ConfigurationStatus } from '@ydistri/api-sdk';
import { COLORS } from '../../../styles/style';
import { useTemplateStatus } from '../../../hooks/useTemplateStatus';
import { Overlay } from '../Overlay';
import { usePatchCurrentSetupMutation } from '../../../screens/Configuration/apiTemplate';
import { useDispatch } from 'react-redux';
import { AvailableModals, openModal } from '../../../screens/Modals/modalSlice';
import { MdDirectionsRun, MdLockOutline, MdOpenInNew, MdReplay } from 'react-icons/md';
import PotentialValues from './PotentialValues';
import Validator from '../../../screens/Configuration/Validator/Validator';

const PotentialOverviewWrapper = styled(RowCentered)<{ $isOutdated: boolean }>`
  flex-wrap: nowrap;
  font-size: 1.5rem;
  color: ${COLORS.ALL_SKU_TYPES_COLOR};
  background-color: ${p => (p.$isOutdated ? COLORS.GREY_LIGHT : COLORS.PRIMARY_STEPS[0])};
  border-radius: 0.5rem;
  gap: 0.5rem;
  padding: 0.25rem 1rem;

  .ant-statistic-title {
    font-size: 0.8rem;
    margin-bottom: 0;
  }

  .ant-statistic-content {
    font-size: 1rem;
  }
`;

const PotentialStatistic = styled(Statistic)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 7.5rem;

  .ant-statistic-content-value {
    font-weight: 500;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  transition: 0.3s all;
  //cursor: pointer;
  gap: 0.5rem;
`;

const OutdatedMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${COLORS.PRIMARY};
  gap: 0.5rem;

  svg {
    font-size: 2rem;
  }
`;

const TemplatePotentialOverview: React.FC = () => {
  const dispatch = useDispatch();
  const { isRunning, isOutdated, niceName } = useTemplateStatus();
  const [recomputeTemplateMutation, recomputeTemplateMutationStatus] =
    usePatchCurrentSetupMutation();

  const openModalIcon = useMemo(() => <MdOpenInNew size={IconButton_IconSizes.small} />, []);
  const recomputeIcon = useMemo(() => <MdReplay size={IconButton_IconSizes.small} />, []);
  const outdatedMessage = useMemo(
    () => (
      <OutdatedMessage>
        <MdLockOutline /> Outdated results
      </OutdatedMessage>
    ),
    [],
  );
  const runningMessage = useMemo(
    () => (
      <OutdatedMessage>
        <MdDirectionsRun /> {niceName}
      </OutdatedMessage>
    ),
    [niceName],
  );

  const recomputeTemplate = useCallback(() => {
    recomputeTemplateMutation({ status: ConfigurationStatus.RefreshPotential });
  }, [recomputeTemplateMutation]);

  const openRootCategoryDetail = useCallback(() => {
    dispatch(openModal({ type: AvailableModals.CATEGORY_INSIGHTS, data: undefined }));
  }, [dispatch]);

  return (
    <PotentialOverviewWrapper $isOutdated={isOutdated}>
      <Overlay active={isRunning} overlayContent={runningMessage}>
        <PotentialStatistic title="Status" value={niceName} />
        <Divider type="vertical" />
        <Overlay active={!isRunning && isOutdated} overlayContent={outdatedMessage}>
          <PotentialValues />
        </Overlay>
        <Divider type="vertical" />
        <IconsWrapper>
          <IconButton
            size="small"
            icon={openModalIcon}
            tooltip="Open detailed statistics"
            tooltipPosition="left"
            onClick={openRootCategoryDetail}
          />
          <IconButton
            size="small"
            icon={recomputeIcon}
            loading={recomputeTemplateMutationStatus.isLoading}
            tooltip="Recompute statistics"
            tooltipPosition="left"
            disabled={!isOutdated}
            onClick={recomputeTemplate}
          />
          <Validator />
        </IconsWrapper>
      </Overlay>
    </PotentialOverviewWrapper>
  );
};

export default TemplatePotentialOverview;
