import React, { useEffect, useState } from 'react';
import { useConfigurationRuleOverlap } from './useConfigurationRuleOverlap';
import { useGetFinalStoreDepartmentConfigurationQuery } from './apiScopes';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import LoadingIndicator from '../../../components/global/LoadingIndicator/LoadingIndicator';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { iterableConfigurationRuleSetupType } from '../../../helpers/types/iterableEnums';
import { lowercaseFirstLetter } from '@ydistri/utils';
import { ScopeEntityConfigurationResponse } from '@ydistri/api-sdk';
import { Alert } from 'antd';

interface ValidateStoreDepartmentConfigurationProps {
  setDisabledButton: React.Dispatch<React.SetStateAction<boolean>>;
}

const ValidateStoreDepartmentConfiguration: React.FC<ValidateStoreDepartmentConfigurationProps> = ({
  setDisabledButton,
}) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const isLoadingRuleOverlap = useConfigurationRuleOverlap();
  const [validationError, setValidationError] = useState(false);
  const exceptionOverlapData = useSelector((state: ReduxState) => state.scope.exceptionOverlapData);
  const {
    data: finalStoreDepartmentConfigurations,
    isFetching: isFetchingFinalStoreDepartmentConfigurations,
  } = useGetFinalStoreDepartmentConfigurationQuery(templateOrCalculation, {
    refetchOnMountOrArgChange: true,
  });

  const isValidating = isLoadingRuleOverlap || isFetchingFinalStoreDepartmentConfigurations;

  useEffect(() => {
    if (isValidating) {
      setDisabledButton(true);
      // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
      setValidationError(false);
    }
  }, [isValidating, setDisabledButton]);

  useEffect(() => {
    if (exceptionOverlapData && finalStoreDepartmentConfigurations) {
      setDisabledButton(false);
      console.log('===================');
      console.log('finalStoreDepartmentConfigurations', finalStoreDepartmentConfigurations);
      console.log('exceptionOverlapData', exceptionOverlapData);

      const errors: string[] = [];

      const usedMatrix: Record<string, true | undefined> = {};

      finalStoreDepartmentConfigurations.forEach(c => {
        const matrixKey = `${c.warehouseId}-${c.departmentId}`;
        const cMatrix = exceptionOverlapData.matrix[matrixKey];

        if (!cMatrix) {
          errors.push(`Not found in matrix: ${matrixKey} = ${JSON.stringify(c)} `);
          return;
        } else {
          usedMatrix[matrixKey] = true;
        }

        iterableConfigurationRuleSetupType.forEach(i => {
          const configKey = lowercaseFirstLetter(i);
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const apiValue = c[configKey as keyof ScopeEntityConfigurationResponse];
          const feValue = cMatrix.final[i];
          if (apiValue !== feValue) {
            errors.push(
              `Config [${configKey}] not the same! ${matrixKey} FE: ${feValue} API: ${apiValue}`,
            );
          }
        });
      });

      /* //Problem with department/store combinations without SKUs
      Object.keys(exceptionOverlapData.matrix).forEach(k => {
        if (!usedMatrix[k]) {
          errors.push(`Key not found from server: ${k} `);
        }
      });*/

      if (errors.length > 0) {
        setDisabledButton(true);
        // eslint-disable-next-line @nx/workspace/no-useless-setstate-in-effect
        setValidationError(true);
      }

      console.log('VALIDATE STORE AND DEPARTMENT CONFIGURATION ERRORS: ', errors);
    }
  }, [exceptionOverlapData, finalStoreDepartmentConfigurations, setDisabledButton]);

  return (
    <>
      {isValidating && <LoadingIndicator text="Validating configurations on the server..." />}
      {!isValidating && validationError && (
        <Alert
          message="There appear to be some inconsistencies with our server validations. This issue isn't caused by your actions, and we're currently implementing a fix."
          type="error"
          showIcon
        />
      )}
    </>
  );
};

export default ValidateStoreDepartmentConfiguration;
