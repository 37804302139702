import ConfigurationMultiselect, {
  MultiselectCustomerPartialEntity,
} from './ConfigurationMultiselect';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateScopeEntitySelectedIds } from './scopeConfigurationSlice';
import { ConfigurationRuleScopeEntityType } from '@ydistri/api-sdk';

interface ScopeConfigurationMultiselectProps {
  selectedIds?: number[];
  entityType: ConfigurationRuleScopeEntityType;
  data: MultiselectCustomerPartialEntity[];
  loading?: boolean;
}

const ScopeConfigurationMultiselect: React.FC<ScopeConfigurationMultiselectProps> = ({
  selectedIds = [],
  entityType,
  data,
  loading,
}) => {
  const dispatch = useDispatch();

  const onChangeHandler = useCallback(
    (selectedIds: number[]) => {
      dispatch(updateScopeEntitySelectedIds({ entityType, selectedIds }));
    },
    [dispatch, entityType],
  );

  return (
    <ConfigurationMultiselect
      data={data}
      value={selectedIds}
      onValueChange={onChangeHandler}
      loading={loading}
      entityType={entityType}
    />
  );
};

export default ScopeConfigurationMultiselect;
