import { computeRemSize, ReadOnlyItem, Text } from '@ydistri/ds';
import { Popover } from 'antd';
import React from 'react';
import { styled } from 'styled-components';

interface CalculationTitleItemProps {
  /** Label to display, will be suffixed with a colon */
  label: string;
  /* Width of the label in CSS units */
  labelWidth?: string;
  content: string;
  contentWidth?: string;
}

const PopoverContentWrapper = styled.div`
  max-width: 40rem;
`;

/**
 * Common item for the template name and calculation description for the
 * Calculation Header. Both items have the same behaviour, so they are
 * factored out to this component.
 * Content is wrapped in Popover because long content is truncated.
 * @param label
 * @param labelWidth
 * @param content
 * @param contentWidth
 * @constructor
 */
const CalculationTitleItem: React.FC<CalculationTitleItemProps> = ({
  label,
  labelWidth,
  content,
  contentWidth,
}) => {
  const popoverContent = <PopoverContentWrapper>{content}</PopoverContentWrapper>;

  return (
    <ReadOnlyItem
      label={label}
      noWrap={true}
      labelWidth={labelWidth}
      spaceBetween={computeRemSize(1)}
    >
      <Popover content={popoverContent}>
        <Text $maxWidth={contentWidth}>{content}</Text>
      </Popover>
    </ReadOnlyItem>
  );
};

export default React.memo(CalculationTitleItem);
