import React, { useCallback } from 'react';
import { TargetListResponse } from '@ydistri/api-sdk';
import TargetListTypeIndicator from '../components/TargetListTypeIndicator';
import { useParams } from 'react-router-dom';
import { RoutingPage } from '../../../../routes/routerSlice';
import { ProjectAdministrationSubpage } from '../../projectAdministrationLib';
import AdministrationItemsList from '../../common/catalog/AdministrationItemsList';

interface TargetListsListProps {
  data?: TargetListResponse[];
  onItemClick?: (targetList: TargetListResponse) => void;
  selectedItem?: TargetListResponse;
  loading?: boolean;
}

const TargetListsList: React.FC<TargetListsListProps> = ({ data, loading, selectedItem }) => {
  const { projectShortName } = useParams();
  const countableData = useCallback((item: TargetListResponse) => {
    return [
      { count: item.productCount, description: 'product' },
      { count: item.warehouseCount, description: 'store' },
    ];
  }, []);

  const getIcon = useCallback(
    (item: TargetListResponse) => (
      <TargetListTypeIndicator type={item.targetListVersionConfigurationTypeId} />
    ),
    [],
  );

  const linkProvider = useCallback(
    (item: TargetListResponse) =>
      `/${projectShortName ?? ''}/${RoutingPage.PROJECT_ADMINISTRATION}/${
        ProjectAdministrationSubpage.TARGETLISTS
      }/${item.targetListId}`,
    [projectShortName],
  );

  const idProvider = useCallback((item: TargetListResponse) => item.targetListId, []);

  const itemSelected = useCallback(
    (item: TargetListResponse) => selectedItem?.targetListId === item.targetListId,
    [selectedItem],
  );

  return (
    <AdministrationItemsList<TargetListResponse>
      data={data}
      loading={loading}
      countableDataProvider={countableData}
      linkProvider={linkProvider}
      idProvider={idProvider}
      iconProvider={getIcon}
      itemSelected={itemSelected}
      data-testid="TargetListsList"
    />
  );
};

export default TargetListsList;
