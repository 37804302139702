import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { setSubpage } from '../../../routes/routerSlice';
import { ProjectAdministrationSubpage } from '../projectAdministrationLib';
import ProductListAdministrationCatalog from './catalog/ProductListAdministrationCatalog';
import ProductListAdministrationContent from './content/ProductListAdministrationContent';
import { useParams } from 'react-router-dom';
import { useGetProductListsQuery } from './apiProductLists';
import { setSelectedProductList } from './productListAdministrationSlice';
import { MainContentWrapper } from '../../../../../../../libs/ds/src/pages/PageLayout/pageLayoutComponents';

const ProductListsAdministration: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data: productLists } = useGetProductListsQuery();

  useEffect(() => {
    dispatch(setSubpage(ProjectAdministrationSubpage.PRODUCTLISTS));
  }, [dispatch]);

  useEffect(() => {
    if (id && productLists) {
      const requestedProductListId = parseInt(id);
      const requestedProductList = productLists.find(
        productList => productList.productListId === requestedProductListId,
      );
      if (requestedProductList) {
        dispatch(setSelectedProductList(requestedProductList));
      }
    }
  }, [dispatch, id, productLists]);

  return (
    <>
      <Helmet title="Product Lists Administration" />
      <MainContentWrapper>
        <ProductListAdministrationCatalog />
        <ProductListAdministrationContent />
      </MainContentWrapper>
    </>
  );
};

export default ProductListsAdministration;
