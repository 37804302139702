import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { RoutingPage, setPage } from '../../routes/routerSlice';
import { ActionsBar, Section } from '@ydistri/ds';
import CloseAllStatisticsAction from './actions/CloseAllStatisticsAction';
import { filterCalculations, resetExpandedRowKeys } from './calculationsSlice';
import CurrentUserCalculationsAction from './actions/CurrentUserCalculationsAction';
import ProductionCalculationsAction from './actions/ProductionCalculationsAction';
import CalculationsTable from './components/table/CalculationsTable';
import { useUser } from '../../hooks/useUser';
import { createDebugLog } from '../../lib/utils/logging';
import SelectedCalculationsActionButtons from './actions/SelectedCalculationsActionButtons';
import { getIconBasedOnPrivateFilterType, PrivateFilterType } from './calculationsTypes';
import { ItemType } from 'antd/es/menu/interface';
import { Dropdown } from 'antd';
import ToggleIconButton from '../../components/buttons/ToggleIconButton';
import { MainContentWrapper } from '../../../../../../libs/ds/src/pages/PageLayout/pageLayoutComponents';

const debugLog = createDebugLog('Calculations', 'Calculations');

const Calculations: React.FC = () => {
  debugLog('rendering');
  const dispatch = useDispatch();
  const user = useUser();

  const calculationFilters = useSelector(state => state.calculations.calculationFilters);

  useEffect(() => {
    dispatch(setPage(RoutingPage.CALCULATIONS));
  }, [dispatch]);

  const setPrivateCalculationFilter = useCallback(
    (value: PrivateFilterType) => {
      dispatch(
        filterCalculations({
          showAllPrivateCalculations: value,
          showCurrentUserCalculationsOnly: false,
          showProductionOnly: false,
        }),
      );
    },
    [dispatch],
  );

  const toggleShowCurrentUserCalculations = useCallback(() => {
    dispatch(
      filterCalculations({
        showCurrentUserCalculationsOnly: !calculationFilters.showCurrentUserCalculationsOnly,
        showAllPrivateCalculations: calculationFilters.showAllPrivateCalculations,
        showProductionOnly: calculationFilters.showProductionOnly,
      }),
    );
  }, [calculationFilters, dispatch]);

  const toggleShowProductionCalculations = useCallback(() => {
    dispatch(
      filterCalculations({
        ...calculationFilters,
        showProductionOnly: !calculationFilters.showProductionOnly,
      }),
    );
  }, [calculationFilters, dispatch]);

  const closeAllStatistics = useCallback(() => {
    dispatch(resetExpandedRowKeys());
  }, [dispatch]);

  const menuItemClickHandler = useCallback(
    (menuInfo: {
      key: string;
      domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
    }) => {
      const { key, domEvent } = menuInfo;

      domEvent.preventDefault();

      switch (key) {
        case PrivateFilterType.ALL.toString():
          setPrivateCalculationFilter(PrivateFilterType.ALL);
          break;
        case PrivateFilterType.MINE.toString():
          setPrivateCalculationFilter(PrivateFilterType.MINE);
          break;
        case PrivateFilterType.NONE.toString():
          setPrivateCalculationFilter(PrivateFilterType.NONE);
          break;
        default:
          return;
      }
    },
    [setPrivateCalculationFilter],
  );

  const menuItems = useMemo(() => {
    const items: ItemType[] = [
      {
        label: 'Show all private calculations',
        key: PrivateFilterType.ALL,
        icon: getIconBasedOnPrivateFilterType(PrivateFilterType.ALL),
        onClick: menuItemClickHandler,
      },
      {
        label: 'Show only my private calculations',
        key: PrivateFilterType.MINE,
        icon: getIconBasedOnPrivateFilterType(PrivateFilterType.MINE),
        onClick: menuItemClickHandler,
      },
      {
        label: 'Hide private calculations',
        key: PrivateFilterType.NONE,
        icon: getIconBasedOnPrivateFilterType(PrivateFilterType.NONE),
        onClick: menuItemClickHandler,
      },
    ];

    return {
      items,
      selectable: true,
      selectedKeys: [calculationFilters.showAllPrivateCalculations],
    };
  }, [menuItemClickHandler, calculationFilters.showAllPrivateCalculations]);

  const headerActions = useMemo(() => {
    return (
      <ActionsBar>
        <SelectedCalculationsActionButtons />
        {user?.isSuperUser && (
          <Dropdown menu={menuItems}>
            <ToggleIconButton
              size="small"
              checked={calculationFilters.showAllPrivateCalculations !== PrivateFilterType.NONE}
              icon={getIconBasedOnPrivateFilterType(
                calculationFilters.showAllPrivateCalculations,
                'small',
              )}
            />
          </Dropdown>
        )}
        <CurrentUserCalculationsAction
          onClick={toggleShowCurrentUserCalculations}
          tooltip={
            calculationFilters.showCurrentUserCalculationsOnly
              ? "Show also other people's calculations"
              : 'Show my calculations only'
          }
          value={calculationFilters.showCurrentUserCalculationsOnly}
          size="small"
        />
        <ProductionCalculationsAction
          tooltip={
            calculationFilters.showProductionOnly
              ? 'Show all calculations'
              : 'Show production calculations only'
          }
          value={calculationFilters.showProductionOnly}
          onClick={toggleShowProductionCalculations}
          size="small"
        />
        <CloseAllStatisticsAction onClick={closeAllStatistics} size="small" />
      </ActionsBar>
    );
  }, [
    menuItems,
    calculationFilters,
    closeAllStatistics,
    toggleShowCurrentUserCalculations,
    toggleShowProductionCalculations,
    user,
  ]);

  return (
    <MainContentWrapper>
      <Helmet title="Calculations" />
      <Section header="Calculations" data-id="CalculationsSection" headerActions={headerActions}>
        <CalculationsTable />
      </Section>
    </MainContentWrapper>
  );
};

export default Calculations;
