import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { setSubpage } from '../../../routes/routerSlice';
import { useParams } from 'react-router-dom';
import { EntityListType } from '@ydistri/api-sdk';
import { useGetEntityListsQuery } from './apiEntityLists';
import {
  pageTitleByEntityListType,
  projectAdministrationSubpageByEntityListType,
} from './entityListsLib';
import { setSelectedEntityList } from './entityListAdministrationSlice';
import EntityListAdministrationCatalog from './catalog/EntityListAdministrationCatalog';
import EntityListAdministrationContent from './content/EntityListAdministrationContent';
import { MainContentWrapper } from '../../../../../../../libs/ds/src/pages/PageLayout/pageLayoutComponents';

interface EntityListsAdministrationProps {
  entityListType: EntityListType;
}

const EntityListsAdministration: React.FC<EntityListsAdministrationProps> = ({
  entityListType,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data: entityLists } = useGetEntityListsQuery(entityListType);

  useEffect(() => {
    dispatch(setSubpage(projectAdministrationSubpageByEntityListType[entityListType]));
  }, [dispatch, entityListType]);

  useEffect(() => {
    if (id && entityLists) {
      const requestedEntityListId = parseInt(id);
      const requestedEntityList = entityLists.find(el => el.entityListId === requestedEntityListId);
      if (requestedEntityList) {
        dispatch(setSelectedEntityList({ entityListType, data: requestedEntityList }));
      }
    }
  }, [dispatch, id, entityLists, entityListType]);

  return (
    <>
      <Helmet title={pageTitleByEntityListType[entityListType]} />
      <MainContentWrapper>
        <EntityListAdministrationCatalog entityListType={entityListType} />
        <EntityListAdministrationContent entityListType={entityListType} />
      </MainContentWrapper>
    </>
  );
};

export default EntityListsAdministration;
