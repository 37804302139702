import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetPickingResultsSummaryQuery } from './apiCalculationPickingResults';
import StatisticsSection from '../Statistics/components/StatisticsSection';
import StatisticsOverallIcon from '../../../../assets/statistics-overall.svg';
import React from 'react';
import { Column, computeRemSize, Row, Title } from '@ydistri/ds';
import CalculationDetails from './CalculationDetails';
import CalculationOverallPickingStatistics from './CalculationOverallPickingStatistics';

/**
 * Header section displaying title and the overall picking results.
 */
const CalculationPickingResultsHeader: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();

  const { data, isFetching } = useGetPickingResultsSummaryQuery({
    calculationId: templateOrCalculation.id,
  });

  if (!isFetching && data) {
    return (
      <StatisticsSection $width="100%" icon={StatisticsOverallIcon}>
        <Row $justifyContent="space-between" $gap="1rem">
          <Column $gap="1rem" $width={computeRemSize(450)}>
            <Title>Store picking results</Title>
            <CalculationDetails />
          </Column>
          <Row $gap="1rem">
            <CalculationOverallPickingStatistics />
          </Row>
        </Row>
      </StatisticsSection>
    );
  }
};

export default CalculationPickingResultsHeader;
