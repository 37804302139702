import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  computeRemSize,
  getDefaultSorting,
  InfiniteScrollTable,
  Panel,
  YColumnsType,
} from '@ydistri/ds';
import { ProductResponse } from '@ydistri/api-sdk';
import { useApplicationData } from '../../../../../hooks/useApplicationData';
import { ProductListItemsRequest, useGetProductListItemsQuery } from '../../apiProductLists';
import { useColumnWidth } from '../../../../../hooks/useColumnWidth';
import useColumnDefinitionByType from '../../../../../hooks/useColumnDefinitionByType';

interface ProductListSkuGridProps {
  selectedProductListId: number;
}

const BLOCK_SIZE: number = 45;

const ProductListProductsGrid: React.FC<ProductListSkuGridProps> = ({ selectedProductListId }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { productIdColumnWidth } = useColumnWidth();
  const [tableHeight, setTableHeight] = useState(480);
  const { productCode } = useApplicationData();

  const productColumnGetterFunction = useColumnDefinitionByType<ProductResponse>({
    type: 'Product',
  });

  const columns: YColumnsType<ProductResponse>[] = useMemo(() => {
    const tmpColumns: YColumnsType<ProductResponse>[] = [
      {
        ...productColumnGetterFunction([], ''),
        width: productIdColumnWidth,
      },
      {
        title: 'Product Name',
        key: 'productName',
        width: '29rem',
        dataIndex: ['name'],
        apiColumnName: 'Name',
        apiFilterable: true,
        apiFilterType: 'text',
        sorter: true,
      },
    ];

    //if project uses product code, insert a Product Code column after the Product index column
    if (productCode) {
      const productIdColumnIndex = tmpColumns.findIndex(tmpColumn => tmpColumn.key === 'productId');
      if (productIdColumnIndex > -1) {
        tmpColumns.splice(productIdColumnIndex + 1, 0, {
          title: 'Product Code',
          key: 'productCode',
          dataIndex: ['code'],
          width: '6rem',
          apiColumnName: 'Code',
          apiFilterable: true,
          apiFilterType: 'text',
          sorter: true,
        });
      }
    }

    return tmpColumns;
  }, [productCode, productColumnGetterFunction, productIdColumnWidth]);

  const defaultParams = useMemo(() => {
    return {
      productListId: selectedProductListId,
      skip: 0,
      top: BLOCK_SIZE,
      sortings: getDefaultSorting(columns),
    };
  }, [columns, selectedProductListId]);

  const [params, setParams] = useState<ProductListItemsRequest>(defaultParams);

  useEffect(() => {
    setParams(defaultParams);
  }, [defaultParams]);

  const { data, isFetching, refetch } = useGetProductListItemsQuery(params);

  const resizeTable = useCallback(() => {
    const TABLE_SHIFT: number = 96;
    if (ref.current) {
      setTableHeight(ref.current.clientHeight - TABLE_SHIFT);
    }
  }, []);

  useEffect(() => {
    resizeTable();

    //compute height on window resize
    window.addEventListener('resize', () => resizeTable);

    return () => {
      window.removeEventListener('resize', () => resizeTable);
    };
  }, [refetch, resizeTable]);

  const rowKeyProvider = useCallback(
    (row: ProductResponse) => (row.customerId ? row.customerId.toString() : row.id.toString()),
    [],
  );

  return (
    <Panel $height="100%" ref={ref}>
      <InfiniteScrollTable<ProductResponse, ProductListItemsRequest>
        id="targetListContent"
        height={computeRemSize(tableHeight)}
        setParams={setParams}
        columns={columns}
        dataSource={data}
        loading={isFetching}
        rowKey={rowKeyProvider}
      />
    </Panel>
  );
};

export default ProductListProductsGrid;
