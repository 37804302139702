import { SelectProps } from 'antd';
import React from 'react';
import ScopeConfigurationFieldRow from './ScopeConfigurationFieldRow';
import {
  ConfigurationRuleScopeEntityType,
  ConfigurationRuleScopeMode,
  ConfigurationRuleSetupType,
} from '@ydistri/api-sdk';
import cloneDeep from 'lodash.clonedeep';
import {
  iterableConfigurationRuleScopeEntityType,
  iterableConfigurationRuleSetupType,
} from '../../../helpers/types/iterableEnums';
import { Column } from '@ydistri/ds';

export enum ViewMode {
  GENERAL_CONFIGURATION = 'general-config',
  EXCEPTION = 'exception',
  NEW_EXCEPTION_MODAL = 'new-exception-modal',
  ADJUST_SCOPE_MODAL = 'adjust-scope-modal',
}

export enum ScopeModalType {
  CREATE = 'create',
  EDIT = 'edit',
}

export type ScopeConfigurationIconReplacement = 'cross' | 'check' | undefined;

export const configurationRuleScopeModeColor: Record<ConfigurationRuleScopeMode, string> = {
  [ConfigurationRuleScopeMode.All]: 'green',
  [ConfigurationRuleScopeMode.Specified]: 'blue',
  [ConfigurationRuleScopeMode.AllExcept]: 'gold',
};

export const configurationRuleScopeModeTitle: Record<ConfigurationRuleScopeMode, string> = {
  [ConfigurationRuleScopeMode.All]: 'All',
  [ConfigurationRuleScopeMode.Specified]: 'Specified',
  [ConfigurationRuleScopeMode.AllExcept]: 'Excluded',
};

export const getScopeTypeSelectOptions = (
  isPlayground: boolean,
): NonNullable<SelectProps<ConfigurationRuleScopeMode>['options']> => {
  return [
    {
      value: ConfigurationRuleScopeMode.All,
      label: `All${isPlayground ? '' : ' in general configuration'}`,
    },
    {
      value: ConfigurationRuleScopeMode.Specified,
      label: `Specify ${isPlayground ? '' : ' from general configuration'}`,
    },
    {
      value: ConfigurationRuleScopeMode.AllExcept,
      label: `Exclude ${isPlayground ? '' : ' from general configuration'}`,
    },
  ];
};

export interface ScopeEntity {
  selectionOption: ConfigurationRuleScopeMode;
  selectedIds: number[];
}

export interface ScopeConfiguration {
  [ConfigurationRuleSetupType.UseAsSource]: boolean | null;
  [ConfigurationRuleSetupType.UseAsTarget]: boolean | null;
  [ConfigurationRuleSetupType.IsEnabled]: boolean | null;
  [ConfigurationRuleSetupType.IsClosing]: boolean | null;
  [ConfigurationRuleSetupType.IsSuperTarget]: boolean | null;
  [ConfigurationRuleSetupType.MaxNumberOfSourceStores]: number | null;
  [ConfigurationRuleSetupType.MaxNumberOfTargetStores]: number | null;
  [ConfigurationRuleSetupType.MaxNumberOfSourceProducts]: number | null;
  [ConfigurationRuleSetupType.MinimumRouteValue]: number | null;
}

export type ScopeEntitySetting = Record<ConfigurationRuleScopeEntityType, ScopeEntity>;

export interface Scope {
  id: number;
  priority: number;
  name: string | undefined;
  creating: boolean;
  entities: ScopeEntitySetting;
  configuration: ScopeConfiguration;
}

export const scopeEntityTitle: Record<ConfigurationRuleScopeEntityType, string> = {
  [ConfigurationRuleScopeEntityType.Regions]: 'Region',
  [ConfigurationRuleScopeEntityType.Stores]: 'Store',
  [ConfigurationRuleScopeEntityType.StoreTypes]: 'Store type',
  [ConfigurationRuleScopeEntityType.Departments]: 'Department',
};

export const emptyScope: Scope = {
  id: -1, //empty scope id
  name: undefined,
  priority: 0,
  creating: true,
  entities: {
    [ConfigurationRuleScopeEntityType.Regions]: {
      selectionOption: ConfigurationRuleScopeMode.All,
      selectedIds: [],
    },
    [ConfigurationRuleScopeEntityType.Stores]: {
      selectionOption: ConfigurationRuleScopeMode.All,
      selectedIds: [],
    },
    [ConfigurationRuleScopeEntityType.StoreTypes]: {
      selectionOption: ConfigurationRuleScopeMode.All,
      selectedIds: [],
    },
    [ConfigurationRuleScopeEntityType.Departments]: {
      selectionOption: ConfigurationRuleScopeMode.All,
      selectedIds: [],
    },
  },
  configuration: {
    [ConfigurationRuleSetupType.UseAsSource]: true,
    [ConfigurationRuleSetupType.UseAsTarget]: true,
    [ConfigurationRuleSetupType.IsEnabled]: true,
    [ConfigurationRuleSetupType.IsClosing]: false,
    [ConfigurationRuleSetupType.IsSuperTarget]: false,
    [ConfigurationRuleSetupType.MaxNumberOfSourceStores]: 0,
    [ConfigurationRuleSetupType.MaxNumberOfTargetStores]: 0,
    [ConfigurationRuleSetupType.MaxNumberOfSourceProducts]: 0,
    [ConfigurationRuleSetupType.MinimumRouteValue]: 0,
  },
};

export const createEmptyScope = (
  id: number,
  name: string | undefined,
  priority: number,
  creating: boolean = true,
): Scope => ({
  ...cloneDeep(emptyScope),
  id,
  name,
  priority,
  creating,
  configuration:
    priority === 0
      ? { ...emptyScope.configuration }
      : {
          [ConfigurationRuleSetupType.UseAsSource]: null,
          [ConfigurationRuleSetupType.UseAsTarget]: null,
          [ConfigurationRuleSetupType.IsEnabled]: null,
          [ConfigurationRuleSetupType.IsClosing]: null,
          [ConfigurationRuleSetupType.IsSuperTarget]: null,
          [ConfigurationRuleSetupType.MaxNumberOfSourceStores]: null,
          [ConfigurationRuleSetupType.MaxNumberOfTargetStores]: null,
          [ConfigurationRuleSetupType.MaxNumberOfSourceProducts]: null,
          [ConfigurationRuleSetupType.MinimumRouteValue]: null,
        },
});

interface RenderUseAsSourceParams {
  scopeId: number;
  viewMode: ViewMode;
  record: ScopeConfiguration;
  useClosing: boolean;
  useAsSuperTarget: boolean;
  disabled: boolean;
}

const renderUseAsSource = (params: RenderUseAsSourceParams): React.ReactNode => {
  const { scopeId, viewMode, record, useClosing, useAsSuperTarget, disabled } = params;

  let iconReplacement: ScopeConfigurationIconReplacement = undefined;
  let iconReplacementTooltip = undefined;
  if (useClosing && record[ConfigurationRuleSetupType.IsClosing]) {
    iconReplacement = 'check';
    iconReplacementTooltip = 'Use as source is always ON for closing stores';
  }
  if (useAsSuperTarget && record[ConfigurationRuleSetupType.IsSuperTarget]) {
    iconReplacement = 'cross';
    iconReplacementTooltip = 'Use as source is always OFF for super target stores';
  }

  return (
    <ScopeConfigurationFieldRow
      title="Use as source"
      scopeId={scopeId}
      viewMode={viewMode}
      field={ConfigurationRuleSetupType.UseAsSource}
      iconReplacement={iconReplacement}
      iconReplacementTooltip={iconReplacement ? iconReplacementTooltip : undefined}
      disabled={disabled}
    />
  );
};

const renderUseAsTarget = (
  scopeId: number,
  viewMode: ViewMode,
  record: ScopeConfiguration,
  useClosing: boolean,
  disabled: boolean,
): React.ReactNode => {
  let iconReplacement: ScopeConfigurationIconReplacement = undefined;
  if (useClosing && record[ConfigurationRuleSetupType.IsClosing]) {
    iconReplacement = 'cross';
  }

  return (
    <ScopeConfigurationFieldRow
      title="Use as target"
      scopeId={scopeId}
      viewMode={viewMode}
      field={ConfigurationRuleSetupType.UseAsTarget}
      iconReplacement={iconReplacement}
      iconReplacementTooltip={
        iconReplacement
          ? 'Use as target is always OFF for closing stores and departments'
          : undefined
      }
      disabled={disabled}
    />
  );
};

interface GetScopeConfigurationColumns {
  scopeId: number;
  viewMode: ViewMode;
  useClosing: boolean;
  useAsSuperTarget: boolean;
  disabled: boolean;
}

export interface ScopeConfigurationDefinition {
  key: ConfigurationRuleSetupType;
  title: string;
  render: (record: ScopeConfiguration) => React.ReactNode;
}

export type ScopeConfigurationDefinitions = Record<
  ConfigurationRuleSetupType,
  ScopeConfigurationDefinition
>;

export const getScopeConfigurationDefinitions = (
  params: GetScopeConfigurationColumns,
): ScopeConfigurationDefinitions => {
  const { scopeId, viewMode, useClosing, useAsSuperTarget, disabled } = params;
  return {
    [ConfigurationRuleSetupType.UseAsSource]: {
      title: 'Use as source',
      key: ConfigurationRuleSetupType.UseAsSource,
      render: (record: ScopeConfiguration) =>
        renderUseAsSource({
          scopeId,
          viewMode,
          record,
          useClosing: useClosing,
          useAsSuperTarget,
          disabled,
        }),
    },
    [ConfigurationRuleSetupType.UseAsTarget]: {
      title: 'Use as target',
      key: ConfigurationRuleSetupType.UseAsTarget,
      render: (record: ScopeConfiguration) =>
        renderUseAsTarget(scopeId, viewMode, record, useClosing, disabled),
    },
    [ConfigurationRuleSetupType.MaxNumberOfSourceStores]: {
      title: 'Max. number of incoming routes',
      key: ConfigurationRuleSetupType.MaxNumberOfSourceStores,
      render: () => {
        return (
          <ScopeConfigurationFieldRow
            title="Max. number of incoming routes"
            scopeId={scopeId}
            viewMode={viewMode}
            field={ConfigurationRuleSetupType.MaxNumberOfSourceStores}
            disabled={disabled}
          />
        );
      },
    },
    [ConfigurationRuleSetupType.MaxNumberOfTargetStores]: {
      title: 'Max. number of outgoing routes',
      key: ConfigurationRuleSetupType.MaxNumberOfTargetStores,
      render: () => {
        return (
          <ScopeConfigurationFieldRow
            title="Max. number of outgoing routes"
            scopeId={scopeId}
            viewMode={viewMode}
            field={ConfigurationRuleSetupType.MaxNumberOfTargetStores}
            disabled={disabled}
          />
        );
      },
    },
    [ConfigurationRuleSetupType.MaxNumberOfSourceProducts]: {
      key: ConfigurationRuleSetupType.MaxNumberOfSourceProducts,
      title: 'Max. number of outgoing products',
      render: () => {
        return (
          <ScopeConfigurationFieldRow
            title="Max. number of outgoing products"
            scopeId={scopeId}
            viewMode={viewMode}
            field={ConfigurationRuleSetupType.MaxNumberOfSourceProducts}
            disabled={disabled}
          />
        );
      },
    },
    [ConfigurationRuleSetupType.MinimumRouteValue]: {
      key: ConfigurationRuleSetupType.MinimumRouteValue,
      title: 'Minimum route value',
      render: () => {
        return (
          <ScopeConfigurationFieldRow
            title="Minimum route value"
            viewMode={viewMode}
            scopeId={scopeId}
            field={ConfigurationRuleSetupType.MinimumRouteValue}
            disabled={disabled}
          />
        );
      },
    },
    [ConfigurationRuleSetupType.IsEnabled]: {
      key: ConfigurationRuleSetupType.IsEnabled,
      title: 'Enabled',
      render: () => {
        return (
          <ScopeConfigurationFieldRow
            title="Enabled"
            viewMode={viewMode}
            scopeId={scopeId}
            field={ConfigurationRuleSetupType.IsEnabled}
            disabled={disabled}
          />
        );
      },
    },
    [ConfigurationRuleSetupType.IsClosing]: {
      key: ConfigurationRuleSetupType.IsClosing,
      title: 'Is closing',
      render: () => {
        return (
          <ScopeConfigurationFieldRow
            title="Is closing"
            viewMode={viewMode}
            scopeId={scopeId}
            field={ConfigurationRuleSetupType.IsClosing}
            disabled={disabled}
          />
        );
      },
    },
    [ConfigurationRuleSetupType.IsSuperTarget]: {
      key: ConfigurationRuleSetupType.IsSuperTarget,
      title: 'Is super target',
      render: () => {
        return (
          <ScopeConfigurationFieldRow
            title="Is super target"
            viewMode={viewMode}
            scopeId={scopeId}
            field={ConfigurationRuleSetupType.IsSuperTarget}
            disabled={disabled}
          />
        );
      },
    },
  };
};

export function setConfigurationRuleSetupType(
  configuration: ScopeConfiguration,
  setupType: ConfigurationRuleSetupType,
  value: string,
): void {
  switch (setupType) {
    case ConfigurationRuleSetupType.IsEnabled:
    case ConfigurationRuleSetupType.UseAsSource:
    case ConfigurationRuleSetupType.UseAsTarget:
    case ConfigurationRuleSetupType.IsClosing:
    case ConfigurationRuleSetupType.IsSuperTarget:
      configuration[setupType] = value === '1';
      return;
    case ConfigurationRuleSetupType.MaxNumberOfSourceStores:
    case ConfigurationRuleSetupType.MaxNumberOfTargetStores:
    case ConfigurationRuleSetupType.MaxNumberOfSourceProducts:
    case ConfigurationRuleSetupType.MinimumRouteValue:
      configuration[setupType] = Number(value);
      return;
  }
}

export function configurationRuleValueToString(
  setupType: ConfigurationRuleSetupType,
  value: number | boolean | null,
): string | null {
  if (value === null) return null;
  switch (setupType) {
    case ConfigurationRuleSetupType.IsEnabled:
    case ConfigurationRuleSetupType.UseAsSource:
    case ConfigurationRuleSetupType.UseAsTarget:
    case ConfigurationRuleSetupType.IsClosing:
    case ConfigurationRuleSetupType.IsSuperTarget:
      return value ? '1' : '0';
    case ConfigurationRuleSetupType.MaxNumberOfSourceStores:
    case ConfigurationRuleSetupType.MaxNumberOfTargetStores:
    case ConfigurationRuleSetupType.MaxNumberOfSourceProducts:
    case ConfigurationRuleSetupType.MinimumRouteValue:
      return value ? value.toString() : '0';
  }
}

export enum ScopeConfigurationErrorType {
  CORRECT = 'correct',
  ERROR = 'unknownError',
  ALL_CONFIGS_NULL = 'allConfigsNull',
  DEPARTMENT_ONLY_CLOSING_OR_SUPER_TARGET = 'departmentOnlyClosingOrSuperTarget',
  EMPTY_MULTISELECT = 'emptyMultiselect',
  ALL_SCOPES_SET_TO_ALL = 'allScopesSetToALl',
  CLOSING_AND_SUPER_TARGET_AT_ONCE = 'closingAndSuperTargetAtOnce',
}

export const scopeConfigurationErrorStrings: Record<ScopeConfigurationErrorType, string> = {
  [ScopeConfigurationErrorType.CORRECT]: '',
  [ScopeConfigurationErrorType.ERROR]: 'Error in configurations',
  [ScopeConfigurationErrorType.ALL_CONFIGS_NULL]: 'No configuration is set for this scope',
  [ScopeConfigurationErrorType.DEPARTMENT_ONLY_CLOSING_OR_SUPER_TARGET]:
    'Department-only scopes can not be used as closing or super target',
  [ScopeConfigurationErrorType.EMPTY_MULTISELECT]: '',
  [ScopeConfigurationErrorType.ALL_SCOPES_SET_TO_ALL]:
    'Store types, stores or departments not specified',
  [ScopeConfigurationErrorType.CLOSING_AND_SUPER_TARGET_AT_ONCE]:
    'Can not be Closing and Super target at the same time',
};

export function isScopeConfigurationCorrect(
  scopeConfiguration: ScopeConfiguration,
  scopeEntitySetting: ScopeEntitySetting,
  useClosing: boolean,
  useSuperTarget: boolean,
): ScopeConfigurationErrorType {
  const allConfigsNull =
    iterableConfigurationRuleSetupType.find(
      configurationRuleSetupType => scopeConfiguration[configurationRuleSetupType] !== null,
    ) === undefined;

  if (allConfigsNull) {
    return ScopeConfigurationErrorType.ALL_CONFIGS_NULL;
  }

  const isClosing = scopeConfiguration[ConfigurationRuleSetupType.IsClosing] !== null && useClosing;
  const isSuperTarget =
    scopeConfiguration[ConfigurationRuleSetupType.IsSuperTarget] !== null && useSuperTarget;

  if (isClosing && isSuperTarget) {
    return ScopeConfigurationErrorType.CLOSING_AND_SUPER_TARGET_AT_ONCE;
  }

  const departmentsOnly =
    scopeEntitySetting.Stores.selectionOption === ConfigurationRuleScopeMode.All &&
    scopeEntitySetting.StoreTypes.selectionOption === ConfigurationRuleScopeMode.All &&
    scopeEntitySetting.Departments.selectionOption !== ConfigurationRuleScopeMode.All;

  if (departmentsOnly && (isClosing || isSuperTarget)) {
    return ScopeConfigurationErrorType.DEPARTMENT_ONLY_CLOSING_OR_SUPER_TARGET;
  }

  return ScopeConfigurationErrorType.CORRECT;
}

export function isScopeCorrect(
  scope: Scope | undefined,
  useClosing: boolean,
  useSuperTarget: boolean,
  includeConfiguration: boolean = true,
  includeScope: boolean = true,
): ScopeConfigurationErrorType {
  if (!scope) return ScopeConfigurationErrorType.ERROR;

  const emptyMultiselect = iterableConfigurationRuleScopeEntityType.find(
    scopeEntityType =>
      scope.entities[scopeEntityType].selectionOption !== ConfigurationRuleScopeMode.All &&
      scope.entities[scopeEntityType].selectedIds.length === 0,
  );
  if (emptyMultiselect) return ScopeConfigurationErrorType.EMPTY_MULTISELECT;

  if (includeScope) {
    const allScopesSetToAll =
      iterableConfigurationRuleScopeEntityType.find(
        scopeEntityType =>
          scope.entities[scopeEntityType].selectionOption !== ConfigurationRuleScopeMode.All,
      ) === undefined;

    if (allScopesSetToAll) return ScopeConfigurationErrorType.ALL_SCOPES_SET_TO_ALL;
  }

  if (!scope.creating) {
    // return ScopeConfigurationErrorType.CORRECT;
  }

  if (includeConfiguration) {
    return isScopeConfigurationCorrect(
      scope.configuration,
      scope.entities,
      useClosing,
      useSuperTarget,
    );
  }

  return ScopeConfigurationErrorType.CORRECT;
}

export function getScopeErrorText(
  priority: number,
  error: boolean,
  entityType: ConfigurationRuleScopeEntityType,
): React.ReactNode | undefined {
  if (!error) return undefined;
  const reasons: string[] = [];
  const entityTitle = scopeEntityTitle[entityType].toLowerCase();

  if (priority > 0) {
    reasons.push(`this ${entityTitle} is not included in general configuration`);
  }

  if (entityType === ConfigurationRuleScopeEntityType.Stores) {
    reasons.push('store type of this store is not in scope');
    reasons.push('region of this store is not in scope');
  } else if (entityType === ConfigurationRuleScopeEntityType.StoreTypes) {
    reasons.push('all stores in this store type have disabled region');
  }

  return (
    <Column>
      This {entityTitle} is not valid. Possible reasons:
      {reasons.length > 0 && (
        <ul>
          {reasons.map(r => (
            <li key={r}>{r}</li>
          ))}
        </ul>
      )}
    </Column>
  );
}
